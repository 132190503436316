var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dndList" }, [
    _c(
      "div",
      { staticClass: "dndList-list", style: { width: _vm.width1 } },
      [
        _c("h3", [_vm._v(_vm._s(_vm.list1Title))]),
        _vm._v(" "),
        _c(
          "draggable",
          {
            staticClass: "dragArea",
            attrs: {
              "set-data": _vm.setData,
              list: _vm.list1,
              group: "article",
            },
          },
          _vm._l(_vm.list1, function (element) {
            return _c(
              "div",
              { key: element.id, staticClass: "list-complete-item" },
              [
                _c("div", { staticClass: "list-complete-item-handle" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(element.id) +
                      "[" +
                      _vm._s(element.author) +
                      "] " +
                      _vm._s(element.title) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { position: "absolute", right: "0px" } },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          float: "right",
                          "margin-top": "-20px",
                          "margin-right": "5px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteEle(element)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-delete",
                          staticStyle: { color: "#ff4949" },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dndList-list", style: { width: _vm.width2 } },
      [
        _c("h3", [_vm._v(_vm._s(_vm.list2Title))]),
        _vm._v(" "),
        _c(
          "draggable",
          {
            staticClass: "dragArea",
            attrs: { list: _vm.list2, group: "article" },
          },
          _vm._l(_vm.list2, function (element) {
            return _c(
              "div",
              { key: element.id, staticClass: "list-complete-item" },
              [
                _c(
                  "div",
                  {
                    staticClass: "list-complete-item-handle2",
                    on: {
                      click: function ($event) {
                        return _vm.pushEle(element)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(element.id) +
                        " [" +
                        _vm._s(element.author) +
                        "] " +
                        _vm._s(element.title) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }