var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "maincontainerviewacasoslisting" } },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.findingCPF,
              expression: "findingCPF",
            },
          ],
          attrs: {
            visible: _vm.modalVisible,
            width: "70%",
            center: "",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "new-case" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mensagemFuncionarioCadastrado === false,
                    expression: "mensagemFuncionarioCadastrado === false",
                  },
                ],
              },
              [_vm._v("Cadastrar Novo Caso")]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mensagemFuncionarioCadastrado === true,
                    expression: "mensagemFuncionarioCadastrado === true",
                  },
                ],
              },
              [_vm._v("Editar Caso")]
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: false,
                model: _vm.form,
                "label-width": "170px",
                "label-position": "top",
                size: "medium",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeData === true,
                      expression: "activeData === true",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("CPF"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "999.999.999-99",
                            expression: "'999.999.999-99'",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: {
                          placeholder: "Ex.: 000.000.000-00",
                          disabled: _vm.findingCPFdone === true,
                        },
                        model: {
                          value: _vm.formCadastrarCaso.cpf,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "cpf", $$v)
                          },
                          expression: "formCadastrarCaso.cpf",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.findingCPFdone === true,
                          expression: "findingCPFdone === true",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("Nome"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: {
                          placeholder: "Nome Completo",
                          disabled: _vm.findingCPFdone === true,
                        },
                        model: {
                          value: _vm.formCadastrarCaso.nome,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "nome", $$v)
                          },
                          expression: "formCadastrarCaso.nome",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.findingCPFdone === true,
                          expression: "findingCPFdone === true",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("E-mail"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: {
                          placeholder: "Ex.: saic@saic.br",
                          disabled: _vm.findingCPFdone === true,
                        },
                        model: {
                          value: _vm.formCadastrarCaso.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "email", $$v)
                          },
                          expression: "formCadastrarCaso.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.findingCPFdone === true,
                          expression: "findingCPFdone === true",
                        },
                      ],
                    },
                    [
                      _c("span", [
                        _vm._v("Data de Nascimento"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99/99/9999",
                            expression: "'99/99/9999'",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: {
                          placeholder: "Ex.: dd/mm/aaaa",
                          disabled: _vm.findingCPFdone === true,
                        },
                        model: {
                          value: _vm.formCadastrarCaso.nascimento,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "nascimento", $$v)
                          },
                          expression: "formCadastrarCaso.nascimento",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.findingCPFdone === true,
                          expression: "findingCPFdone === true",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("Status")]),
                      _c(
                        "el-select",
                        {
                          staticClass: "input-style",
                          attrs: { placeholder: "Status" },
                          model: {
                            value: _vm.formCadastrarCaso.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formCadastrarCaso, "status", $$v)
                            },
                            expression: "formCadastrarCaso.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Confirmado", value: "confirmado" },
                          }),
                          _c("el-option", {
                            attrs: { label: "Recuperado", value: "recuperado" },
                          }),
                          _c("el-option", {
                            attrs: { label: "Suspeito", value: "suspeito" },
                          }),
                          _c("el-option", {
                            attrs: { label: "Óbito", value: "obito" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.findingCPFdone === false ? _c("br") : _vm._e(),
                  _vm.findingCPFdone === false
                    ? _c("el-alert", {
                        attrs: {
                          title: "Informe o CPF de um funcionário cadastrado.",
                          type: "error",
                          closable: false,
                        },
                      })
                    : _vm._e(),
                  _vm.mensagemFuncionarioCadastrado === true
                    ? _c("el-alert", {
                        attrs: {
                          title:
                            "Esse funcionário já possui o seu caso registrado.",
                          type: "warning",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.findingCPFdone === true
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.errorSaving !== ""
                    ? _c("el-alert", {
                        attrs: {
                          title: _vm.errorSaving,
                          type: "error",
                          closable: false,
                        },
                      })
                    : _vm._e(),
                  _vm.success
                    ? _c("el-alert", {
                        attrs: {
                          title: "Caso registrado com sucesso",
                          type: "success",
                          closable: false,
                        },
                      })
                    : _vm._e(),
                  _c("br"),
                  _vm.activeData === true
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.handleClose },
                        },
                        [_vm._v("Cancelar")]
                      )
                    : _vm._e(),
                  _vm.findingCPFdone === true
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "medium",
                            loading: _vm.sending,
                            disabled: _vm.sending,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("Salvar")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [_vm._v("Central de Casos")]),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [_c("panelgroup", { attrs: { data: _vm.casos } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "padding-top": "1rem" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        { attrs: { inline: true } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mostrar" } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.filtros.tipofiltro,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filtros, "tipofiltro", $$v)
                                    },
                                    expression: "filtros.tipofiltro",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Status", value: "status" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-left": "1rem" },
                              attrs: { label: "Tipo" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.filtros.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filtros, "status", $$v)
                                    },
                                    expression: "filtros.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "Todos", value: "todos" },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Suspeito",
                                      value: "suspeito",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Confirmado",
                                      value: "confirmado",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Recuperado",
                                      value: "recuperado",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "Óbito", value: "obito" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticClass: "row-bg",
                  staticStyle: { "padding-top": "1rem" },
                  attrs: { type: "flex" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.perfil.role == "recepcionista" ||
                                _vm.perfil.role == "admin",
                              expression:
                                "(perfil.role == 'recepcionista') || (perfil.role == 'admin')",
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.novoCaso },
                        },
                        [_vm._v("Novo Caso")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.perfil.st_escola,
                      expression: "!perfil.st_escola",
                    },
                  ],
                  staticClass: "row-bg",
                  staticStyle: { "padding-top": "1rem" },
                  attrs: { type: "flex" },
                },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v(
                        "Mostrando " +
                          _vm._s(_vm.casosFiltered.length) +
                          " resultados"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "padding-top": "1rem" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.perfil.st_escola,
                              expression: "!perfil.st_escola",
                            },
                          ],
                          ref: "tableData",
                          staticClass: "clickable-rows",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.casosPaginated,
                            "empty-text": "-",
                            "row-class-name": _vm.tableRowClassName,
                          },
                        },
                        [
                          _c("el-table-column", {
                            staticClass: "table-column-style",
                            staticStyle: { "padding-left": "1rem" },
                            attrs: { prop: "nome", label: "NOME" },
                          }),
                          _c("el-table-column", {
                            staticClass: "table-column-style",
                            attrs: { prop: "cpf", label: "CPF" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      _vm._s(
                                        _vm.casosPaginated[
                                          scope.$index
                                        ].cpf.replace(
                                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                          "$1.$2.$3-$4"
                                        )
                                      )
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            staticClass: "table-column-style",
                            attrs: { prop: "status", label: "STATUS" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "Status" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.confirmStatusChange(
                                              scope.$index
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.casosPaginated[scope.$index]
                                              .status,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.casosPaginated[scope.$index],
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "casosPaginated[scope.$index].status",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "Confirmado",
                                            value: "confirmado",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "Recuperado",
                                            value: "recuperado",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "Suspeito",
                                            value: "suspeito",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "Óbito",
                                            value: "obito",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-tabs",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.perfil.st_escola,
                              expression: "perfil.st_escola",
                            },
                          ],
                          staticStyle: {
                            "border-radius": "1rem",
                            width: "100%",
                          },
                          attrs: { type: "border-card" },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "Alunos" } },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "row-bg",
                                  staticStyle: {
                                    "padding-top": "1rem",
                                    "margin-bottom": "2rem",
                                  },
                                  attrs: { type: "flex" },
                                },
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "12px" } },
                                      [
                                        _vm._v(
                                          "Mostrando " +
                                            _vm._s(
                                              _vm.casosFilteredAlunos.length
                                            ) +
                                            " resultados"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "tableData",
                                      staticClass: "clickable-rows",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.casosPaginatedAlunos,
                                        "empty-text": "-",
                                        "row-class-name":
                                          _vm.tableRowClassNameAlunos,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        staticClass: "table-column-style",
                                        staticStyle: { "padding-left": "1rem" },
                                        attrs: { prop: "nome", label: "NOME" },
                                      }),
                                      _c("el-table-column", {
                                        staticClass: "table-column-style",
                                        attrs: { prop: "cpf", label: "CPF" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.casosPaginatedAlunos[
                                                      scope.$index
                                                    ].cpf.replace(
                                                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                                      "$1.$2.$3-$4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        staticClass: "table-column-style",
                                        attrs: {
                                          prop: "status",
                                          label: "STATUS",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "Status",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.confirmStatusChangeAlunos(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .casosPaginatedAlunos[
                                                          scope.$index
                                                        ].status,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .casosPaginatedAlunos[
                                                            scope.$index
                                                          ],
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "casosPaginatedAlunos[scope.$index].status",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "Confirmado",
                                                        value: "confirmado",
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "Recuperado",
                                                        value: "recuperado",
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "Suspeito",
                                                        value: "suspeito",
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "Óbito",
                                                        value: "obito",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    { staticStyle: { "padding-top": "1rem" } },
                                    [
                                      _vm.casosFilteredAlunos.length > 0
                                        ? _c("el-pagination", {
                                            attrs: {
                                              background: "",
                                              "page-size": _vm.casosPerPage,
                                              layout: "prev, pager, next",
                                              total:
                                                _vm.casosFilteredAlunos.length,
                                            },
                                            on: {
                                              "current-change": _vm.changePage,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "Funcionários" } },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "row-bg",
                                  staticStyle: {
                                    "padding-top": "1rem",
                                    "margin-bottom": "2rem",
                                  },
                                  attrs: { type: "flex" },
                                },
                                [
                                  _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "12px" } },
                                      [
                                        _vm._v(
                                          "Mostrando " +
                                            _vm._s(_vm.casosFiltered.length) +
                                            " resultados"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "tableData",
                                      staticClass: "clickable-rows",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        data: _vm.casosPaginated,
                                        "empty-text": "-",
                                        "row-class-name": _vm.tableRowClassName,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        staticClass: "table-column-style",
                                        staticStyle: { "padding-left": "1rem" },
                                        attrs: { prop: "nome", label: "NOME" },
                                      }),
                                      _c("el-table-column", {
                                        staticClass: "table-column-style",
                                        attrs: { prop: "cpf", label: "CPF" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.casosPaginated[
                                                      scope.$index
                                                    ].cpf.replace(
                                                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                                      "$1.$2.$3-$4"
                                                    )
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _c("el-table-column", {
                                        staticClass: "table-column-style",
                                        attrs: {
                                          prop: "status",
                                          label: "STATUS",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      placeholder: "Status",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.confirmStatusChange(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.casosPaginated[
                                                          scope.$index
                                                        ].status,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.casosPaginated[
                                                            scope.$index
                                                          ],
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "casosPaginated[scope.$index].status",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "Confirmado",
                                                        value: "confirmado",
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "Recuperado",
                                                        value: "recuperado",
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "Suspeito",
                                                        value: "suspeito",
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "Óbito",
                                                        value: "obito",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { "padding-top": "1rem" } },
                                [
                                  _vm.casosFiltered.length > 0
                                    ? _c("el-pagination", {
                                        attrs: {
                                          background: "",
                                          "page-size": _vm.casosPerPage,
                                          layout: "prev, pager, next",
                                          total: _vm.casosFiltered.length,
                                        },
                                        on: {
                                          "current-change": _vm.changePage,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.perfil.st_escola,
                      expression: "!perfil.st_escola",
                    },
                  ],
                  staticStyle: { "padding-top": "1rem" },
                },
                [
                  _vm.casosFiltered.length > 0
                    ? _c("el-pagination", {
                        attrs: {
                          background: "",
                          "page-size": _vm.casosPerPage,
                          layout: "prev, pager, next",
                          total: _vm.casosFiltered.length,
                        },
                        on: { "current-change": _vm.changePage },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }