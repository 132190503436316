"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _vuex = require("vuex");

var _localforage = _interopRequireDefault(require("localforage"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: 'AppMain',
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('account', ['perfis'])), {}, {
    cachedViews: function cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.path;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    if (this.perfis.length === 0) {
      var roles = JSON.parse(localStorage.getItem('roles'));

      _localforage.default.getItem('secretaria_scheme').then(function (sec_schema) {
        _this.setPerfis(roles.filter(function (p) {
          return (p.role === 'medico' || p.role === 'admin' || p.role === 'recepcionista' || p.role === 'assistente_social' || p.role === 'enfermeiro' || p.role === 'fisioterapeuta') && (p.codigo_instituicao === sec_schema && p.tipo_instituicao === 'SECRETARIA' || p.tipo_instituicao === 'HOSPITAL' || p.tipo_instituicao === 'EMPRESA');
        }));
      });
    }
  },
  methods: _objectSpread({}, (0, _vuex.mapMutations)('account', {
    setPerfis: 'SET_PERFIS'
  }))
};
exports.default = _default;