var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "containerpageavaliacoesmain" } }, [
    !this.isLoading
      ? _c(
          "div",
          [
            _c(
              "el-row",
              { staticStyle: { "padding-top": "40px" }, attrs: { gutter: 32 } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } },
                  [
                    _c("h2", [
                      _vm._v(
                        "Ficha de avaliação de " +
                          _vm._s(this.data.nome) +
                          " (" +
                          _vm._s(_vm.moment(_vm.data.dt).format("DD/MM/YYYY")) +
                          ")"
                      ),
                    ]),
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.data.respostas },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "texto_pergunta",
                            label: "Pergunta",
                            width: "300",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "texto_pergunta",
                            label: "Resposta",
                            width: "300",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.data.respostas[scope.$index]
                                            .resposta == true ||
                                            _vm.data.respostas[scope.$index]
                                              .resposta == "true"
                                            ? "sim"
                                            : _vm.data.respostas[scope.$index]
                                                .resposta == false ||
                                              _vm.data.respostas[scope.$index]
                                                .resposta == "false"
                                            ? "Não"
                                            : _vm.data.respostas[scope.$index]
                                                .resposta
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1335516361
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } },
                  [
                    _c("h2", [_vm._v("Linha do tempo")]),
                    _c(
                      "el-timeline",
                      _vm._l(_vm.previous, function (avaliacao, i) {
                        return _c(
                          "el-timeline-item",
                          {
                            attrs: {
                              timestamp: _vm
                                .moment(avaliacao.dt)
                                .format("DD/MM/YYYY"),
                              color: {
                                risco_baixo: "green",
                                risco_medio: "yellow",
                                risco_alto: "red",
                              }[avaliacao.status_risco],
                            },
                          },
                          [
                            _c(
                              "el-collapse",
                              {
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChange(i)
                                  },
                                },
                                model: {
                                  value: avaliacao.active,
                                  callback: function ($$v) {
                                    _vm.$set(avaliacao, "active", $$v)
                                  },
                                  expression: "avaliacao.active",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    attrs: {
                                      title: {
                                        risco_baixo: "Risco baixo",
                                        risco_medio: "Risco médio",
                                        risco_alto: "Risco alto",
                                      }[avaliacao.status_risco],
                                      name: "1",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { data: avaliacao.respostas },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "texto_pergunta",
                                            label: "Pergunta",
                                            width: "300",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "texto_pergunta",
                                            label: "Resposta",
                                            width: "300",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          avaliacao.respostas[
                                                            scope.$index
                                                          ].resposta == true ||
                                                            avaliacao.respostas[
                                                              scope.$index
                                                            ].resposta == "true"
                                                            ? "sim"
                                                            : avaliacao
                                                                .respostas[
                                                                scope.$index
                                                              ].resposta ==
                                                                false ||
                                                              avaliacao
                                                                .respostas[
                                                                scope.$index
                                                              ].resposta ==
                                                                "false"
                                                            ? "Não"
                                                            : avaliacao
                                                                .respostas[
                                                                scope.$index
                                                              ].resposta
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }