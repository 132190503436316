var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "maincontainerviewpostslisting" } },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.modalVisibleNotice, width: "60%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.modalVisibleNotice = $event
            },
          },
        },
        [
          _c("h1", [_vm._v(_vm._s(_vm.noticiaSelecionada.titulo))]),
          _c("el-divider"),
          _c("i", { staticClass: "el-icon-date" }, [_vm._v(" ")]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm
                  .moment(_vm.noticiaSelecionada.criado_em)
                  .format("DD/MM/YYYY")
              )
            ),
          ]),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" }, attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "80%",
                      height: "80%",
                      display: "-webkit-flex",
                      "margin-left": "auto",
                      "margin-right": "auto",
                      "padding-top": "1rem",
                    },
                    attrs: { src: _vm.noticiaSelecionada.imagem_de_capa },
                  }),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("span", {
                  domProps: {
                    innerHTML: _vm._s(_vm.noticiaSelecionada.conteudo),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [
              _vm._v("Gerenciar Postagens"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/hotsite/novapostagem" } },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("Criar nova postagem"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: true } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Buscar por" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.filtros.tipobusca,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "tipobusca", $$v)
                                },
                                expression: "filtros.tipobusca",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Tipo", value: "tipo" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Título", value: "titulo" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.filtros.tipobusca === "tipo"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "Tipo" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "Tipo" },
                                  model: {
                                    value: _vm.filtros.tipo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filtros, "tipo", $$v)
                                    },
                                    expression: "filtros.tipo",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "Notícia",
                                      value: "Notícia",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "Fato ou fake",
                                      value: "Fato ou fake",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.filtros.tipobusca === "titulo"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "Título" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Título" },
                                model: {
                                  value: _vm.filtros.titulo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "titulo", $$v)
                                  },
                                  expression: "filtros.titulo",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c("el-card", { staticClass: "box-card-results" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-card",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("RESULTADOS")])]
                    ),
                    _c("div", { staticClass: "content-card" }, [
                      _c("span", [_vm._v(_vm._s(_vm.postsFiltered.length))]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableData",
                      staticClass: "clickable-rows",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.postsPaginated, "empty-text": "-" },
                      on: { "row-click": _vm.abrirPostagem },
                    },
                    [
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "titulo", label: "TÍTULO" },
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "chamada", label: "CHAMADA" },
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "tipo", label: "TIPO" },
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "autor.nome", label: "AUTOR" },
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "status", label: "STATUS" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _vm.postsFiltered.length > 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-size": _vm.postsPerPage,
                      layout: "prev, pager, next",
                      total: _vm.postsFiltered.length,
                    },
                    on: { "current-change": _vm.changePage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }