"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _driver = _interopRequireDefault(require("driver.js"));

require("driver.js/dist/driver.min.css");

var _steps = _interopRequireDefault(require("./steps"));

//
//
//
//
//
//
//
//
//
//
//
//
//
// import driver.js
// import driver.js css
var _default = {
  name: 'Guide',
  data: function data() {
    return {
      driver: null
    };
  },
  mounted: function mounted() {
    this.driver = new _driver.default();
  },
  methods: {
    guide: function guide() {
      this.driver.defineSteps(_steps.default);
      this.driver.start();
    }
  }
};
exports.default = _default;