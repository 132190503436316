"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCidades = getCidades;
exports.getEnderecoCEP = getEnderecoCEP;
exports.getEnderecoCEP2 = getEnderecoCEP2;
exports.getEstados = getEstados;

require("core-js/modules/es6.regexp.replace");

var _request = _interopRequireDefault(require("@/utils/request"));

function getEnderecoCEP(cep) {
  return (0, _request.default)({
    url: '/core/logradouro/' + cep,
    method: 'get'
  });
}

function getEstados() {
  return (0, _request.default)({
    url: 'https://api.saic.infatec.solutions/v1/core/uf/',
    method: 'get'
  });
}

function getCidades(uf) {
  return (0, _request.default)({
    url: 'https://api.saic.infatec.solutions/v1/core/localidades/' + (uf ? '?uf=' + uf : ''),
    method: 'get'
  });
}

function getEnderecoCEP2(cep) {
  return (0, _request.default)({
    url: "https://viacep.com.br/ws/".concat(cep.replace('-', ''), "/json/"),
    method: 'get'
  });
}