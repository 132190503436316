var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "Gerando o PDF..." },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "article__heading" }, [
        _c("div", { staticClass: "article__heading__title" }, [
          _vm._v(_vm._s(_vm.title) + " "),
        ]),
        _c("br"),
        _c("div", { staticClass: "article__heading__subtitle" }, [
          _vm._v(" " + _vm._s(_vm.subtitle)),
        ]),
        _c("div", { staticStyle: { color: "#ccc" } }),
      ]),
      _vm.tp_relatorio == "funcionarios"
        ? _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.resultados_encontrados.label)
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content-card",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.resultados_encontrados.value)
                      ),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.tp_relatorio == "funcionarios"
        ? _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex", gutter: 32 } },
            [
              _c(
                "div",
                { staticClass: "result-table-style" },
                [
                  _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "nome_completo", label: "NOME COMP" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cpf", label: "CPF" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cep", label: "CEP" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "uf", label: "ESTADO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "localidade", label: "CIDADE" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "bairro", label: "BAIRRO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "data_nascimento", label: "DT NASC" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dados[
                                        scope.$index
                                      ].data_nascimento.replace(
                                        /(\d{4})-(\d{2})-(\d{2})/,
                                        "$3/$2/$1"
                                      )
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4087311234
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "setor", label: "SETOR" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cargo", label: "CARGO" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "printlogo" } }, [
      _c("div", { staticClass: "header-logo-saic" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/SAICovid-19.png") },
        }),
      ]),
      _c("div", { staticClass: "header-text" }, [
        _c("span", [_vm._v("INFATEC - SOLUÇÕES TECNOLÓGICAS")]),
        _c("br"),
        _c("span", [_vm._v("TERESINA - PI")]),
        _c("br"),
        _c("span", [_vm._v("SISTEMA DE ACOMPANHAMENTO INTEGRAL AO COVID-19")]),
      ]),
      _c("div", { staticClass: "header-logo-infatec" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/infatec-logo.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }