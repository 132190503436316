import { render, staticRenderFns } from "./index.vue?vue&type=template&id=bf34d14a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=bf34d14a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf34d14a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Diogo\\Documents\\projetos\\free\\saic-dash\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bf34d14a')) {
      api.createRecord('bf34d14a', component.options)
    } else {
      api.reload('bf34d14a', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=bf34d14a&scoped=true&", function () {
      api.rerender('bf34d14a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DndList/index.vue"
export default component.exports