var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-funcionarioLista" },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [_vm._v("Funcionários")]),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setores/cadastroFuncionario" } },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("Novo Funcionário"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { inline: true, size: "medium" },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("span", [_vm._v("Buscar por")]),
                          _c("br"),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.filtros.tipobusca,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "tipobusca", $$v)
                                },
                                expression: "filtros.tipobusca",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Nome", value: "nome" },
                              }),
                              _c("el-option", {
                                attrs: { label: "CPF", value: "cpf" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.filtros.tipobusca === "cpf"
                        ? _c(
                            "el-form-item",
                            [
                              _c("span", [_vm._v("CPF")]),
                              _c("br"),
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "###########",
                                    expression: "'###########'",
                                  },
                                ],
                                attrs: { placeholder: "Informe o CPF" },
                                model: {
                                  value: _vm.filtros.cpf,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "cpf", $$v)
                                  },
                                  expression: "filtros.cpf",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.filtros.tipobusca === "nome"
                        ? _c(
                            "el-form-item",
                            [
                              _c("span", [_vm._v("Nome")]),
                              _c("br"),
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      regex:
                                        "[0-9a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*",
                                    },
                                    expression:
                                      "{'regex': '[0-9a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*'}",
                                  },
                                ],
                                attrs: { placeholder: "Nome", type: "text" },
                                model: {
                                  value: _vm.filtros.nome,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "nome", $$v)
                                  },
                                  expression: "filtros.nome",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c("el-card", { staticClass: "box-card-results" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-card",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("RESULTADOS")])]
                    ),
                    _c("div", { staticClass: "content-card" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.funcionariosPaginated.length)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableData",
                      staticClass: "clickable-rows",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.funcionariosPaginated,
                        "empty-text": "-",
                      },
                    },
                    [
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "nome_completo", label: "NOME" },
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "cpf", label: "CPF" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.funcionariosPaginated[scope.$index].cpf
                                      ? _vm.funcionariosPaginated[
                                          scope.$index
                                        ].cpf.replace(
                                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                          "$1.$2.$3-$4"
                                        )
                                      : ""
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "setor", label: "SETOR" },
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { label: "OPERAÇÕES" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "info" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editarFuncionario(
                                          _vm.funcionariosPaginated[
                                            scope.$index
                                          ].id
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Editar")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _vm.funcionariosFiltered.length > 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-size": _vm.funcionariosPerPage,
                      layout: "prev, pager, next",
                      total: _vm.funcionariosFiltered.length,
                    },
                    on: { "current-change": _vm.changePage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }