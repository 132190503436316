var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "maincontainerviewacasoslisting" } },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.findingCPF,
              expression: "findingCPF",
            },
          ],
          attrs: {
            visible: _vm.modalVisible,
            width: "70%",
            center: "",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "new-case" }, [
            _c("span", [_vm._v("Cadastrar Novo Caso")]),
          ]),
          _vm.formCadastrarCaso.exists === false
            ? _c(
                "el-steps",
                {
                  staticClass: "steps-style",
                  attrs: {
                    "finish-status": "success",
                    active: _vm.active,
                    "align-center": "",
                  },
                },
                [
                  _c("el-step", { attrs: { title: "Dados Pessoais" } }),
                  _c("el-step", { attrs: { title: "Endereço" } }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: false,
                model: _vm.form,
                "label-width": "170px",
                "label-position": "top",
                size: "medium",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeData === true,
                      expression: "activeData === true",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("CPF"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "999.999.999-99",
                            expression: "'999.999.999-99'",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: { placeholder: "Ex.: 000.000.000-00" },
                        model: {
                          value: _vm.formCadastrarCaso.cpf,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "cpf", $$v)
                          },
                          expression: "formCadastrarCaso.cpf",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.findingCPFdone === true,
                          expression: "findingCPFdone === true",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("Nome"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: {
                          placeholder: "Nome Completo",
                          disabled: _vm.formCadastrarCaso.exists === true,
                        },
                        model: {
                          value: _vm.formCadastrarCaso.nome,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "nome", $$v)
                          },
                          expression: "formCadastrarCaso.nome",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formCadastrarCaso.exists === false ? _c("br") : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("E-mail"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: { placeholder: "Ex.: saic@saic.br" },
                        model: {
                          value: _vm.formCadastrarCaso.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "email", $$v)
                          },
                          expression: "formCadastrarCaso.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [
                        _vm._v("Data de Nascimento"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99/99/9999",
                            expression: "'99/99/9999'",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: { placeholder: "Ex.: dd/mm/aaaa" },
                        model: {
                          value: _vm.formCadastrarCaso.nascimento,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "nascimento", $$v)
                          },
                          expression: "formCadastrarCaso.nascimento",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [
                        _vm._v("Telefone"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "(99) 99999-9999",
                            expression: "'(99) 99999-9999'",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: { placeholder: "Ex.: (00) 90000-0000" },
                        model: {
                          value: _vm.formCadastrarCaso.telefone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "telefone", $$v)
                          },
                          expression: "formCadastrarCaso.telefone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("CNS")]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: { regex: "[0-9]{1,20}" },
                            expression: "{'regex': '[0-9]{1,20}'}",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.formCadastrarCaso.cns,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "cns", $$v)
                          },
                          expression: "formCadastrarCaso.cns",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.findingCPFdone === true,
                          expression: "findingCPFdone === true",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("Status")]),
                      _c(
                        "el-select",
                        {
                          staticClass: "input-style",
                          attrs: {
                            placeholder: "Status",
                            disabled: _vm.formCadastrarCaso.exists === true,
                          },
                          model: {
                            value: _vm.formCadastrarCaso.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formCadastrarCaso, "status", $$v)
                            },
                            expression: "formCadastrarCaso.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Confirmado", value: "confirmado" },
                          }),
                          _c("el-option", {
                            attrs: { label: "Recuperado", value: "recuperado" },
                          }),
                          _c("el-option", {
                            attrs: { label: "Suspeito", value: "suspeito" },
                          }),
                          _c("el-option", {
                            attrs: { label: "Óbito", value: "obito" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.formCadastrarCaso.exists === false ? _c("br") : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeAddress === true,
                      expression: "activeAddress === true",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("CEP"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99999-999",
                            expression: "'99999-999'",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: { placeholder: "Ex.: 00000-000" },
                        model: {
                          value: _vm.formCadastrarCaso.cep,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "cep", $$v)
                          },
                          expression: "formCadastrarCaso.cep",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formCadastrarCaso.exists === false ? _c("br") : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("Estado"), _c("span", [_vm._v("*")])]),
                      _c(
                        "el-select",
                        {
                          staticClass: "input-style",
                          attrs: { placeholder: "Estado" },
                          model: {
                            value: _vm.formCadastrarCaso.estado,
                            callback: function ($$v) {
                              _vm.$set(_vm.formCadastrarCaso, "estado", $$v)
                            },
                            expression: "formCadastrarCaso.estado",
                          },
                        },
                        _vm._l(_vm.listaestados, function (estado, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: estado.no_uf, value: estado.co_uf },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("Cidade"), _c("span", [_vm._v("*")])]),
                      _c("el-autocomplete", {
                        staticClass: "inline-input input-style",
                        attrs: {
                          "fetch-suggestions": _vm.listacidadescomplete,
                          placeholder: "Selecione",
                        },
                        on: { select: _vm.handleSelectCidade },
                        model: {
                          value: _vm.formCadastrarCaso.cidadeselected,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formCadastrarCaso,
                              "cidadeselected",
                              $$v
                            )
                          },
                          expression: "formCadastrarCaso.cidadeselected",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("Bairro"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: { placeholder: "Nome do Bairro" },
                        model: {
                          value: _vm.formCadastrarCaso.bairro,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "bairro", $$v)
                          },
                          expression: "formCadastrarCaso.bairro",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formCadastrarCaso.exists === false ? _c("br") : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.formCadastrarCaso.exists === false,
                          expression: "formCadastrarCaso.exists === false",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("Rua"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: { placeholder: "Nome da Rua" },
                        model: {
                          value: _vm.formCadastrarCaso.rua,
                          callback: function ($$v) {
                            _vm.$set(_vm.formCadastrarCaso, "rua", $$v)
                          },
                          expression: "formCadastrarCaso.rua",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.formCadastrarCaso.exists === false ? _c("br") : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.formCadastrarCaso.exists === false
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.errorSaving !== ""
                    ? _c("el-alert", {
                        attrs: {
                          title: _vm.errorSaving,
                          type: "error",
                          closable: false,
                        },
                      })
                    : _vm._e(),
                  _vm.success
                    ? _c("el-alert", {
                        attrs: {
                          title: "Caso registrado com sucesso",
                          type: "success",
                          closable: false,
                        },
                      })
                    : _vm._e(),
                  _c("br"),
                  _vm.activeData === true
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: {
                            click: function ($event) {
                              _vm.modalVisible = false
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      )
                    : _vm._e(),
                  _vm.activeData === false
                    ? _c(
                        "el-button",
                        { attrs: { size: "medium" }, on: { click: _vm.prev } },
                        [_vm._v("Voltar")]
                      )
                    : _vm._e(),
                  _vm.activeAddress === false
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium" },
                          on: { click: _vm.next },
                        },
                        [_vm._v("Avançar")]
                      )
                    : _vm._e(),
                  _vm.activeAddress === true
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "medium",
                            loading: _vm.sending,
                            disabled: _vm.sending,
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("Salvar")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("el-container"),
      _c("el-header", [
        _c("span", { staticClass: "title" }, [_vm._v("Central de Casos")]),
      ]),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [_c("panelgroup", { attrs: { data: _vm.casos } })],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form",
                    { attrs: { inline: true } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mostrar" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.filtros.tipofiltro,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "tipofiltro", $$v)
                                },
                                expression: "filtros.tipofiltro",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Status", value: "status" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-left": "1rem" },
                          attrs: { label: "Tipo" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.filtros.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "status", $$v)
                                },
                                expression: "filtros.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Todos", value: "todos" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Suspeito", value: "suspeito" },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "Confirmado",
                                  value: "confirmado",
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "Recuperado",
                                  value: "recuperado",
                                },
                              }),
                              _c("el-option", {
                                attrs: { label: "Óbito", value: "obito" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              staticStyle: { "padding-top": "1rem" },
              attrs: { type: "flex" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.modalVisible = true
                        },
                      },
                    },
                    [_vm._v("Novo Caso")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              staticClass: "row-bg",
              staticStyle: { "padding-top": "1rem" },
              attrs: { type: "flex" },
            },
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", { staticStyle: { "font-size": "12px" } }, [
                  _vm._v(
                    "Mostrando " +
                      _vm._s(_vm.casosFiltered.length) +
                      " resultados"
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c("el-card", { staticClass: "box-card-results" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-card",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("RESULTADOS")])]
                    ),
                    _c("div", { staticClass: "content-card" }, [
                      _c("span", [_vm._v(_vm._s(_vm.casosFiltered.length))]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableData",
                      staticClass: "clickable-rows",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.casosPaginated,
                        "empty-text": "-",
                        "row-class-name": _vm.tableRowClassName,
                      },
                    },
                    [
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        staticStyle: { "padding-left": "1rem" },
                        attrs: { prop: "cidadao.nome_completo", label: "NOME" },
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "cidadao.cpf", label: "CPF" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.casosPaginated[
                                      scope.$index
                                    ].cidadao.cpf.replace(
                                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                      "$1.$2.$3-$4"
                                    )
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "status", label: "STATUS" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "Status" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.confirmStatusChange(
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.casosPaginated[scope.$index].status,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.casosPaginated[scope.$index],
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "casosPaginated[scope.$index].status",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "Confirmado",
                                        value: "confirmado",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "Recuperado",
                                        value: "recuperado",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "Suspeito",
                                        value: "suspeito",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "Óbito", value: "obito" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _vm.casosFiltered.length > 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-size": _vm.casosPerPage,
                      layout: "prev, pager, next",
                      total: _vm.casosFiltered.length,
                    },
                    on: { "current-change": _vm.changePage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }