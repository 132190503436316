"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _formFuncionario = _interopRequireDefault(require("./formFuncionario"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'viewCadastroFuncionário',
  components: {
    formFuncionario: _formFuncionario.default
  }
};
exports.default = _default;