"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

var _typeof = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/typeof.js");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

require("core-js/modules/es6.array.find-index");

var _article = require("@/api/article");

var _stats = require("@/api/stats");

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _utils = require("@/utils");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// secondary package based on el-pagination
var calendarTypeOptions = [{
  key: 'CN',
  display_name: 'China'
}, {
  key: 'US',
  display_name: 'USA'
}, {
  key: 'JP',
  display_name: 'Japan'
}, {
  key: 'EU',
  display_name: 'Eurozone'
}]; // arr to obj, such as { CN : "China", US : "USA" }

var calendarTypeKeyValue = calendarTypeOptions.reduce(function (acc, cur) {
  acc[cur.key] = cur.display_name;
  return acc;
}, {});
var _default = {
  name: 'ComplexTable',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'info',
        deleted: 'danger'
      };
      return statusMap[status];
    },
    typeFilter: function typeFilter(type) {
      return calendarTypeKeyValue[type];
    }
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },
      importanceOptions: [1, 2, 3],
      calendarTypeOptions: calendarTypeOptions,
      sortOptions: [{
        label: 'ID Ascending',
        key: '+id'
      }, {
        label: 'ID Descending',
        key: '-id'
      }],
      statusOptions: ['published', 'draft', 'deleted'],
      showReviewer: false,
      temp: {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        type: '',
        status: 'published'
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: 'Edit',
        create: 'Create'
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {
        type: [{
          required: true,
          message: 'type is required',
          trigger: 'change'
        }],
        timestamp: [{
          type: 'date',
          required: true,
          message: 'timestamp is required',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      (0, _stats.getAvaliacoes)().then(function (response) {
        _this.list = response.results;
        _this.total = response.count; // Just to simulate the time of the request

        setTimeout(function () {
          _this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleModifyStatus: function handleModifyStatus(row, status) {
      this.$message({
        message: '操作Success',
        type: 'success'
      });
      row.status = status;
    },
    sortChange: function sortChange(data) {
      var prop = data.prop,
          order = data.order;

      if (prop === 'id') {
        this.sortByID(order);
      }
    },
    sortByID: function sortByID(order) {
      if (order === 'ascending') {
        this.listQuery.sort = '+id';
      } else {
        this.listQuery.sort = '-id';
      }

      this.handleFilter();
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        status: 'published',
        type: ''
      };
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.resetTemp();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this3.temp.id = parseInt(Math.random() * 100) + 1024; // mock a id

          _this3.temp.author = 'vue-element-admin';
          (0, _article.createArticle)(_this3.temp).then(function () {
            _this3.list.unshift(_this3.temp);

            _this3.dialogFormVisible = false;

            _this3.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.temp = Object.assign({}, row); // copy obj

      this.temp.timestamp = new Date(this.temp.timestamp);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          var tempData = Object.assign({}, _this5.temp);
          tempData.timestamp = +new Date(tempData.timestamp); // change Thu Nov 30 2017 16:41:05 GMT+0800 (CST) to 1512031311464

          (0, _article.updateArticle)(tempData).then(function () {
            var index = _this5.list.findIndex(function (v) {
              return v.id === _this5.temp.id;
            });

            _this5.list.splice(index, 1, _this5.temp);

            _this5.dialogFormVisible = false;

            _this5.$notify({
              title: 'Success',
              message: 'Update Successfully',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    },
    handleDelete: function handleDelete(row, index) {
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      });
      this.list.splice(index, 1);
    },
    handleFetchPv: function handleFetchPv(pv) {
      var _this6 = this;

      (0, _article.fetchPv)(pv).then(function (response) {
        _this6.pvData = response.data.pvData;
        _this6.dialogPvVisible = true;
      });
    },
    handleDownload: function handleDownload() {
      var _this7 = this;

      this.downloadLoading = true;
      Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        var filterVal = ['timestamp', 'title', 'type', 'importance', 'status'];

        var data = _this7.formatJson(filterVal);

        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: 'table-list'
        });
        _this7.downloadLoading = false;
      });
    },
    formatJson: function formatJson(filterVal) {
      return this.list.map(function (v) {
        return filterVal.map(function (j) {
          if (j === 'timestamp') {
            return (0, _utils.parseTime)(v[j]);
          } else {
            return v[j];
          }
        });
      });
    },
    getSortClass: function getSortClass(key) {
      var sort = this.listQuery.sort;
      return sort === "+".concat(key) ? 'ascending' : 'descending';
    }
  }
};
exports.default = _default;