var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "maincontainerviewprofessionalreport" } },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [_vm._v("Logs de Acesso")]),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            { staticClass: "row-bg no-print", attrs: { type: "flex" } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("strong", [_vm._v("Filtros")]),
                    _c("img", {
                      attrs: { src: require("@/assets/images/icon-pdf.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.dadosSelected,
                        },
                        on: { click: _vm.generatePdf },
                      },
                      [_vm._v("Gerar PDF")]
                    ),
                    _c("img", {
                      staticStyle: { "margin-right": "3rem" },
                      attrs: { src: require("@/assets/images/icon-excel.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.dadosSelected,
                        },
                        on: { click: _vm.generateExcel },
                      },
                      [_vm._v("Gerar Excel")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-style" },
                  [
                    _c(
                      "el-form",
                      { staticClass: "form-style", attrs: { inline: true } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Logs de")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Logs de",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_inicial,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "data_inicial", $$v)
                                },
                                expression: "filtros.data_inicial",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Logs até")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Logs até",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_final,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "data_final", $$v)
                                },
                                expression: "filtros.data_final",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tags-filters-style" },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("Filtrar por: ")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.tipoprofissional === "" &&
                                      _vm.filtros.bairro === "" &&
                                      _vm.filtros.estado === "" &&
                                      _vm.filtros.data_inicial === "" &&
                                      _vm.filtros.data_final === "" &&
                                      _vm.filtros.zona === "",
                                    expression:
                                      "(filtros.tipoprofissional === '') && (filtros.bairro === '') && (filtros.estado === '') && (filtros.data_inicial === '') && (filtros.data_final === '') && (filtros.zona === '')",
                                  },
                                ],
                              },
                              [_vm._v("Os seus filtros aparecerão aqui.")]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.tipoprofissional != "",
                                    expression:
                                      "filtros.tipoprofissional != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.tipoprofissional
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Profissional: " +
                                    _vm._s(_vm.filtros.tipoprofissional)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.estado != "",
                                    expression: "filtros.estado != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.estado)
                                  },
                                },
                              },
                              [_vm._v("Estado: " + _vm._s(_vm.estado))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.cidade != "",
                                    expression: "filtros.cidade != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.cidade)
                                  },
                                },
                              },
                              [_vm._v("Cidade: " + _vm._s(_vm.filtros.cidade))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.bairro != "",
                                    expression: "filtros.bairro != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.bairro)
                                  },
                                },
                              },
                              [_vm._v("Bairro: " + _vm._s(_vm.filtros.bairro))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.data_inicial != "",
                                    expression: "filtros.data_inicial != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_inicial
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Logs de: " + _vm._s(_vm.filtros.data_inicial)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.data_final != "",
                                    expression: "filtros.data_final != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_final
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Logs até: " + _vm._s(_vm.filtros.data_final)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.zona != "",
                                    expression: "filtros.zona != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.zona)
                                  },
                                },
                              },
                              [_vm._v("Zona: " + _vm._s(_vm.filtros.zona))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "medium",
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.cleanFilters },
                              },
                              [_vm._v("Limpar")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.sending,
                                  disabled: _vm.sending,
                                  size: "medium",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.filter },
                              },
                              [_vm._v("Filtrar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c("el-card", { staticClass: "box-card-results" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-card",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("RESULTADOS")])]
                    ),
                    _c("div", { staticClass: "content-card" }, [
                      _c("span", [_vm._v(_vm._s(_vm.dados.length))]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex", gutter: 32 },
            },
            [
              _c(
                "el-card",
                { staticClass: "result-style" },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "table-style",
                      attrs: { data: _vm.logsPaginated },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "nome", label: "NOME" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "username", label: "USUÁRIO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "email", label: "E-MAIL" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "criado_em", label: "DATA" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "horas", label: "HORAS" },
                      }),
                    ],
                    1
                  ),
                  _vm.logsFiltered.length > 0
                    ? _c("el-pagination", {
                        attrs: {
                          background: "",
                          "page-size": _vm.logsPerPage,
                          layout: "prev, pager, next",
                          total: _vm.logsFiltered.length,
                        },
                        on: { "current-change": _vm.changePage },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }