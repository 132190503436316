"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;

require("core-js/modules/es6.typed.uint8-array");

require("core-js/modules/es6.regexp.split");

/**
 * database64文件格式转换为2进制
 *
 * @param  {[String]} data dataURL 的格式为 “data:image/png;base64,****”,逗号之前都是一些说明性的文字，我们只需要逗号之后的就行了
 * @param  {[String]} mime [description]
 * @return {[blob]}      [description]
 */
function _default(data, mime) {
  data = data.split(',')[1];
  data = window.atob(data);
  var ia = new Uint8Array(data.length);

  for (var i = 0; i < data.length; i++) {
    ia[i] = data.charCodeAt(i);
  } // canvas.toDataURL 返回的默认格式就是 image/png


  return new Blob([ia], {
    type: mime
  });
}