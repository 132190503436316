var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: _vm.id,
      staticClass: "dropzone",
      attrs: { id: _vm.id, action: _vm.url },
    },
    [_c("input", { attrs: { type: "file", name: "file" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }