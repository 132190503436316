var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card" }, [
    _c(
      "div",
      { attrs: { id: "maincomponentformhospital" } },
      [
        _c(
          "el-form",
          {
            attrs: {
              inline: false,
              model: _vm.formData,
              "label-width": "auto",
              "label-position": "top",
              size: "medium",
            },
          },
          [
            _c(
              "el-form-item",
              [
                _c("span", [_vm._v("Nome"), _c("span", [_vm._v("*")])]),
                _c("el-input", {
                  staticClass: "input-style",
                  attrs: { placeholder: "Nome Completo", type: "text" },
                  model: {
                    value: _vm.formData.nome,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "nome", $$v)
                    },
                    expression: "formData.nome",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("span", [_vm._v("Estado"), _c("span", [_vm._v("*")])]),
                _c(
                  "el-select",
                  {
                    staticClass: "input-style",
                    attrs: { placeholder: "Estado" },
                    model: {
                      value: _vm.formData.estado,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "estado", $$v)
                      },
                      expression: "formData.estado",
                    },
                  },
                  _vm._l(_vm.listaestados, function (estado, i) {
                    return _c("el-option", {
                      key: i,
                      attrs: { label: estado.no_uf, value: estado.co_uf },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c("span", [_vm._v("Cidade"), _c("span", [_vm._v("*")])]),
                _c("el-autocomplete", {
                  staticClass: "input-style inline-input",
                  attrs: {
                    "fetch-suggestions": _vm.listacidadescomplete,
                    placeholder: "Selecione",
                  },
                  on: { select: _vm.handleSelectCidade },
                  model: {
                    value: _vm.formData.cidadeselected,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "cidadeselected", $$v)
                    },
                    expression: "formData.cidadeselected",
                  },
                }),
              ],
              1
            ),
            _c("br"),
            _vm.error !== ""
              ? _c("el-alert", {
                  attrs: { title: _vm.error, type: "error", closable: false },
                })
              : _vm._e(),
            _vm.success
              ? _c("el-alert", {
                  attrs: {
                    title:
                      "Hospital " +
                      (_vm.hospital !== null ? "atualizado" : "criado") +
                      " com sucesso",
                    type: "success",
                    closable: false,
                  },
                })
              : _vm._e(),
            _c("br"),
            _c(
              "el-form-item",
              { staticClass: "buttons" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.sending,
                      disabled: _vm.sending,
                      size: "medium",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.submit()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.hospital === null ? "Cadastrar" : "Salvar")
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }