"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAvaliacoes = filterAvaliacoes;
exports.getAvaliacoes = getAvaliacoes;
exports.getCasosResumo = getCasosResumo;
exports.getFichasResumo = getFichasResumo;
exports.getGeoData = getGeoData;
exports.getGrupoRiscoPorCidade = getGrupoRiscoPorCidade;
exports.getMapa = getMapa;

var _request = _interopRequireDefault(require("@/utils/request"));

function getGrupoRiscoPorCidade(question_id) {
  return (0, _request.default)({
    url: '/stats/risco/',
    method: 'get',
    params: {
      question_id: question_id
    }
  });
}

function getGeoData() {
  return (0, _request.default)({
    url: '/stats/geodata/',
    method: 'get'
  });
}

function getAvaliacoes() {
  return (0, _request.default)({
    url: '/avaliacoes/formularios/',
    method: 'get'
  });
}

function getMapa() {
  return (0, _request.default)({
    url: '/stats/mapa/',
    method: 'get'
  });
}

function getCasosResumo() {
  return (0, _request.default)({
    url: '/stats/casos/qtd/',
    method: 'get'
  });
}

function getFichasResumo() {
  return (0, _request.default)({
    url: '/stats/fichas/qtd/',
    method: 'get'
  });
}

function filterAvaliacoes(data) {
  return (0, _request.default)({
    url: '/avaliacoes/formularios/?' + data,
    method: 'get'
  });
}