var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "components-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("tinymce", {
          attrs: { height: 300 },
          model: {
            value: _vm.content,
            callback: function ($$v) {
              _vm.content = $$v
            },
            expression: "content",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "editor-content",
      domProps: { innerHTML: _vm._s(_vm.content) },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v(
        "\n    Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.\n    "
      ),
      _c(
        "a",
        {
          staticClass: "link-type",
          attrs: {
            target: "_blank",
            href: "https://panjiachen.github.io/vue-element-admin-site/component/rich-editor.html",
          },
        },
        [_vm._v("Documentation")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }