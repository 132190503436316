"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.barreiras = barreiras;
exports.cidades = cidades;
exports.estados = estados;
exports.filtrarBarreiras = filtrarBarreiras;

var _request = _interopRequireDefault(require("@/utils/request"));

function barreiras(filter) {
  return (0, _request.default)({
    url: "/barreiras/visitas/?".concat(filter),
    method: 'get'
  });
}

function estados() {
  return (0, _request.default)({
    url: '/core/uf/',
    method: 'get'
  });
}

function cidades(estado) {
  return (0, _request.default)({
    url: "core/localidades/?uf=".concat(estado),
    method: 'get'
  });
}

function filtrarBarreiras(data) {
  return (0, _request.default)({
    url: '/barreiras/visitas/?' + data,
    method: 'get'
  });
}