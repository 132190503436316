"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _stats = require("@/api/stats");

var state = {
  mapData: {},
  geoData: [],
  mapa: [],
  casos: {
    gutter: 10,
    offset: 1,
    col: 4,
    panels: [{
      label: "Recuperados",
      value: 0
    }, {
      label: "Alta Suspeita",
      value: 0
    }, {
      label: "Média Suspeita",
      value: 0
    }, {
      label: "Obitos",
      value: 0
    }, {
      label: "Confirmados",
      value: 0
    }]
  },
  fichas: {
    gutter: 10,
    offset: 0,
    col: 6,
    panels: [{
      label: "Diabetes",
      value: 0
    }, {
      label: "Hipertensão",
      value: 0
    }, {
      label: "Prob. Renais",
      value: 0
    }, {
      label: "Prob. Respiratório",
      value: 0
    }]
  }
};
var mutations = {
  SET_MAPDATA: function SET_MAPDATA(state, obj) {
    state.mapData = obj;
  },
  SET_GEODATA: function SET_GEODATA(state, obj) {
    state.geoData = obj;
  },
  SET_MAPA: function SET_MAPA(state, mapa) {
    state.mapa = mapa;
  },
  SET_CASOS_RESUMO: function SET_CASOS_RESUMO(state, obj) {
    state.casosResumo = obj;
  },
  SET_FICHAS_RESUMO: function SET_FICHAS_RESUMO(state, obj) {
    state.fichasResumo = obj;
  }
};
var actions = {
  getGrupoRisco: function getGrupoRisco(_ref, question_id) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _stats.getGrupoRiscoPorCidade)(question_id).then(function (response) {
        commit("SET_MAPDATA", response);
        var data = response.data;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getGeoData: function getGeoData(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      (0, _stats.getGeoData)().then(function (response) {
        var results = response.results;
        commit("SET_GEODATA", results);
        resolve(results);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getMapa: function getMapa(_ref3) {
    var commit = _ref3.commit;
    return (0, _stats.getMapa)().then(function (response) {
      // const { results } = response;
      commit("SET_MAPA", response);
      return response;
    });
  },
  getCasosResumo: function getCasosResumo(_ref4) {
    var commit = _ref4.commit;
    return (0, _stats.getCasosResumo)().then(function (response) {
      commit("SET_CASOS_RESUMO", response);
      return response;
    });
  },
  getFichasResumo: function getFichasResumo(_ref5) {
    var commit = _ref5.commit;
    return (0, _stats.getFichasResumo)().then(function (response) {
      commit("SET_FICHAS_RESUMO", response);
      return response;
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;