"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterSetores = filterSetores;
exports.getSetorById = getSetorById;
exports.listSetores = listSetores;
exports.newSetor = newSetor;
exports.updateSetor = updateSetor;

var _request = _interopRequireDefault(require("@/utils/request"));

function newSetor(data) {
  return (0, _request.default)({
    url: '/core/setores/',
    method: 'post',
    data: data
  });
}

function updateSetor(data, id) {
  return (0, _request.default)({
    url: '/core/setores/' + id,
    method: 'patch',
    data: data
  });
}

function getSetorById(id) {
  return (0, _request.default)({
    url: '/core/setores/' + id,
    method: 'get'
  });
}

function listSetores() {
  return (0, _request.default)({
    url: '/core/setores/',
    method: 'get'
  });
}

function filterSetores(data) {
  return (0, _request.default)({
    url: '/core/setores/?' + data,
    method: 'get'
  });
}