var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "tabcomponentmaincontainer" } }, [
    _c(
      "div",
      {
        staticClass: "titlemain",
        class: { alertmessages: _vm.alert },
        style: {
          "background-color": _vm.tabcolor ? _vm.tabcolor : "rgb(94, 94, 117)",
          width: _vm.tabwidth ? _vm.tabwidth : "200px",
        },
        on: { click: _vm.minmax },
      },
      [
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.tabname))]),
        _vm.showclosebutton
          ? _c(
              "span",
              {
                staticClass: "close",
                staticStyle: { float: "right" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("X")]
            )
          : _vm._e(),
        _c("img", {
          staticStyle: {
            width: "20px",
            "margin-right": "1rem",
            "margin-top": "-8px",
            float: "right",
          },
          attrs: { src: require("@/assets/images/downloaded_minimize.svg") },
        }),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.maximized,
            expression: "maximized",
          },
        ],
        staticClass: "contentmain",
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }