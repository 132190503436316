"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var _barreiras = require("@/api/barreiras");

var _components = _interopRequireDefault(require("@/router/modules/components"));

var state = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  estados: [],
  cidadesOrigem: [],
  cidadesDestino: [],
  origem: {
    cidade: null,
    estado: null
  },
  destino: {
    cidade: null,
    estado: null
  }
};
var mutations = {
  SET_COUNT: function SET_COUNT(state, count) {
    state.count = count;
  },
  SET_NEXT: function SET_NEXT(state, next) {
    state.next = next;
  },
  SET_PREVIOUS: function SET_PREVIOUS(state, previous) {
    state.previous = previous;
  },
  SET_RESULTS: function SET_RESULTS(state, results) {
    state.results = results;
  },
  SET_ESTADOS: function SET_ESTADOS(state, estados) {
    state.estados = estados;
  },
  SET_CIDADES_ORIGEM: function SET_CIDADES_ORIGEM(state, cidadesOrigem) {
    state.cidadesOrigem = cidadesOrigem;
  },
  SET_CIDADES_DESTINO: function SET_CIDADES_DESTINO(state, cidadesDestino) {
    state.cidadesDestino = cidadesDestino;
  },
  SET_ESTADO_ORIGEM: function SET_ESTADO_ORIGEM(state, estado) {
    state.origem.estado = estado;
  },
  SET_CIDADE_ORIGEM: function SET_CIDADE_ORIGEM(state, cidade) {
    state.origem.cidade = cidade;
  },
  SET_ESTADO_DESTINO: function SET_ESTADO_DESTINO(state, estado) {
    state.destino.estado = estado;
  },
  SET_CIDADE_DESTINO: function SET_CIDADE_DESTINO(state, cidade) {
    state.destino.cidade = cidade;
  }
};
var actions = {
  list: function list(_ref) {
    var commit = _ref.commit,
        state = _ref.state;
    var filter = [];

    if (state.origem.estado) {
      var estadoOrigem = state.estados.filter(function (item) {
        return item.co_uf == state.origem.estado;
      })[0];
      if (estadoOrigem) filter.push("uf_origem=".concat(estadoOrigem.sg_uf));
    }

    if (state.origem.cidade) filter.push("localidade_origem=".concat(state.origem.cidade));

    if (state.destino.estado) {
      var estadoDestino = state.estados.filter(function (item) {
        return item.co_uf == state.destino.estado;
      })[0];
      if (estadoDestino) filter.push("uf_destino=".concat(estadoDestino.sg_uf));
    }

    if (state.destino.cidade) filter.push("localidade_destino=".concat(state.destino.cidade));
    return (0, _barreiras.barreiras)(filter.join('&')).then(function (response) {
      var count = response.count,
          next = response.next,
          previous = response.previous,
          results = response.results;
      commit("SET_COUNT", count);
      commit("SET_NEXT", next);
      commit("SET_PREVIOUS", previous);

      for (var i = 0; i < results.length; i++) {
        if (results[i].sintomas != null) {
          var x = results[i].sintomas;
          x = x.replace(/\|/g, ", ");
          x = x.replace(/_/g, " ");

          if (x == "Dor no corpo ,  Coriza") {
            x = "Dor no corpo, coriza";
          } else if (x == "sintomas tosse seca") {
            x = "Tosse seca";
          } else if (x == "sintomas dor garganta, sintomas febre, sintomas dor no corpo, sintomas espirros, viajou recentement") {
            x = "Dor de garganta, febre, dor no corpo, espirros, viajou recentemente";
          }

          results[i].sintomas = x;
        }
      } //console.log("Results:  ", results);


      commit("SET_RESULTS", results || []);
    });
  },
  listEstados: function listEstados(_ref2) {
    var commit = _ref2.commit;
    return (0, _barreiras.estados)().then(function (response) {
      var results = response.results;
      commit("SET_ESTADOS", results || []);
    });
  },
  listCidades: function listCidades(_ref3, _ref4) {
    var commit = _ref3.commit;
    var estado = _ref4.estado,
        _ref4$origem = _ref4.origem,
        origem = _ref4$origem === void 0 ? true : _ref4$origem;
    return (0, _barreiras.cidades)(estado).then(function (response) {
      var results = response.results;

      if (origem) {
        commit("SET_CIDADES_ORIGEM", results || []);
      } else {
        commit("SET_CIDADES_DESTINO", results || []);
      }
    });
  },
  selecionarEstado: function selecionarEstado(_ref5, _ref6) {
    var commit = _ref5.commit,
        dispatch = _ref5.dispatch;
    var estado = _ref6.estado,
        _ref6$origem = _ref6.origem,
        origem = _ref6$origem === void 0 ? true : _ref6$origem;

    if (origem) {
      commit("SET_ESTADO_ORIGEM", estado);
      commit("SET_CIDADE_ORIGEM", null);
    } else {
      commit("SET_ESTADO_DESTINO", estado);
      commit("SET_CIDADE_DESTINO", null);
    }

    dispatch("listCidades", {
      estado: estado,
      origem: origem
    });
    dispatch("list");
  },
  selecionarCidade: function selecionarCidade(_ref7, _ref8) {
    var commit = _ref7.commit,
        dispatch = _ref7.dispatch;
    var cidade = _ref8.cidade,
        _ref8$origem = _ref8.origem,
        origem = _ref8$origem === void 0 ? true : _ref8$origem;

    if (origem) {
      commit("SET_CIDADE_ORIGEM", cidade);
    } else {
      commit("SET_CIDADE_DESTINO", cidade);
    }

    dispatch("list");
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;