var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "maincontainerviewbarreiraslisting" },
        [
          _c("el-row", { attrs: { type: "flex", justify: "center" } }, [
            _c("h2", [_vm._v("Barreiras Sanitárias")]),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "panel-group",
              attrs: { gutter: 40, type: "flex", justify: "center" },
            },
            [
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 10, sm: 10, lg: 8 },
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-people" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "peoples",
                            "class-name": "card-panel-icon",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("Visitantes"),
                        ]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.count,
                            duration: 3000,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 10, sm: 10, lg: 8 },
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-message" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "peoples",
                            "class-name": "card-panel-icon",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("Casos de alerta"),
                        ]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": 0,
                            duration: 3000,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-row", {
            attrs: { gutter: 10, type: "flex", justify: "start" },
          }),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { inline: true, size: "medium" },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c("span", [_vm._v("Buscar por")]),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.filtros.tipobusca,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "tipobusca", $$v)
                                },
                                expression: "filtros.tipobusca",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Nome", value: "nome" },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "CPF", value: "cpf" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.filtros.tipobusca === "nome"
                        ? _c(
                            "el-form-item",
                            [
                              _c("span", [_vm._v("Nome")]),
                              _c("br"),
                              _vm._v(" "),
                              _c("el-input", {
                                attrs: { placeholder: "Nome", type: "text" },
                                model: {
                                  value: _vm.filtros.nome,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "nome", $$v)
                                  },
                                  expression: "filtros.nome",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.filtros.tipobusca === "cpf"
                        ? _c(
                            "el-form-item",
                            [
                              _c("span", [_vm._v("CPF")]),
                              _c("br"),
                              _vm._v(" "),
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "###########",
                                    expression: "'###########'",
                                  },
                                ],
                                attrs: { placeholder: "CPF", type: "number" },
                                model: {
                                  value: _vm.filtros.cpf,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "cpf", $$v)
                                  },
                                  expression: "filtros.cpf",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c("el-card", { staticClass: "box-card-results" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-card",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("RESULTADOS")])]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-card" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.barreirasFiltered.length)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "40px" } },
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableData",
                      staticClass: "table-style",
                      attrs: {
                        data: _vm.barreirasPaginated,
                        "row-class-name": _vm.casoAlerta,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "nome", label: "NOME" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "cpf", label: "CPF" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "profissao", label: "PROFISSÃO" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "temperatura", label: "TEMP" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "data_nascimento", label: "NASCIMENTO" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.barreirasPaginated[scope.$index]
                                    .data_nascimento != null
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.barreirasPaginated[
                                                scope.$index
                                              ].data_nascimento.replace(
                                                /(\d{4})-(\d{2})-(\d{2})/,
                                                "$3/$2/$1"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          510511726
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "sintomas", label: "SINTOMAS" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "criado_em", label: "COLETA" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.barreirasPaginated[scope.$index]
                                    .criado_em != null
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.barreirasPaginated[
                                              scope.$index
                                            ].criado_em[0].replace(
                                              /(\d{4})-(\d{2})-(\d{2})/,
                                              "$3/$2/$1"
                                            )
                                          ) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1398695155
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _vm.barreirasFiltered.length > 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-size": _vm.barreirasPerPage,
                      layout: "prev, pager, next",
                      total: _vm.barreirasFiltered.length,
                    },
                    on: { "current-change": _vm.changePage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }