"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  data: function data() {
    return {
      statusAvaliacoes: "",
      fullscreenLoading: true,
      title: '',
      subtitle: '',
      dados: [],
      tp_relatorio: '',
      key: '',
      resultados: {
        qtd_visitas_cidade: {
          label: "",
          value: ""
        },
        qtd_visitas_tempo: {
          label: "",
          value: ""
        },
        resultados_encontrados: {
          label: "Resultados encontrados",
          value: 0
        }
      }
    };
  },
  created: function created() {
    // Obtendo dados do localStorage.
    this.title = localStorage.getItem('tituloRelatorio'); //this.subtitle = localStorage.getItem('subtituloRelatorio');

    this.dados = JSON.parse(localStorage.getItem('filtro')); // Obtendo quantidade de funcionários do resultado.
    //this.resultados.resultados_encontrados.value = this.dados.length;
    //this.tp_relatorio = this.$route.query.relatorio;
  },
  mounted: function mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      document.title = "Expedido por: " + this.username;
      setTimeout(function () {
        _this.fullscreenLoading = false;

        _this.$nextTick(function () {
          window.print();
        });
      }, 3000);
    }
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['username', 'domain']))
};
exports.default = _default;