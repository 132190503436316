"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _TabPane = _interopRequireDefault(require("./components/TabPane"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Tab',
  components: {
    TabPane: _TabPane.default
  },
  data: function data() {
    return {
      tabMapOptions: [{
        label: 'China',
        key: 'CN'
      }, {
        label: 'USA',
        key: 'US'
      }, {
        label: 'Japan',
        key: 'JP'
      }, {
        label: 'Eurozone',
        key: 'EU'
      }],
      activeName: 'CN',
      createdTimes: 0
    };
  },
  watch: {
    activeName: function activeName(val) {
      this.$router.push("".concat(this.$route.path, "?tab=").concat(val));
    }
  },
  created: function created() {
    // init the default selected tab
    var tab = this.$route.query.tab;

    if (tab) {
      this.activeName = tab;
    }
  },
  methods: {
    showCreatedTimes: function showCreatedTimes() {
      this.createdTimes = this.createdTimes + 1;
    }
  }
};
exports.default = _default;