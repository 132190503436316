"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var defaultFormThead = ['apple', 'banana'];
var _default = {
  data: function data() {
    return {
      tableData: [{
        name: 'fruit-1',
        apple: 'apple-10',
        banana: 'banana-10',
        orange: 'orange-10'
      }, {
        name: 'fruit-2',
        apple: 'apple-20',
        banana: 'banana-20',
        orange: 'orange-20'
      }],
      key: 1,
      // table key
      formTheadOptions: ['apple', 'banana', 'orange'],
      checkboxVal: defaultFormThead,
      // checkboxVal
      formThead: defaultFormThead // 默认表头 Default header

    };
  },
  watch: {
    checkboxVal: function checkboxVal(valArr) {
      this.formThead = this.formTheadOptions.filter(function (i) {
        return valArr.indexOf(i) >= 0;
      });
      this.key = this.key + 1; // 为了保证table 每次都会重渲 In order to ensure the table will be re-rendered each time
    }
  }
};
exports.default = _default;