"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/typeof.js"));

require("core-js/modules/es6.regexp.to-string");

var _stats = require("@/api/stats");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      tableData: [],
      data: {},
      isLoading: true,
      previous: []
    };
  },
  created: function created() {
    var _this = this;

    this.isLoading = true;
    (0, _stats.getAvaliacoes)().then(function (response) {
      var id = _this.$route.params.id;
      var results = response.results; // console.log(results)

      for (var index = 0; index < results.length; index++) {
        if (results[index].perfil_cidadao && results[index].hasOwnProperty('perfil_cidadao')) {
          results[index]['nome'] = results[index].perfil_cidadao.nome_completo;
        } else {
          if (results[index].nome_cidadao_esus != '') {
            results[index]['nome'] = results[index].nome_cidadao_esus;
          } else {
            results[index]['nome'] = 'Cidadão Cadastrado pelo ESUS';
          }
        }

        results[index]['dt'] = new Date(results[index]['criado_em']).toString('dd/MM/yyyy'); // console.log(results[index], id)

        if (results[index].id == id) {
          _this.data = results[index];
        }
      }

      var previousavaliations = [];

      for (var index = 0; index < results.length; index++) {
        console.log((0, _typeof2.default)(results[index].perfil_cidadao));

        if (results[index].usuario === _this.data.usuario) {
          results[index].active = '0';
          previousavaliations.push(results[index]);
        }
      }

      _this.previous = previousavaliations;
      _this.isLoading = false;
    });
  },
  methods: {}
};
exports.default = _default;