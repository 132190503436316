"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TinymceDemo',
  components: {
    Tinymce: _Tinymce.default
  },
  data: function data() {
    return {
      content: "<h1 style=\"text-align: center;\">Welcome to the TinyMCE demo!</h1><p style=\"text-align: center; font-size: 15px;\"><img title=\"TinyMCE Logo\" src=\"//www.tinymce.com/images/glyph-tinymce@2x.png\" alt=\"TinyMCE Logo\" width=\"110\" height=\"97\" /><ul>\n        <li>Our <a href=\"//www.tinymce.com/docs/\">documentation</a> is a great resource for learning how to configure TinyMCE.</li><li>Have a specific question? Visit the <a href=\"https://community.tinymce.com/forum/\">Community Forum</a>.</li><li>We also offer enterprise grade support as part of <a href=\"https://tinymce.com/pricing\">TinyMCE premium subscriptions</a>.</li>\n      </ul>"
    };
  }
};
exports.default = _default;