var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c("el-steps", {
        attrs: {
          active: _vm.active,
          "align-center": "",
          "finish-status": "success",
        },
      }),
      _c("el-step", { attrs: { title: "Informações da Postagem" } }),
      _c("el-step", { attrs: { title: "Conteúdo da Postagem" } }),
      _c(
        "div",
        { attrs: { id: "formagentecontainermain" } },
        [
          _c(
            "el-form",
            {
              attrs: {
                inline: false,
                model: _vm.formData,
                "label-position": "top",
                size: "medium",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 0,
                      expression: "active === 0",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("Título"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: { placeholder: "Título", type: "text" },
                        model: {
                          value: _vm.formData.titulo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "titulo", $$v)
                          },
                          expression: "formData.titulo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [
                        _vm._v("Imagem de Capa"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c("div", { staticClass: "alertImageSize" }, [
                        _vm._v("Arquivos jpg/png de até 500kb"),
                      ]),
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action:
                              "https://jsonplaceholder.typicode.com/posts/",
                            "on-change": _vm.handleAvatarSuccess,
                            "show-file-list": false,
                            multiple: false,
                            "auto-upload": false,
                          },
                        },
                        [
                          _vm.formData.imagem_de_capa
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.formData.imagem_de_capa },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [
                        _vm._v("Chamada"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: { placeholder: "Chamada", type: "textarea" },
                        model: {
                          value: _vm.formData.chamada,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "chamada", $$v)
                          },
                          expression: "formData.chamada",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [
                        _vm._v("Status da Postagem"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass: "input-style",
                          attrs: { placeholder: "Status" },
                          model: {
                            value: _vm.formData.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "status", $$v)
                            },
                            expression: "formData.status",
                          },
                        },
                        _vm._l(_vm.tipos_de_status, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.perfil.tipo_instituicao == "EMPRESA" &&
                            _vm.tipo != "sobre",
                          expression:
                            "(perfil.tipo_instituicao == 'EMPRESA' && tipo != 'sobre')",
                        },
                      ],
                    },
                    [
                      _c("span", [
                        _vm._v("Tipo da Postagem"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass: "input-style",
                          attrs: { placeholder: "Tipo da Postagem" },
                          model: {
                            value: _vm.tipo,
                            callback: function ($$v) {
                              _vm.tipo = $$v
                            },
                            expression: "tipo",
                          },
                        },
                        _vm._l(_vm.tipos_postagem, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 1,
                      expression: "active === 1",
                    },
                  ],
                },
                [
                  _c("el-form-item", [
                    _c("span", [
                      _vm._v("Corpo da Postagem"),
                      _c("span", [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      [
                        _c("tinymce", {
                          attrs: { height: 300 },
                          model: {
                            value: _vm.formData.conteudo,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "conteudo", $$v)
                            },
                            expression: "formData.conteudo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.error !== ""
                ? _c("el-alert", {
                    attrs: { title: _vm.error, type: "error", closable: false },
                  })
                : _vm._e(),
              _vm.success
                ? _c("el-alert", {
                    attrs: {
                      title:
                        "Postagem " +
                        (_vm.postagem !== null ? "atualizada" : "criada") +
                        " com sucesso",
                      type: "success",
                      closable: false,
                    },
                  })
                : _vm._e(),
              _c("br"),
              _c(
                "el-form-item",
                { staticClass: "buttons" },
                [
                  _vm.active === 1
                    ? _c(
                        "el-button",
                        { attrs: { size: "medium" }, on: { click: _vm.prev } },
                        [_vm._v("Voltar")]
                      )
                    : _vm._e(),
                  _vm.active === 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.next(_vm.formData)
                            },
                          },
                        },
                        [_vm._v("Avançar")]
                      )
                    : _vm._e(),
                  _vm.active === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.sending,
                            disabled: _vm.sending,
                            size: "medium",
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.postagem === null
                                ? "Criar postagem"
                                : "Salvar"
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }