"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAlunos = filterAlunos;
exports.getAlunosById = getAlunosById;
exports.listAlunos = listAlunos;
exports.newAlunos = newAlunos;
exports.updateAlunos = updateAlunos;

var _request = _interopRequireDefault(require("@/utils/request"));

function newAlunos(data) {
  return (0, _request.default)({
    url: '/core/alunos/',
    method: 'post',
    data: data
  });
}

function updateAlunos(data, id) {
  return (0, _request.default)({
    url: '/core/alunos/' + id,
    method: 'patch',
    data: data
  });
}

function getAlunosById(id) {
  return (0, _request.default)({
    url: '/core/alunos/' + id,
    method: 'get'
  });
}

function listAlunos() {
  return (0, _request.default)({
    url: '/core/alunos/',
    method: 'get'
  });
}

function filterAlunos(data) {
  return (0, _request.default)({
    url: '/core/alunos/?' + data,
    method: 'get'
  });
}
/*export function getAlunosByCPF(cpf) {
  return request({
    url: '/core/funcionarios/cpf/' + cpf,
    method: 'get'
  })
}*/