var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainpageselectlocalidadecontainer" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { staticStyle: { margin: "auto" } },
            [
              _c(
                "div",
                { staticClass: "row1", staticStyle: { width: "300px" } },
                [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: require("@/assets/images/SAICovid-19.png") },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "row2",
                  staticStyle: {
                    width: "300px",
                    display: "flex",
                    "padding-top": "1rem",
                    "padding-bottom": "1rem",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col1",
                      staticStyle: {
                        width: "130px",
                        display: "flex",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("div", [
                        _c("img", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            src: require("@/assets/images/Prancheta_1.png"),
                          },
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col2", staticStyle: { width: "130px" } },
                    [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          src: require("@/assets/images/isbn_saic.jpeg"),
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c("br"),
              _c(
                "span",
                { staticStyle: { "font-size": "26px", color: "#2d3a4ba6" } },
                [_vm._v("Informe sua localidade:")]
              ),
              _c(
                "el-row",
                { staticStyle: { "padding-top": "1rem" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        { ref: "form", attrs: { "label-width": "120px" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Estado" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "Selecione" },
                                  model: {
                                    value: _vm.estado,
                                    callback: function ($$v) {
                                      _vm.estado = $$v
                                    },
                                    expression: "estado",
                                  },
                                },
                                _vm._l(_vm.listaestados, function (estado, i) {
                                  return _c("el-option", {
                                    key: i,
                                    attrs: {
                                      label: estado.no_uf,
                                      value: estado.co_uf,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.secretarias.length > 0
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "Cidade" } },
                                [
                                  _c("el-autocomplete", {
                                    staticClass: "inline-input",
                                    attrs: {
                                      "fetch-suggestions": _vm.listasecretarias,
                                      placeholder: "Selecione",
                                    },
                                    on: { select: _vm.handleSelect },
                                    model: {
                                      value: _vm.secretariaselected,
                                      callback: function ($$v) {
                                        _vm.secretariaselected = $$v
                                      },
                                      expression: "secretariaselected",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.secretariaobj !== null
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { float: "right" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.done },
                                },
                                [_vm._v("Prosseguir")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.estadosemsecretarias
                ? _c("el-alert", {
                    attrs: {
                      title:
                        "Nenhuma secretaria disponível no seu estado está utilizando o SAIC",
                      type: "error",
                      closable: false,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("el-main"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }