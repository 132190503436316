var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.list,
        border: "",
        fit: "",
        "highlight-current-row": "",
      },
    },
    [
      _c("el-table-column", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          align: "center",
          label: "ID",
          width: "65",
          "element-loading-text": "请给我点时间！",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "180px", align: "center", label: "Date" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("parseTime")(
                        scope.row.timestamp,
                        "{y}-{m}-{d} {h}:{i}"
                      )
                    )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { "min-width": "300px", label: "Title" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [_vm._v(_vm._s(row.title))]),
                _vm._v(" "),
                _c("el-tag", [_vm._v(_vm._s(row.type))]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "110px", align: "center", label: "Author" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.author))])]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { width: "120px", label: "Importance" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return _vm._l(+scope.row.importance, function (n) {
                return _c("svg-icon", {
                  key: n,
                  attrs: { "icon-class": "star" },
                })
              })
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { align: "center", label: "Readings", width: "95" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_c("span", [_vm._v(_vm._s(scope.row.pageviews))])]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { "class-name": "status-col", label: "Status", width: "110" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var row = ref.row
              return [
                _c(
                  "el-tag",
                  { attrs: { type: _vm._f("statusFilter")(row.status) } },
                  [_vm._v("\n        " + _vm._s(row.status) + "\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }