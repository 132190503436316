"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;

var _typeof2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/typeof.js"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _Layoutsuporte = _interopRequireDefault(require("@/layout/Layoutsuporte"));

var _components = _interopRequireDefault(require("./modules/components"));

var _charts = _interopRequireDefault(require("./modules/charts"));

var _table = _interopRequireDefault(require("./modules/table"));

var _nested = _interopRequireDefault(require("./modules/nested"));

var _localidade = _interopRequireDefault(require("@/views/localidade"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/localidade',
  component: _localidade.default,
  hidden: true,
  name: 'Seleção de localidade'
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/recuperar-senha',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/forgot/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  //Dashboard
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  profileFilter: {
    'SECRETARIA': ['admin', 'acs']
  },
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  // Dashboard da empresa // PERFIL EMPRESA
  path: '/empresa',
  component: _layout.default,
  redirect: '/empresa',
  profileFilter: {
    'EMPRESA': ['admin', 'recepcionista', 'st_escola']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/empresa/index'));
      });
    },
    name: 'Página inicial',
    meta: {
      title: 'Página inicial',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  // Avaliações
  path: '/avaliacoes',
  component: _layout.default,
  meta: {
    title: 'Avaliações',
    icon: 'edit',
    affix: true
  },
  profileFilter: {
    'SECRETARIA': ['admin', 'acs'],
    'EMPRESA': ['admin']
  },
  children: [{
    path: '/avaliacoes/lista',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/avaliacoes/index'));
      });
    },
    name: 'ListarAvaliacoes',
    meta: {
      title: 'Listar',
      icon: 'list',
      affix: true
    }
  }, {
    path: '/avaliacoes/relatorio',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/views/avaliacoes/relatorio"));
      });
    },
    name: 'RelatorioAvaliacoes',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }]
}, {
  // Recados
  path: '/recados',
  component: _layout.default,
  profileFilter: {
    'EMPRESA': ['admin']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recados/index'));
      });
    },
    name: 'Recados',
    meta: {
      title: 'Recados',
      icon: 'list',
      affix: true
    }
  }]
}, {
  // Setores
  path: '/setores',
  component: _layout.default,
  meta: {
    title: 'Setores',
    icon: 'download_agente',
    affix: true
  },
  profileFilter: {
    'EMPRESA': ['admin', 'st_escola']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/setores/setor'));
      });
    },
    name: 'ListarSetores',
    meta: {
      title: 'Setores',
      icon: 'tree',
      affix: true
    }
  }, {
    path: 'funcionarios',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/setores/funcionarios/index'));
      });
    },
    name: 'ListarFuncionarios',
    meta: {
      title: 'Funcionários',
      icon: 'peoples',
      affix: true
    }
  }, {
    path: 'cadastroFuncionario',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/setores/funcionarios/funcionarioCadastro'));
      });
    },
    name: 'CadastrarFuncionario',
    meta: {
      title: 'Novo funcionário',
      icon: 'user_add',
      affix: true
    }
  }, {
    path: 'cadastroSetor',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/setores/setorCadastro'));
      });
    },
    name: 'CadastrarSetor',
    meta: {
      title: 'Novo setor',
      icon: 'tree-table',
      affix: true
    }
  }, {
    path: 'relatorioSetorFuncionario',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/setores/relatorio'));
      });
    },
    name: 'RelatorioSetorFuncionario',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }, {
    path: ':id',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/setores/editarSetor'));
      });
    },
    name: 'EditarSetor',
    meta: {
      title: 'Editar setor',
      breadcrumb: false
    }
  }, {
    path: ':id/funcionarios',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/setores/funcionarios/funcionario'));
      });
    },
    name: 'PageFuncionario',
    meta: {
      title: 'Detalhes do funcionário'
    }
  }]
}, {
  // Alunos
  path: '/alunos',
  component: _layout.default,
  meta: {
    title: 'Alunos',
    icon: 'download_agente',
    affix: true
  },
  profileFilter: {
    'EMPRESA': ['st_escola']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/alunos/index'));
      });
    },
    name: 'ListarAlunos',
    meta: {
      title: 'Alunos',
      icon: 'list',
      affix: true
    }
  }, {
    path: 'cadastroAlunos',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/alunos/alunoCadastro'));
      });
    },
    name: 'CadastrarAluno',
    meta: {
      title: 'Novo aluno',
      icon: 'user_add',
      affix: true
    }
  }, {
    path: 'relatorioAluno',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/alunos/relatorio'));
      });
    },
    name: 'RelatorioAluno',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }, {
    path: ':id',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/alunos/editarAluno'));
      });
    },
    name: 'EditarAluno',
    meta: {
      title: 'Editar aluno',
      breadcrumb: false
    }
  }]
}, {
  // Hospitais
  path: '/hospitais',
  component: _layout.default,
  meta: {
    title: 'Hospitais',
    icon: 'downloaded_hospital',
    affix: true
  },
  profileFilter: {
    'SECRETARIA': ['admin', 'acs']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/hospitais/index'));
      });
    },
    name: 'ListarHospitais',
    meta: {
      title: 'Gerenciar',
      icon: 'downloaded_list',
      affix: true
    }
  }, {
    path: 'cadastro',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/hospitais/cadastro'));
      });
    },
    name: 'CadastrarHospital',
    meta: {
      title: 'Cadastrar',
      icon: 'downloaded_hospital2',
      affix: true
    }
  }, {
    path: 'relatoriohospitais',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/hospitais/relatorio'));
      });
    },
    name: 'RelatorioHospitais',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }, {
    path: ':id',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/hospitais/hospital'));
      });
    },
    name: 'VisualizarHospital',
    meta: {
      title: 'Detalhes do hospital',
      icon: 'downloaded_hospital',
      affix: true
    }
  }, {
    path: ':idhospital/cadastrarprofissional',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes2/cadastro'));
      });
    },
    name: 'CadastrarProfissional',
    meta: {
      title: 'Cadastrar profissional',
      icon: 'downloaded_hospital',
      affix: true
    }
  }]
}, {
  // Agentes
  path: '/agentes',
  component: _layout.default,
  meta: {
    title: 'Agentes',
    icon: 'download_agente',
    affix: true
  },
  profileFilter: {
    'SECRETARIA': ['admin', 'acs']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes/index'));
      });
    },
    name: 'GerenciarUsuarios',
    meta: {
      title: 'Gerenciar',
      icon: 'list',
      affix: true
    }
  }, {
    path: 'cadastro',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes/cadastro'));
      });
    },
    name: 'CadastrarAgentes',
    meta: {
      title: 'Cadastrar',
      icon: 'user_add',
      affix: true
    }
  }, {
    path: 'relatorioagente',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes/relatorio'));
      });
    },
    name: 'RelatorioAgentes',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }, {
    path: ':id',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes/editar'));
      });
    },
    name: 'EditarAgente',
    meta: {
      title: 'Editar agente',
      breadcrumb: false
    }
  }]
}, {
  // Profissionais
  path: '/profissionais',
  component: _layout.default,
  meta: {
    title: 'Profissionais',
    icon: 'download_agente',
    affix: true
  },
  profileFilter: {
    'HOSPITAL': ['admin', 'medico']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes2/index'));
      });
    },
    name: 'GerenciarProfissionais',
    meta: {
      title: 'Listar',
      icon: 'downloaded_list',
      affix: true
    }
  }, {
    path: 'cadastro',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes2/cadastro'));
      });
    },
    name: 'CadastrarProfissional',
    meta: {
      title: 'Cadastrar',
      icon: 'user_add',
      affix: true
    }
  }, {
    path: 'relatorioprofissionais',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes2/relatorio'));
      });
    },
    name: 'RelatorioProfissional',
    meta: {
      title: 'Relatório',
      icon: 'list',
      affix: true
    }
  }, {
    path: ':id',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/agentes2/editar'));
      });
    },
    name: 'EditarProfissional',
    meta: {
      title: 'Editar profissional',
      breadcrumb: false
    }
  }]
}, {
  // Central de casos
  profileFilter: {
    'SECRETARIA': ['admin', 'acs']
  },
  path: '/centraldecasos',
  component: _layout.default,
  meta: {
    title: 'Central de casos',
    icon: 'downloaded_folder',
    affix: true
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/centraldecasos/index'));
      });
    },
    name: 'GerenciarCentralCasos',
    meta: {
      title: 'Gerenciar',
      icon: 'list',
      affix: true
    }
  }, {
    path: 'relatoriocentraldecasos',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/centraldecasos/relatorio'));
      });
    },
    name: 'RelatorioCentralCasos',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }]
},
/*{ // Central de casos - empresas
  profileFilter: {
    'EMPRESA': ['']
  },
  path: '/centraldecasosfuncionarios',
  component: Layout,
  meta: { title: 'Central de casos', icon: 'downloaded_folder', affix: true },
  children: [
    {
      path: '',
      component: () => import('@/views/centraldecasos-empresa/index'),
      name: 'GerenciarCentralCasosEmpresa',
      meta: { title: 'Gerenciar', icon: 'downloaded_folder', affix: true }
    },
    {
      path: 'relatoriocentraldecasos',
      component: () => import('@/views/centraldecasos-empresa/relatorio'),
      name: 'RelatorioCentralCasosEmpresa',
      meta: { title: 'Relatório', icon: 'list', affix:true}
    },
  ]
},
*/
{
  // Central de casos - empresas
  profileFilter: {
    'EMPRESA': ['recepcionista', 'admin']
  },
  path: '/centraldecasosempresa',
  component: _layout.default,
  meta: {
    title: 'Central de casos',
    icon: 'downloaded_folder',
    affix: true
  },
  children: [{
    path: 'cadastro',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/centraldecasos-empresa/cadastro'));
      });
    },
    name: 'CadastroCentralCasosEmpresa',
    meta: {
      title: 'Cadastro',
      icon: 'tree-table',
      affix: true
    }
  }, {
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/centraldecasos-empresa/index'));
      });
    },
    name: 'GerenciarCentralCasosEmpresa',
    meta: {
      title: 'Gerenciar',
      icon: 'list',
      affix: true
    }
  }, {
    path: 'relatoriocentraldecasosempresa',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/centraldecasos-empresa/relatorio'));
      });
    },
    name: 'RelatorioCentralCasosEmpresa',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }]
}, {
  // Hotsite
  profileFilter: {
    'SECRETARIA': ['admin', 'acs'],
    'EMPRESA': ['admin', 'recepcionista', 'st_escola']
  },
  path: '/hotsite',
  component: _layout.default,
  meta: {
    title: 'Hotsite',
    icon: 'international',
    affix: true
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/hotsite/index'));
      });
    },
    name: 'Hotsite',
    meta: {
      title: 'Postagens',
      icon: 'list',
      affix: true
    }
  }, {
    //path: 'postagem/nova',
    path: 'novapostagem',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/hotsite/novapostagem'));
      });
    },
    name: 'NovaPostagem',
    meta: {
      title: 'Criar postagem',
      icon: 'edit',
      affix: true
    }
  }, {
    path: 'sobre',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/hotsite/sobre'));
      });
    },
    name: 'Sobre',
    meta: {
      title: 'Sobre',
      icon: 'list',
      affix: true
    }
  }, {
    path: 'sobre/nova',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/hotsite/novapostagemsobre'));
      });
    },
    name: 'NovaSobre',
    meta: {
      title: 'Criar bloco de sobre',
      icon: 'edit',
      affix: true
    }
  }]
}, {
  // Pacientes
  path: '/pacientes',
  component: _layout.default,
  profileFilter: {
    'HOSPITAL': ['medico', 'admin', 'recepcionista', 'assistente_social', 'fisioterapeuta', 'enfermeiro']
  },
  meta: {
    title: 'Pacientes',
    icon: 'downloaded_form',
    affix: true
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pacientes/index'));
      });
    },
    name: 'IndexPacientes',
    meta: {
      title: 'Listar',
      icon: 'downloaded_list',
      affix: true
    }
  }, {
    path: 'relatoriopacientes',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pacientes/relatorio'));
      });
    },
    name: 'RelatorioPacientes',
    meta: {
      title: 'Relatório',
      icon: 'list',
      affix: true
    }
  }, {
    path: ':id',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pacientes/paciente'));
      });
    },
    name: 'PagePacientes',
    meta: {
      title: 'Detalhes do paciente'
    }
  }]
}, // {
//   path: '/usuarios',
//   component: Layout,
//   meta: { title: 'Usuários', icon: 'user', affix: true },
//   profileFilter: {
//     'SECRETARIA': ['admin', 'acs']
//   },
//   children: [
//     {
//       path: '',
//       component: () => import('@/views/usuarios/index'),
//       name: 'GerenciarUsuarios',
//       meta: { title: 'Gerenciar usuários', icon: 'downloaded_list', affix: true }
//     },
//     {
//       path: 'cadastro',
//       component: () => import('@/views/usuarios/cadastro'),
//       name: 'CadastrarUsuarios',
//       meta: { title: 'Novo usuário', icon: 'user_add', affix: true }
//     },
//     {
//       path: ':id',
//       hidden: true,
//       component: () => import('@/views/usuarios/editar'),
//       name: 'EditarUsuario',
//       meta: { title: 'Editar usuário', breadcrumb: false }
//     }
//   ]
// },
// {
//   path: '/chat',
//   component: Layout,
//   meta: { title: 'Usuários', icon: 'downloaded_chat', affix: true },
//   children: [
//     {
//       path: '',
//       component: () => import('@/views/chat/index'),
//       name: 'Chats',
//       meta: { title: 'Chats', icon: 'downloaded_chat', affix: true }
//     }
//   ]
// },
{
  // Avaliações
  path: '/avaliacao/:id',
  component: _layout.default,
  redirect: '/avaliacao/:id',
  children: [{
    path: '/avaliacao/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/avaliacao/index'));
      });
    },
    name: 'avaliacao',
    meta: {
      title: 'Avaliações',
      icon: 'list',
      affix: true
    },
    hidden: true
  }]
}, {
  // Barreiras
  path: '/barreiras',
  component: _layout.default,
  meta: {
    title: 'Barreiras',
    icon: 'table',
    affix: true
  },
  profileFilter: {
    'SECRETARIA': ['admin', 'acs']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/barreiras/index'));
      });
    },
    name: 'ListarBarreiras',
    meta: {
      title: 'Listar',
      icon: 'list',
      affix: true
    }
  }, {
    path: 'relatorio',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/barreiras/relatorio'));
      });
    },
    name: 'RelatorioBarreiras',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }]
}, {
  // Indicadores
  path: '/indicadores',
  component: _layout.default,
  profileFilter: {
    'SECRETARIA': ['admin', 'acs']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/indicadores/index'));
      });
    },
    name: 'ListarIndicadores',
    meta: {
      title: 'Indicadores',
      icon: 'chart',
      affix: true
    }
  }]
}, {
  // Chat
  path: '/suportechat',
  component: _Layoutsuporte.default,
  profileFilter: {
    'SUPORTE': ['admin']
  },
  meta: {
    title: 'Suporte',
    icon: 'downloaded_chat2',
    affix: true
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/suporte-chat/index'));
      });
    },
    name: 'Chat',
    meta: {
      title: 'Chat',
      icon: 'downloaded_chat2',
      affix: true
    }
  }]
}, // {
//   path: '/noticias',
//   component: Layout,
//   meta: { title: 'Noticias', icon: 'documentation', affix: true },
//   profileFilter: {
//     'SECRETARIA': ['admin']
//   },
//   children: [
//     {
//       path: '',
//       component: () => import('@/views/noticias'),
//       name: 'Noticias',
//       meta: { title: 'Notícias', icon: 'documentation', affix: true }
//     }
//   ]
// },
{
  path: '/suporte',
  component: _layout.default,
  profileFilter: {
    'HOSPITAL': ['admin'],
    'SECRETARIA': ['admin', 'acs']
  } // meta: { title: 'Suporte', icon: 'downloaded_chat2', affix: true },
  // children: [
  //   {
  //     path: '',
  //     component: () => import('@/views/suporte/chat'),
  //     name: 'Ajuda',
  //     meta: { title: 'Ajuda', icon: 'downloaded_chat2', affix: true }
  //   }
  // ]

}, {
  path: '/documentation',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/documentation/index'));
      });
    },
    name: 'Documentation' // meta: { title: 'Documentation', icon: 'documentation', affix: true }

  }]
}, {
  path: '/guide',
  component: _layout.default,
  redirect: '/guide/index',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/guide/index'));
      });
    },
    name: 'Guide' // meta: { title: 'Guide', icon: 'guide', noCache: true }

  }]
}, {
  path: '/profile',
  component: _layout.default,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/profile/index'));
      });
    },
    name: 'Profile' // meta: { title: 'Profile', icon: 'user', noCache: true }

  }]
}, // PERFIL EMPRESA
{
  // Portaria
  path: '/barreirasempresa',
  component: _layout.default,
  meta: {
    title: 'Barreiras',
    icon: 'download_agente',
    affix: true
  },
  profileFilter: {
    'EMPRESA': ['admin', 'portaria', 'st_escola'],
    'HOSPITAL': ['medico', 'admin', 'recepcionista', 'assistente_social', 'fisioterapeuta', 'enfermeiro']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/barreiras-empresa/index'));
      });
    },
    name: 'Barreiras',
    meta: {
      title: 'Barreiras',
      icon: 'list',
      affix: true
    }
  }, {
    path: 'relatorioBarreirasEmpresa',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/barreiras-empresa/relatorio'));
      });
    },
    name: 'RelatorioBarreirasEmpresa',
    meta: {
      title: 'Relatório',
      icon: 'documentation',
      affix: true
    }
  }]
}, {
  // Logs
  path: '/loginrelatorio',
  component: _layout.default,
  redirect: '',
  profileFilter: {
    'SECRETARIA': ['admin'],
    'EMPRESA': ['admin']
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/login/relatorioLogin'));
      });
    },
    name: 'RelatorioLogin',
    meta: {
      title: 'Logs de acesso',
      icon: 'documentation',
      affix: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [{
  path: '/permission',
  component: _layout.default,
  redirect: '/permission/page',
  // alwaysShow: true, // will always show the root menu
  name: 'Permission' // meta: {
  //   title: 'Permission',
  //   icon: 'lock',
  //   roles: ['admin', 'editor'] // you can set roles in root nav
  // },
  // children: [
  //   {
  //     path: 'page',
  //     component: () => import('@/views/permission/page'),
  //     name: 'PagePermission',
  //     meta: {
  //       title: 'Page Permission',
  //       roles: ['admin'] // or you can only set roles in sub nav
  //     }
  //   },
  //   {
  //     path: 'directive',
  //     component: () => import('@/views/permission/directive'),
  //     name: 'DirectivePermission',
  //     meta: {
  //       title: 'Directive Permission'
  //       // if do not set roles, means: this page does not require permission
  //     }
  //   },
  //   {
  //     path: 'role',
  //     component: () => import('@/views/permission/role'),
  //     name: 'RolePermission',
  //     meta: {
  //       title: 'Role Permission',
  //       roles: ['admin']
  //     }
  //   }
  // ]

}, {
  path: '/icon',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/icons/index'));
      });
    },
    name: 'Icons' // meta: { title: 'Icons', icon: 'icon', noCache: true }

  }]
},
/** when your routing map is too long, you can split it into small modules **/
// componentsRouter,
// chartsRouter,
// nestedRouter,
// tableRouter,
{
  path: '/example',
  component: _layout.default,
  redirect: '/example/list',
  name: 'Example' // meta: {
  //   title: 'Example',
  //   icon: 'example'
  // },
  // children: [
  //   {
  //     path: 'create',
  //     component: () => import('@/views/example/create'),
  //     name: 'CreateArticle',
  //     // meta: { title: 'Create Article', icon: 'edit' }
  //   },
  //   {
  //     path: 'edit/:id(\\d+)',
  //     component: () => import('@/views/example/edit'),
  //     name: 'EditArticle',
  //     meta: { title: 'Edit Article', noCache: true, activeMenu: '/example/list' },
  //     hidden: true
  //   },
  //   {
  //     path: 'list',
  //     component: () => import('@/views/example/list'),
  //     name: 'ArticleList',
  //     meta: { title: 'Article List', icon: 'list' }
  //   }
  // ]

}, {
  path: '/tab',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/tab/index'));
      });
    },
    name: 'Tab' // meta: { title: 'Tab', icon: 'tab' }

  }]
}, {
  path: '/error',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'ErrorPages' // meta: {
  //   title: 'Error Pages',
  //   icon: '404'
  // },
  // children: [
  //   {
  //     path: '401',
  //     component: () => import('@/views/error-page/401'),
  //     name: 'Page401',
  //     meta: { title: '401', noCache: true }
  //   },
  //   {
  //     path: '404',
  //     component: () => import('@/views/error-page/404'),
  //     name: 'Page404',
  //     meta: { title: '404', noCache: true }
  //   }
  // ]

}, {
  path: '/error-log',
  component: _layout.default,
  children: [{
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/error-log/index'));
      });
    },
    name: 'ErrorLog' // meta: { title: 'Error Log', icon: 'bug' }

  }]
}, {
  path: '/excel',
  component: _layout.default,
  redirect: '/excel/export-excel',
  name: 'Excel' // meta: {
  //   title: 'Excel',
  //   icon: 'excel'
  // },
  // children: [
  //   {
  //     path: 'export-excel',
  //     component: () => import('@/views/excel/export-excel'),
  //     name: 'ExportExcel',
  //     meta: { title: 'Export Excel' }
  //   },
  //   {
  //     path: 'export-selected-excel',
  //     component: () => import('@/views/excel/select-excel'),
  //     name: 'SelectExcel',
  //     meta: { title: 'Export Selected' }
  //   },
  //   {
  //     path: 'export-merge-header',
  //     component: () => import('@/views/excel/merge-header'),
  //     name: 'MergeHeader',
  //     meta: { title: 'Merge Header' }
  //   },
  //   {
  //     path: 'upload-excel',
  //     component: () => import('@/views/excel/upload-excel'),
  //     name: 'UploadExcel',
  //     meta: { title: 'Upload Excel' }
  //   }
  // ]

}, {
  path: '/zip',
  component: _layout.default,
  redirect: '/zip/download',
  // alwaysShow: true,
  name: 'Zip' // meta: { title: 'Zip', icon: 'zip' },
  // children: [
  //   {
  //     path: 'download',
  //     component: () => import('@/views/zip/index'),
  //     name: 'ExportZip',
  //     meta: { title: 'Export Zip' }
  //   }
  // ]

}, {
  path: '/pdf',
  component: _layout.default,
  redirect: '/pdf/index',
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/pdf/index'));
      });
    },
    name: 'PDF' // meta: { title: 'PDF', icon: 'pdf' }

  }]
}, {
  path: '/pdf/download',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pdf/download'));
    });
  },
  hidden: true
}, {
  path: '/pdf/downloadAvaliacoes/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pdf/downloadAvaliacoes'));
    });
  },
  hidden: true
}, {
  path: '/pdf/downloadIndicadores/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pdf/downloadIndicadores'));
    });
  },
  hidden: true
}, {
  path: '/pdf/downloadAvaliacoesRelatorio/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pdf/downloadAvaliacoesRelatorio'));
    });
  },
  hidden: true
}, {
  path: '/pdf/downloadBarreirasRelatorio/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pdf/downloadBarreirasRelatorio'));
    });
  },
  hidden: true
}, {
  path: '/pdf/downloadCasosRelatorio/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pdf/downloadCasosRelatorio'));
    });
  },
  hidden: true
}, {
  path: '/pdf/downloadFuncionarioEmpresa/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pdf/downloadFuncionarioEmpresa'));
    });
  },
  hidden: true
}, {
  path: '/pdf/downloadLogsAcesso/',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/pdf/downloadLogsAcesso'));
    });
  },
  hidden: true
}, {
  path: '/theme',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/theme/index'));
      });
    },
    name: 'Theme' // meta: { title: 'Theme', icon: 'theme' }

  }]
}, {
  path: '/clipboard',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/clipboard/index'));
      });
    },
    name: 'ClipboardDemo' // meta: { title: 'Clipboard', icon: 'clipboard' }

  }]
}, {
  path: 'external-link',
  component: _layout.default,
  children: [{
    path: 'https://github.com/PanJiaChen/vue-element-admin' // meta: { title: 'External Link', icon: 'link' }

  }]
}, // 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;