"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.searchUser = searchUser;
exports.transactionList = transactionList;

var _request = _interopRequireDefault(require("@/utils/request"));

function searchUser(name) {
  return (0, _request.default)({
    url: '/vue-element-admin/search/user',
    method: 'get',
    params: {
      name: name
    }
  });
}

function transactionList(query) {
  return (0, _request.default)({
    url: '/vue-element-admin/transaction/list',
    method: 'get',
    params: query
  });
}