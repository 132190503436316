var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainavaliacoescontainerpage" } },
    [
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          staticStyle: {
            "padding-top": "1rem",
            "border-bottom": "1px solid #EEE",
            "padding-bottom": "0.8rem",
          },
          attrs: { type: "flex" },
        },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", { staticStyle: { "font-size": "16px" } }, [
              _vm._v(_vm._s(_vm.tableData.length) + " avaliações realizadas"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "padding-top": "1rem" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Buscar por" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.filtros.tipobusca,
                            callback: function ($$v) {
                              _vm.$set(_vm.filtros, "tipobusca", $$v)
                            },
                            expression: "filtros.tipobusca",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Nome", value: "nome" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.filtros.tipobusca === "nome"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Nome" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Nome" },
                            model: {
                              value: _vm.filtros.nome,
                              callback: function ($$v) {
                                _vm.$set(_vm.filtros, "nome", $$v)
                              },
                              expression: "filtros.nome",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Mostrar" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.filtros.tipofiltro,
                            callback: function ($$v) {
                              _vm.$set(_vm.filtros, "tipofiltro", $$v)
                            },
                            expression: "filtros.tipofiltro",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Risco", value: "risco" },
                          }),
                          _c("el-option", {
                            attrs: { label: "Tosse", value: "tosse" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.filtros.tipofiltro === "risco"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Nível de risco" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.filtros.nivelrisco,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "nivelrisco", $$v)
                                },
                                expression: "filtros.nivelrisco",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Todos", value: "todos" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Alto", value: "alto" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Baixo", value: "baixo" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filtros.tipofiltro === "tosse"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Tosse seca" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.filtros.tosse,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "tosse", $$v)
                                },
                                expression: "filtros.tosse",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "Todos", value: "todos" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Sim", value: "y" },
                              }),
                              _c("el-option", {
                                attrs: { label: "Não", value: "n" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
            [
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("RESULTADOS")])]
                ),
                _c("div", { staticClass: "content-card" }, [
                  _c("span", [_vm._v(_vm._s(_vm.filteredAvaliacoes.length))]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "padding-top": "1rem" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  ref: "tableData",
                  staticClass: "clickable-rows",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.paginatedAvaliacoes, "empty-text": "-" },
                  on: { "row-click": _vm.rowClicked },
                },
                [
                  _c("el-table-column", {
                    staticClass: "table-column-style",
                    attrs: { label: "RISCO" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.paginatedAvaliacoes[scope.$index]
                              .status_risco === "risco_alto"
                              ? _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-right": "1rem",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/downloaded_redflag.svg"),
                                  },
                                })
                              : _vm._e(),
                            _vm.paginatedAvaliacoes[scope.$index]
                              .status_risco === "risco_baixo"
                              ? _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-right": "1rem",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/downloaded_greenflag.svg"),
                                  },
                                })
                              : _vm._e(),
                            _vm.paginatedAvaliacoes[scope.$index]
                              .status_risco === "risco_medio"
                              ? _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-right": "1rem",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/downloaded_yellowflag.svg"),
                                  },
                                })
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  {
                                    risco_baixo: "Baixo",
                                    risco_medio: "Médio",
                                    risco_alto: "Alto",
                                  }[
                                    _vm.paginatedAvaliacoes[scope.$index]
                                      .status_risco
                                  ]
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    staticClass: "table-column-style",
                    attrs: { prop: "id", label: "ID" },
                  }),
                  _c("el-table-column", {
                    staticClass: "table-column-style",
                    attrs: { prop: "nome", label: "NOME" },
                  }),
                  _c("el-table-column", {
                    staticClass: "table-column-style",
                    attrs: { prop: "perfil_cidadao.celular", label: "CELULAR" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.paginatedAvaliacoes[scope.$index]
                                  .perfil_cidadao &&
                                  _vm.paginatedAvaliacoes[scope.$index]
                                    .perfil_cidadao.celular
                                  ? _vm.paginatedAvaliacoes[
                                      scope.$index
                                    ].perfil_cidadao.celular.replace(
                                      /([1-9]{2})?([0-9]{4,5})?([0-9]{4})/,
                                      "($1) $2-$3"
                                    )
                                  : ""
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    staticClass: "table-column-style",
                    attrs: { prop: "dt", label: "FEITA EM" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(
                                    _vm.paginatedAvaliacoes[scope.$index].dt
                                  )
                                  .format("DD/MM/YYYY")
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.perfil.tipo_instituicao == "EMPRESA"
                    ? _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "setor", label: "SETOR" },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    staticClass: "table-column-style",
                    attrs: { label: "AÇÕES" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                on: {
                                  mousedown: function ($event) {
                                    return _vm.newChat(
                                      $event,
                                      _vm.paginatedAvaliacoes[scope.$index]
                                        .perfil_cidadao,
                                      _vm.paginatedAvaliacoes[scope.$index].nome
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      disabled:
                                        !_vm.paginatedAvaliacoes[scope.$index]
                                          .perfil_cidadao,
                                    },
                                  },
                                  [_vm._v("Abrir chat")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "padding-top": "1rem" } },
        [
          _vm.filteredAvaliacoes.length > 0
            ? _c("el-pagination", {
                attrs: {
                  background: "",
                  "page-size": _vm.avaliacoesPerPage,
                  layout: "prev, pager, next",
                  total: _vm.filteredAvaliacoes.length,
                },
                on: { "current-change": _vm.changePage },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }