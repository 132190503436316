"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      fullscreenLoading: true,
      title: '',
      content: ''
    };
  },
  created: function created() {
    this.title = this.$route.query.title;
    this.content = this.$route.query.content;
  },
  mounted: function mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      setTimeout(function () {
        _this.fullscreenLoading = false;

        _this.$nextTick(function () {
          window.print();
        });
      }, 3000);
    }
  }
};
exports.default = _default;