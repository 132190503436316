var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "pageeditaragentemaincontainer" } },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [
              _vm._v(
                "Editar agente: " +
                  _vm._s(_vm.agente ? _vm.agente.nome_completo : "")
              ),
            ]),
          ]),
          _c(
            "el-main",
            [
              _vm.agente
                ? _c("formagente", {
                    attrs: { agente: _vm.agente, edit: true },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }