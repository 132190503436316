import { render, staticRenderFns } from "./formhospital.vue?vue&type=template&id=085b1f2b&scoped=true&lang=pug&"
import script from "./formhospital.vue?vue&type=script&lang=js&"
export * from "./formhospital.vue?vue&type=script&lang=js&"
import style0 from "./formhospital.vue?vue&type=style&index=0&id=085b1f2b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085b1f2b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Diogo\\Documents\\projetos\\free\\saic-dash\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('085b1f2b')) {
      api.createRecord('085b1f2b', component.options)
    } else {
      api.reload('085b1f2b', component.options)
    }
    module.hot.accept("./formhospital.vue?vue&type=template&id=085b1f2b&scoped=true&lang=pug&", function () {
      api.rerender('085b1f2b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/hospitais/formhospital.vue"
export default component.exports