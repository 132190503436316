"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.db = exports.currentUser = exports.auth = void 0;
Object.defineProperty(exports, "firebase", {
  enumerable: true,
  get: function get() {
    return _firebase.default;
  }
});
exports.usersCollection = exports.storage = void 0;

var _firebase = _interopRequireDefault(require("firebase"));

require("firebase/firestore");

var DevConfigOptions = {
  apiKey: 'AIzaSyBZbuSUAlWWiaB0ObNdGHyuPmkfj1Lrhk8',
  authDomain: 'saicovid19.firebaseapp.com',
  databaseURL: 'https://saicovid19.firebaseio.com',
  projectId: 'saicovid19',
  storageBucket: 'saicovid19.appspot.com',
  messagingSenderId: '1064538129672',
  appId: '1:1064538129672:web:064b0ba58e9d04e14e58bd'
}; // const DevConfigOptions = {
//   apiKey: 'AIzaSyDy0z0LTI59pi4IkX-G8LOOa_kBCulTWXU',
//   authDomain: 'teste-saic-chat.firebaseapp.com',
//   databaseURL: 'https://teste-saic-chat.firebaseio.com',
//   projectId: 'teste-saic-chat',
//   storageBucket: 'teste-saic-chat.appspot.com',
//   messagingSenderId: '611725465207',
//   appId: '1:611725465207:web:7853236d922f4cae249872'
// }

var ProdConfigOptions = {
  apiKey: "AIzaSyA8Ot23sGTcx5PGIPLZPRTMFqZrHfoyqYc",
  authDomain: "saicovid19-prod.firebaseapp.com",
  databaseURL: "https://saicovid19-prod.firebaseio.com",
  projectId: "saicovid19-prod",
  storageBucket: "saicovid19-prod.appspot.com",
  messagingSenderId: "165160916821",
  appId: "1:165160916821:web:dfb1728c08706aac90c1dc"
};
var configOptions = DevConfigOptions;

switch (process.env.NODE_ENV) {
  case 'development':
    configOptions = DevConfigOptions;
    break;

  case 'production':
    configOptions = ProdConfigOptions;
    break;

  case 'staging':
    configOptions = DevConfigOptions;
    break;

  default:
    configOptions = DevConfigOptions;
    break;
} // const configOptions = process.env.NODE_ENV === 'development' ? DevConfigOptions : ProdConfigOptions


console.log();

_firebase.default.initializeApp(configOptions);

var db = _firebase.default.firestore();

exports.db = db;

var auth = _firebase.default.auth();

exports.auth = auth;
var currentUser = auth.currentUser;
exports.currentUser = currentUser;
var usersCollection = db.collection('usuarios');
exports.usersCollection = usersCollection;

var storage = _firebase.default.storage();

exports.storage = storage;