var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "Gerando o PDF..." },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "article__heading" }, [
        _c("div", { staticClass: "article__heading__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("div", { staticStyle: { color: "#ccc" } }),
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xs: 22, sm: 22, lg: 22 } },
            [
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("RESULTADOS")])]
                ),
                _c("div", { staticClass: "content-card" }, [
                  _c("span", [_vm._v(_vm._s(_vm.dados.length))]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.tp_relatorio == "indicadores" &&
                _vm.key == 1 &&
                (_vm.numIndicador == 1 || _vm.numIndicador == 4),
              expression:
                "(tp_relatorio == 'indicadores') && (key == 1) && ((numIndicador == 1) || (numIndicador == 4))",
            },
          ],
          staticClass: "row-bg charts-style",
          attrs: { type: "flex", gutter: 32, id: "exportContent" },
        },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.numIndicador != 5 &&
                    _vm.numIndicador != 3 &&
                    _vm.numIndicador != 2,
                  expression:
                    "(numIndicador != 5) && (numIndicador != 3) && (numIndicador != 2)",
                },
              ],
              attrs: { xs: 24, sm: 24, lg: 14 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "chart-wrapper",
                  staticStyle: { height: "500px" },
                },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Metas (%)"),
                  ]),
                  _c("pie-chart", {
                    staticStyle: { height: "400px" },
                    attrs: { data: _vm.pieDataMetas, nameGraph: "Metas" },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tp_relatorio == "indicadores" &&
                    _vm.key == 1 &&
                    _vm.numIndicador == 1,
                  expression:
                    "(tp_relatorio == 'indicadores') && (key == 1) && (numIndicador == 1)",
                },
              ],
              attrs: { xs: 24, sm: 24, lg: 14 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "chart-wrapper",
                  staticStyle: { height: "500px" },
                },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("1ª atd. < 20 semanas (%)"),
                  ]),
                  _c("pie-chart", {
                    staticStyle: { height: "400px" },
                    attrs: {
                      data: _vm.dadosGraficoAtendimento20Semana,
                      nameGraph: "1º atd. < 20 semanas",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numIndicador == 4,
                  expression: "((numIndicador == 4))",
                },
              ],
              attrs: { xs: 24, sm: 24, lg: 14 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "chart-wrapper",
                  staticStyle: { height: "500px" },
                },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Exame Citopatológico (%)"),
                  ]),
                  _c("pie-chart", {
                    staticStyle: { height: "400px" },
                    attrs: {
                      data: _vm.dadosGraficoCitopatologico,
                      nameGraph: "Exame Citopatológico",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.tp_relatorio == "indicadores" &&
                _vm.key == 1 &&
                _vm.numIndicador == 5,
              expression:
                "(tp_relatorio == 'indicadores') && (key == 1) && (numIndicador == 5)",
            },
          ],
          staticClass: "row-bg charts-style",
          attrs: { type: "flex", gutter: 32, id: "exportContent" },
        },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numIndicador == 5,
                  expression: "(numIndicador == 5)",
                },
              ],
              attrs: { xs: 24, sm: 24, lg: 24 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "chart-wrapper",
                  staticStyle: { height: "350px" },
                },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Metas (%)"),
                  ]),
                  _c("pie-chart", {
                    staticStyle: { height: "350px" },
                    attrs: { data: _vm.pieDataMetas, nameGraph: "Metas" },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.tp_relatorio == "indicadores" &&
                _vm.key == 1 &&
                _vm.numIndicador == 5,
              expression:
                "(tp_relatorio == 'indicadores') && (key == 1) && (numIndicador == 5)",
            },
          ],
          staticClass: "row-bg charts-style",
          attrs: { type: "flex", gutter: 32, id: "exportContent" },
        },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numIndicador == 5,
                  expression: "((numIndicador == 5))",
                },
              ],
              attrs: { xs: 24, sm: 11, lg: 11 },
            },
            [
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Vacina Polio (%)"),
                  ]),
                  _c("pie-chart", {
                    attrs: {
                      data: _vm.dadosGraficoPolio,
                      nameGraph: "Vacina Polio",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numIndicador == 5,
                  expression: "((numIndicador == 5))",
                },
              ],
              attrs: { xs: 24, sm: 11, lg: 11 },
            },
            [
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Vacina Penta (%)"),
                  ]),
                  _c("pie-chart", {
                    attrs: {
                      data: _vm.dadosGraficoPenta,
                      nameGraph: "Vacina Penta",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.tp_relatorio == "indicadores" &&
                _vm.key == 1 &&
                (_vm.numIndicador == 2 ||
                  _vm.numIndicador == 3 ||
                  _vm.numIndicador == 6 ||
                  _vm.numIndicador == 7),
              expression:
                "(tp_relatorio == 'indicadores') && (key == 1) && ((numIndicador == 2) || (numIndicador == 3) || (numIndicador == 6) || (numIndicador == 7))",
            },
          ],
          staticClass: "row-bg charts-style",
          attrs: { type: "flex", gutter: 32, id: "exportContent" },
        },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.numIndicador == 2 ||
                    _vm.numIndicador == 3 ||
                    _vm.numIndicador == 6 ||
                    _vm.numIndicador == 7,
                  expression:
                    " (numIndicador == 2) || (numIndicador == 3) || (numIndicador == 6) || (numIndicador == 7)",
                },
              ],
              attrs: { xs: 24, sm: 24, lg: 24 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "chart-wrapper",
                  staticStyle: { height: "350px" },
                },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Metas (%)"),
                  ]),
                  _c("pie-chart", {
                    staticStyle: { height: "350px" },
                    attrs: { data: _vm.pieDataMetas, nameGraph: "Metas" },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.tp_relatorio == "indicadores" &&
                _vm.key == 1 &&
                _vm.numIndicador == 2,
              expression:
                "(tp_relatorio == 'indicadores') && (key == 1) && (numIndicador == 2)",
            },
          ],
          staticClass: "row-bg charts-style",
          attrs: { type: "flex", gutter: 32, id: "exportContent" },
        },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numIndicador == 2,
                  expression: "((numIndicador == 2))",
                },
              ],
              attrs: { xs: 24, sm: 11, lg: 14 },
            },
            [
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Sorologia HIV (%)"),
                  ]),
                  _c("pie-chart", {
                    attrs: {
                      data: _vm.dadosGraficoHIV,
                      nameGraph: "Sorologia HIV",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.numIndicador == 2,
                  expression: "((numIndicador == 2))",
                },
              ],
              attrs: { xs: 24, sm: 11, lg: 14 },
            },
            [
              _c(
                "div",
                { staticClass: "chart-wrapper" },
                [
                  _c("h3", { staticStyle: { "text-align": "left" } }, [
                    _vm._v("Sorologia Sífilis (%)"),
                  ]),
                  _c("pie-chart", {
                    attrs: {
                      data: _vm.dadosGraficoSifilis,
                      nameGraph: "Sorologia Sífilis",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.tp_relatorio == "indicadores"
        ? _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex", gutter: 32 } },
            [
              _c("div", { staticClass: "clearfix indication-title-style" }, [
                _c("span", { staticStyle: { "font-weigth": "bold" } }, [
                  _vm._v(
                    "Indicador " +
                      _vm._s(_vm.numIndicador) +
                      ": " +
                      _vm._s(_vm.titulo_indicador)
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.tp_relatorio == "indicadores"
        ? _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex", gutter: 32 } },
            [
              _c("div", { staticClass: "result-indicators-style" }, [
                _c("span", { staticClass: "result-indicators-style-title" }, [
                  _vm._v("Acompanhe a evolução dos indicadores"),
                ]),
                _c("br"),
                _c("br"),
                _c("img", {
                  staticStyle: { width: "20px", "margin-right": "0.5rem" },
                  attrs: {
                    src: require("@/assets/images/downloaded_redflag.svg"),
                  },
                }),
                _c(
                  "span",
                  { staticStyle: { "margin-right": "1rem", color: "#d81e06" } },
                  [_vm._v("< 24.0%")]
                ),
                _c("img", {
                  staticStyle: { width: "20px", "margin-right": "0.5rem" },
                  attrs: {
                    src: require("@/assets/images/downloaded_yellowflag.svg"),
                  },
                }),
                _c(
                  "span",
                  { staticStyle: { "margin-right": "1rem", color: "#f0ad4e" } },
                  [_vm._v("≥ 24.0% e < 42.0%")]
                ),
                _c("img", {
                  staticStyle: { width: "20px", "margin-right": "0.5rem" },
                  attrs: {
                    src: require("@/assets/images/downloaded_greenflag.svg"),
                  },
                }),
                _c(
                  "span",
                  { staticStyle: { "margin-right": "1rem", color: "#00a65a" } },
                  [_vm._v("≥ 42.0% e < 60%")]
                ),
                _c("img", {
                  staticStyle: { width: "20px", "margin-right": "0.5rem" },
                  attrs: {
                    src: require("@/assets/images/downloaded_blueflag.svg"),
                  },
                }),
                _c(
                  "span",
                  { staticStyle: { "margin-right": "1rem", color: "#3c8dbc" } },
                  [_vm._v("≥ 60.0%")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("br"),
      _c("br"),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", gutter: 32 } },
        [
          _c(
            "div",
            {
              staticClass: "result-table-style",
              staticStyle: { width: "100%" },
            },
            [
              _vm.tp_relatorio == "indicadores" &&
              _vm.key == 1 &&
              _vm.numIndicador == 1
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "nu_cnes_list",
                          label: "CNES ESTABELECIMENTO",
                          width: "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_unidade_saude_list",
                          label: "EQUIPE",
                          width: "80",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_profissional_list",
                          label: "PROFISSIONAL",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "no_cidadao", label: "CIDADAO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cns", label: "CARTAO SUS" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "prim_atd_menos_20_semanas",
                          label: "1º ATD. < 20 SEMANAS",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "total_atd",
                          label: "TOTAL DE CONSULTAS",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "META", width: "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index].meta.toFixed(2)
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2201719406
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "STATUS", width: "70" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].meta < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 23 &&
                                  _vm.dados[scope.$index].meta < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 41 &&
                                  _vm.dados[scope.$index].meta < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          213723818
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "detalhes", label: "DETALHES" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  _vm.dados[scope.$index].detalhes,
                                  function (det, key) {
                                    return _c("span", [
                                      _vm._v(_vm._s(det) + " "),
                                      _c("br"),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2213813388
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tp_relatorio == "indicadores" &&
              _vm.key == 1 &&
              _vm.numIndicador == 2
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "nu_cnes_list",
                          label: "CNES ESTABELECIMENTO",
                          width: "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_unidade_saude_list",
                          label: "EQUIPE",
                          width: "80",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_profissional_list",
                          label: "PROFISSIONAL",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "no_cidadao", label: "CIDADAO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cpf", label: "CPF" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cns", label: "CARTAO SUS" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sorologia_sifilis",
                          label: "SIFILIS",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sorologia_hiv",
                          label: "HIV",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "META", width: "60" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index].meta.toFixed(2)
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2201719406
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "STATUS", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].meta < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 23 &&
                                  _vm.dados[scope.$index].meta < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 41 &&
                                  _vm.dados[scope.$index].meta < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          213723818
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "detalhes", label: "DETALHES" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  _vm.dados[scope.$index].detalhes,
                                  function (det, key) {
                                    return _c("span", [
                                      _vm._v(_vm._s(det) + " "),
                                      _c("br"),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2213813388
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tp_relatorio == "indicadores" &&
              _vm.key == 1 &&
              _vm.numIndicador == 3
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "nu_cnes_list",
                          label: "CNES ESTABELECIMENTO",
                          width: "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_unidade_saude_list",
                          label: "EQUIPE",
                          width: "80",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_profissional_list",
                          label: "PROFISSIONAL",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "no_cidadao", label: "CIDADAO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cpf", label: "CPF" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cns", label: "CARTAO SUS" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "total_atd",
                          label: "TOTAL DE CONSULTAS",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "META", width: "55" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index].meta.toFixed(2)
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2201719406
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "STATUS", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].meta < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 23 &&
                                  _vm.dados[scope.$index].meta < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 41 &&
                                  _vm.dados[scope.$index].meta < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          213723818
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "detalhes", label: "DETALHES" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  _vm.dados[scope.$index].detalhes,
                                  function (det, key) {
                                    return _c("span", [
                                      _vm._v(_vm._s(det) + " "),
                                      _c("br"),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2213813388
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tp_relatorio == "indicadores" &&
              _vm.key == 1 &&
              _vm.numIndicador == 4
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "nu_cnes_list",
                          label: "CNES ESTABELECIMENTO",
                          width: "115",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_unidade_saude_list",
                          label: "EQUIPE",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_profissional_list",
                          label: "PROFISSIONAL",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "no_cidadao", label: "CIDADAO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cpf", label: "CPF" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cns", label: "CARTAO SUS" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "idade", label: "IDADE", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "citopatologico",
                          label: "CITOPATOLÓGICO",
                          width: "90",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "STATUS", width: "60" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].meta < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 23 &&
                                  _vm.dados[scope.$index].meta < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 41 &&
                                  _vm.dados[scope.$index].meta < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          213723818
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "detalhes", label: "DETALHES" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  _vm.dados[scope.$index].detalhes,
                                  function (det, key) {
                                    return _c("span", [
                                      _vm._v(_vm._s(det) + " "),
                                      _c("br"),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2213813388
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tp_relatorio == "indicadores" &&
              _vm.key == 1 &&
              _vm.numIndicador == 5
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "nu_cnes_list",
                          label: "CNES ESTABELECIMENTO",
                          width: "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_unidade_saude_list",
                          label: "EQUIPE",
                          width: "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_profissional_list",
                          label: "PROFISSIONAL",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "no_cidadao", label: "CIDADAO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cpf", label: "CPF" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cns", label: "CARTAO SUS" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "vacina_polio",
                          label: "POLIO",
                          width: "45",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "vacina_penta",
                          label: "PENTA",
                          width: "45",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "META", width: "55" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index].meta.toFixed(2)
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2201719406
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "STATUS", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].meta < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 23 &&
                                  _vm.dados[scope.$index].meta < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 41 &&
                                  _vm.dados[scope.$index].meta < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          213723818
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "detalhes", label: "DETALHES" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  _vm.dados[scope.$index].detalhes,
                                  function (det, key) {
                                    return _c("span", [
                                      _vm._v(_vm._s(det) + " "),
                                      _c("br"),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2213813388
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tp_relatorio == "indicadores" &&
              _vm.key == 1 &&
              _vm.numIndicador == 6
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "nu_cnes_list",
                          label: "CNES ESTABELECIMENTO",
                          width: "105",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_unidade_saude_list",
                          label: "EQUIPE",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_profissional_list",
                          label: "PROFISSIONAL",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_cidadao",
                          label: "CIDADAO",
                          width: "55",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cpf", label: "CPF", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cns", label: "CARTAO SUS" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dt_inclusao",
                          label: "DT INC HIPER",
                          width: "60",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dt_pa_penultimo_semestre",
                          label: "DT P.A. PENÚLT SEMEST",
                          width: "60",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "total_pa_penultimo_semestre",
                          label: "TOTAL P.A. PENÚLT SEMEST",
                          width: "60",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dt_pa_ultimo_semestre",
                          label: "DT P.A. ÚLT SEMEST",
                          width: "60",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "total_pa_ultimo_semestre",
                          label: "TOTAL P.A. ÚLT SEMEST",
                          width: "60",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "STATUS", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].meta < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 23 &&
                                  _vm.dados[scope.$index].meta < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 41 &&
                                  _vm.dados[scope.$index].meta < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          213723818
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "detalhes", label: "DETALHES" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  _vm.dados[scope.$index].detalhes,
                                  function (det, key) {
                                    return _c("span", [
                                      _vm._v(_vm._s(det) + " "),
                                      _c("br"),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2213813388
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tp_relatorio == "indicadores" &&
              _vm.key == 1 &&
              _vm.numIndicador == 7
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "nu_cnes_list",
                          label: "CNES ESTABELECIMENTO",
                          width: "110",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_unidade_saude_list",
                          label: "EQUIPE",
                          width: "90",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_profissional_list",
                          label: "PROFISSIONAL",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no_cidadao",
                          label: "CIDADAO",
                          width: "60",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nu_cpf", label: "CPF", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "nu_cns",
                          label: "CARTAO SUS",
                          width: "70",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "idade", label: "IDADE", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "total_atd",
                          label: "TOTAL",
                          width: "50",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "dt_atd",
                          label: "DT ATENDIMENTO",
                          width: "85",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "total_hemoglobina_glicada",
                          label: "TOTAL HEMOGLOBINA GLICADA",
                          width: "70",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "meta", label: "STATUS", width: "50" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].meta < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 23 &&
                                  _vm.dados[scope.$index].meta < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 41 &&
                                  _vm.dados[scope.$index].meta < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].meta > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          213723818
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "detalhes", label: "DETALHES" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  _vm.dados[scope.$index].detalhes,
                                  function (det, key) {
                                    return _c("span", [
                                      _vm._v(_vm._s(det) + " "),
                                      _c("br"),
                                    ])
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2213813388
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tp_relatorio == "indicadores" && _vm.key == 2
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "estado", label: "ESTADO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "nome", label: "UBS" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "pre_natal_consultas",
                          label: "PRÉ-NATAL(6 CONSUL)",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].pre_natal_consultas <
                                  24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].pre_natal_consultas >
                                    23 &&
                                  _vm.dados[scope.$index].pre_natal_consultas <
                                    42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].pre_natal_consultas >
                                    41 &&
                                  _vm.dados[scope.$index].pre_natal_consultas <
                                    60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].pre_natal_consultas >
                                  59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index]
                                          .pre_natal_consultas
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3532603714
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "pre_natal_sifilis_hiv",
                          label: "PRÉ-NATAL(SÍF E HIV)",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index]
                                    .pre_natal_sifilis_hiv < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .pre_natal_sifilis_hiv > 23 &&
                                  _vm.dados[scope.$index]
                                    .pre_natal_sifilis_hiv < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .pre_natal_sifilis_hiv > 41 &&
                                  _vm.dados[scope.$index]
                                    .pre_natal_sifilis_hiv < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .pre_natal_sifilis_hiv > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index]
                                          .pre_natal_sifilis_hiv
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          215425095
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "gestantes_saude_bucal",
                          label: "GESTANTES SAÚDE BUCAL",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index]
                                    .gestantes_saude_bucal < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .gestantes_saude_bucal > 23 &&
                                  _vm.dados[scope.$index]
                                    .gestantes_saude_bucal < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .gestantes_saude_bucal > 41 &&
                                  _vm.dados[scope.$index]
                                    .gestantes_saude_bucal < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .gestantes_saude_bucal > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index]
                                          .gestantes_saude_bucal
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3004563338
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cobertura_citopatologica",
                          label: "COBER CITOPATOLÓGICO",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index]
                                    .cobertura_citopatologica < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .cobertura_citopatologica > 23 &&
                                  _vm.dados[scope.$index]
                                    .cobertura_citopatologica < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .cobertura_citopatologica > 41 &&
                                  _vm.dados[scope.$index]
                                    .cobertura_citopatologica < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .cobertura_citopatologica > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index]
                                          .cobertura_citopatologica
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2370430269
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cobertura_polio_penta",
                          label: "COBER POLIO E PENTA",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index]
                                    .cobertura_polio_penta < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .cobertura_polio_penta > 23 &&
                                  _vm.dados[scope.$index]
                                    .cobertura_polio_penta < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .cobertura_polio_penta > 41 &&
                                  _vm.dados[scope.$index]
                                    .cobertura_polio_penta < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index]
                                    .cobertura_polio_penta > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index]
                                          .cobertura_polio_penta
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2253073773
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "hipertensao",
                          label: "HIPERT(PA AFERIDA)",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].hipertensao < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].hipertensao > 23 &&
                                  _vm.dados[scope.$index].hipertensao < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].hipertensao > 41 &&
                                  _vm.dados[scope.$index].hipertensao < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].hipertensao > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dados[scope.$index].hipertensao
                                      ) + " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3113495545
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "diabetes",
                          label: "DIABETES(HEMOG GLIC)",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].diabetes < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].diabetes > 23 &&
                                  _vm.dados[scope.$index].diabetes < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].diabetes > 41 &&
                                  _vm.dados[scope.$index].diabetes < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].diabetes > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dados[scope.$index].diabetes) +
                                        " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2961742484
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tp_relatorio == "indicadores" && _vm.key == 3
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "estado", label: "ESTADO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "q1_2020", label: "2020 Q1" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].q1_2020 < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2020 > 23 &&
                                  _vm.dados[scope.$index].q1_2020 < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2020 > 41 &&
                                  _vm.dados[scope.$index].q1_2020 < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2020 > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dados[scope.$index].q1_2020) +
                                        " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3719976034
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "q3_2019", label: "2019 Q3" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].q3_2019 < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q3_2019 > 23 &&
                                  _vm.dados[scope.$index].q3_2019 < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q3_2019 > 41 &&
                                  _vm.dados[scope.$index].q3_2019 < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q3_2019 > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dados[scope.$index].q3_2019) +
                                        " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3374392330
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "q2_2019", label: "2019 Q2" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].q2_2019 < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q2_2019 > 23 &&
                                  _vm.dados[scope.$index].q2_2019 < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q2_2019 > 41 &&
                                  _vm.dados[scope.$index].q2_2019 < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q2_2019 > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dados[scope.$index].q2_2019) +
                                        " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          301772171
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "q1_2019", label: "2019 Q1" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].q1_2019 < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2019 > 23 &&
                                  _vm.dados[scope.$index].q1_2019 < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2019 > 41 &&
                                  _vm.dados[scope.$index].q1_2019 < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2019 > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dados[scope.$index].q1_2019) +
                                        " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1920342792
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "q3_2018", label: "2018 Q3" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].q3_2018 < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q3_2018 > 23 &&
                                  _vm.dados[scope.$index].q3_2018 < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q3_2018 > 41 &&
                                  _vm.dados[scope.$index].q3_2018 < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q3_2018 > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dados[scope.$index].q3_2018) +
                                        " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1970081323
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "q2_2018", label: "2018 Q3" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].q2_2018 < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q2_2018 > 23 &&
                                  _vm.dados[scope.$index].q2_2018 < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q2_2018 > 41 &&
                                  _vm.dados[scope.$index].q2_2018 < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q2_2018 > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dados[scope.$index].q2_2018) +
                                        " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3264659306
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "q1_2018", label: "2018 Q3" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].q1_2018 < 24
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2018 > 23 &&
                                  _vm.dados[scope.$index].q1_2018 < 42
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2018 > 41 &&
                                  _vm.dados[scope.$index].q1_2018 < 60
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].q1_2018 > 59
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_blueflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.dados[scope.$index].q1_2018) +
                                        " %"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2016691497
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "printlogo" } }, [
      _c("div", { staticClass: "header-logo-saic" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/SAICovid-19.png") },
        }),
      ]),
      _c("div", { staticClass: "header-text" }, [
        _c("span", [_vm._v("INFATEC - SOLUÇÕES TECNOLÓGICAS")]),
        _c("br"),
        _c("span", [_vm._v("TERESINA - PI")]),
        _c("br"),
        _c("span", [_vm._v("SISTEMA DE ACOMPANHAMENTO INTEGRAL AO COVID-19")]),
      ]),
      _c("div", { staticClass: "header-logo-infatec" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/infatec-logo.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }