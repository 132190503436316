"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _vuex = require("vuex");

var _admin = _interopRequireDefault(require("./admin"));

var _editor = _interopRequireDefault(require("./editor"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: 'Dashboard',
  components: {
    adminDashboard: _admin.default,
    editorDashboard: _editor.default
  },
  data: function data() {
    return {
      currentRole: 'adminDashboard',
      'checkedProfile': false
    };
  },
  methods: _objectSpread({}, (0, _vuex.mapActions)({
    loadPerfil: "user/loadPerfil"
  })),
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['roles', 'perfil'])),
  created: function created() {
    var _this = this;

    var self = this;
    this.loadPerfil().then(function () {
      if (!_this.perfil.role === 'admin') {
        _this.currentRole = 'editorDashboard';
      }

      if (_this.perfil.tipo_instituicao === 'HOSPITAL') {
        self.$router.push('/pacientes');
        return;
      } else if (_this.perfil.tipo_instituicao === 'SUPORTE') {
        self.$router.push('/suportechat');
        return;
      }

      _this.checkedProfile = true;
    });
  }
};
exports.default = _default;