"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _MixChart = _interopRequireDefault(require("@/components/Charts/MixChart"));

//
//
//
//
//
//
var _default = {
  name: 'MixChart',
  components: {
    Chart: _MixChart.default
  }
};
exports.default = _default;