"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Dropzone = _interopRequireDefault(require("@/components/Dropzone"));

//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DropzoneDemo',
  components: {
    Dropzone: _Dropzone.default
  },
  methods: {
    dropzoneS: function dropzoneS(file) {
      console.log(file);
      this.$message({
        message: 'Upload success',
        type: 'success'
      });
    },
    dropzoneR: function dropzoneR(file) {
      console.log(file);
      this.$message({
        message: 'Delete success',
        type: 'success'
      });
    }
  }
};
exports.default = _default;