"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _FixedThead = _interopRequireDefault(require("./components/FixedThead"));

var _UnfixedThead = _interopRequireDefault(require("./components/UnfixedThead"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DynamicTable',
  components: {
    FixedThead: _FixedThead.default,
    UnfixedThead: _UnfixedThead.default
  }
};
exports.default = _default;