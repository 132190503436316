var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.modalVisibleNotice, width: "60%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.modalVisibleNotice = $event
            },
          },
        },
        [
          _c("h1", [_vm._v(_vm._s(_vm.noticiaSelecionada.titulo))]),
          _c("el-divider"),
          _c("i", { staticClass: "el-icon-date" }, [_vm._v(" ")]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm
                  .moment(_vm.noticiaSelecionada.criado_em)
                  .format("DD/MM/YYYY")
              )
            ),
          ]),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" }, attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "80%",
                      height: "80%",
                      display: "-webkit-flex",
                      "margin-left": "auto",
                      "margin-right": "auto",
                      "padding-top": "1rem",
                    },
                    attrs: { src: _vm.noticiaSelecionada.imagem_de_capa },
                  }),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.noticiaSelecionada.conteudo),
                  },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.modalVisible, width: "60%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.modalVisible = $event
            },
          },
        },
        _vm._l(_vm.listaNoticias, function (item) {
          return _c("el-card", { staticStyle: { "margin-bottom": "1rem" } }, [
            _c(
              "a",
              {
                attrs: { target: "_self" },
                on: {
                  click: function ($event) {
                    return _vm.abrirPostagem(item)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-weight": "bolder",
                      "font-size": "14px",
                    },
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v(_vm._s(item.titulo))])]
                ),
                _c("div", { staticClass: "card-content" }, [
                  _c("p", { domProps: { innerHTML: _vm._s(item.chamada) } }),
                ]),
              ]
            ),
          ])
        }),
        1
      ),
      _c(
        "el-container",
        [
          _c("el-header", [
            _vm.listaNoticias.length > 0
              ? _c("h1", { staticClass: "dash-titles" }, [_vm._v("Notícias")])
              : _vm._e(),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-row",
                [
                  _vm.listaNoticias.length > 0
                    ? _c(
                        "el-carousel",
                        { attrs: { interval: 5000, arrow: "always" } },
                        [
                          _vm.listaNoticias.length > 0
                            ? _c("el-carousel-item", [
                                _c(
                                  "a",
                                  {
                                    attrs: { target: "_self" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.abrirPostagem(
                                          _vm.listaNoticias[
                                            _vm.listaNoticias.length - 1
                                          ]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "60%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 1
                                              ].titulo
                                            )
                                          ),
                                        ]),
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 1
                                              ].chamada
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "40%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            "border-radius": "1rem",
                                            width: "80%",
                                            height: "80%",
                                            display: "-webkit-flex",
                                            "margin-left": "auto",
                                            "margin-right": "auto",
                                            "padding-top": "1rem",
                                          },
                                          attrs: {
                                            src: _vm.listaNoticias[
                                              _vm.listaNoticias.length - 1
                                            ].imagem_de_capa,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.listaNoticias.length > 1
                            ? _c("el-carousel-item", [
                                _c(
                                  "a",
                                  {
                                    attrs: { target: "_self" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.abrirPostagem(
                                          _vm.listaNoticias[
                                            _vm.listaNoticias.length - 2
                                          ]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "60%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 2
                                              ].titulo
                                            )
                                          ),
                                        ]),
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 2
                                              ].chamada
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "40%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            "border-radius": "1rem",
                                            width: "80%",
                                            height: "80%",
                                            display: "-webkit-flex",
                                            "margin-left": "auto",
                                            "margin-right": "auto",
                                            "padding-top": "1rem",
                                          },
                                          attrs: {
                                            src: _vm.listaNoticias[
                                              _vm.listaNoticias.length - 2
                                            ].imagem_de_capa,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.listaNoticias.length > 2
                            ? _c("el-carousel-item", [
                                _c(
                                  "a",
                                  {
                                    attrs: { target: "_self" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.abrirPostagem(
                                          _vm.listaNoticias[
                                            _vm.listaNoticias.length - 3
                                          ]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "60%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 3
                                              ].titulo
                                            )
                                          ),
                                        ]),
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 3
                                              ].chamada
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "40%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            "border-radius": "1rem",
                                            width: "80%",
                                            height: "80%",
                                            display: "-webkit-flex",
                                            "margin-left": "auto",
                                            "margin-right": "auto",
                                            "padding-top": "1rem",
                                          },
                                          attrs: {
                                            src: _vm.listaNoticias[
                                              _vm.listaNoticias.length - 3
                                            ].imagem_de_capa,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.listaNoticias.length > 3
                            ? _c("el-carousel-item", [
                                _c(
                                  "a",
                                  {
                                    attrs: { target: "_self" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.abrirPostagem(
                                          _vm.listaNoticias[
                                            _vm.listaNoticias.length - 4
                                          ]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "60%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 4
                                              ].titulo
                                            )
                                          ),
                                        ]),
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 4
                                              ].chamada
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "40%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            "border-radius": "1rem",
                                            width: "80%",
                                            height: "80%",
                                            display: "-webkit-flex",
                                            "margin-left": "auto",
                                            "margin-right": "auto",
                                            "padding-top": "1rem",
                                          },
                                          attrs: {
                                            src: _vm.listaNoticias[
                                              _vm.listaNoticias.length - 4
                                            ].imagem_de_capa,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.listaNoticias.length > 4
                            ? _c("el-carousel-item", [
                                _c(
                                  "a",
                                  {
                                    attrs: { target: "_self" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.abrirPostagem(
                                          _vm.listaNoticias[
                                            _vm.listaNoticias.length - 5
                                          ]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "60%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 5
                                              ].titulo
                                            )
                                          ),
                                        ]),
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.listaNoticias[
                                                _vm.listaNoticias.length - 5
                                              ].chamada
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "relative",
                                          float: "left",
                                          width: "40%",
                                          height: "100%",
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            "border-radius": "1rem",
                                            width: "80%",
                                            height: "80%",
                                            display: "-webkit-flex",
                                            "margin-left": "auto",
                                            "margin-right": "auto",
                                            "padding-top": "1rem",
                                          },
                                          attrs: {
                                            src: _vm.listaNoticias[
                                              _vm.listaNoticias.length - 5
                                            ].imagem_de_capa,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _vm.listaNoticias.length > 0
                    ? _c(
                        "el-col",
                        { attrs: { xs: 8, sm: 8, lg: 8 } },
                        [
                          _c(
                            "el-card",
                            { staticStyle: { "margin-right": "0.5rem" } },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { target: "_self" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.abrirPostagem(
                                        _vm.listaNoticias[
                                          _vm.listaNoticias.length - 1
                                        ]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-weight": "bolder",
                                        "font-size": "14px",
                                      },
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.listaNoticias[
                                              _vm.listaNoticias.length - 1
                                            ].titulo
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "card-content" }, [
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.listaNoticias[
                                            _vm.listaNoticias.length - 1
                                          ].chamada
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.listaNoticias.length > 1
                    ? _c(
                        "el-col",
                        { attrs: { xs: 8, sm: 8, lg: 8 } },
                        [
                          _c(
                            "el-card",
                            { staticStyle: { "margin-right": "0.5rem" } },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { target: "_self" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.abrirPostagem(
                                        _vm.listaNoticias[
                                          _vm.listaNoticias.length - 2
                                        ]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-weight": "bolder",
                                        "font-size": "14px",
                                      },
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.listaNoticias[
                                              _vm.listaNoticias.length - 2
                                            ].titulo
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "card-content" }, [
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.listaNoticias[
                                            _vm.listaNoticias.length - 2
                                          ].chamada
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.listaNoticias.length > 2
                    ? _c(
                        "el-col",
                        { attrs: { xs: 8, sm: 8, lg: 8 } },
                        [
                          _c("el-card", [
                            _c(
                              "a",
                              {
                                attrs: { target: "_self" },
                                on: {
                                  click: function ($event) {
                                    return _vm.abrirPostagem(
                                      _vm.listaNoticias[
                                        _vm.listaNoticias.length - 3
                                      ]
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bolder",
                                      "font-size": "14px",
                                    },
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.listaNoticias[
                                            _vm.listaNoticias.length - 3
                                          ].titulo
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "card-content" }, [
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.listaNoticias[
                                          _vm.listaNoticias.length - 3
                                        ].chamada
                                      ),
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("el-row", [
                _vm.listaNoticias.length > 0
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "margin-top": "1rem",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.modalVisible = true
                              },
                            },
                          },
                          [
                            _vm._v("+ Notícias "),
                            _c("i", { staticClass: "el-icon-d-arrow-right" }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("el-row", [_c("h1", [_vm._v("Últimas visitas")])]),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "2rem" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.listaFuncionarios },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "nome", label: "NOME" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cpf", label: "CPF" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "profissao", label: "PROFISSÃO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "temperatura", label: "TEMPERATURA" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "criado_em", label: "DATA COLETA" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(
                                        _vm.listaFuncionarios[scope.$index]
                                          .criado_em
                                      )
                                      .format("DD/MM/YYYY")
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }