var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("aside", { staticStyle: { "margin-top": "15px" } }, [
        _vm._v(
          "\n    Here we use window.print() to implement the feature of downloading PDF.\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        {
          attrs: {
            target: "_blank",
            to: {
              path: "/pdf/download",
              query: { title: _vm.titulo, content: _vm.conteudo },
            },
          },
        },
        [
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v("\n      Click to download PDF\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }