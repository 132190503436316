"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.replace");

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _validate = require("@/utils/validate");

var _vuex = require("vuex");

var _localforage = _interopRequireDefault(require("localforage"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  name: 'Login',
  components: {},
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('Por favor insira um email válido'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('A senha deve conter 6 caracteres ou mais'));
      } else {
        callback();
      }
    };

    var validateUsername = function validateUsername(rule, value, callback) {
      if (value == "") {
        callback(new Error('Por favor preencha o campo de login'));
      } else {
        callback();
      }
    };

    return {
      usarCelular: false,
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      selectedPerfil: 0,
      'tipos': {
        'admin': 'Administrador',
        'enfermeiro': 'Enfermeiro',
        'medico': 'Médico',
        'fisioterapeuta': 'Fisioterapeuta',
        'psicologo': 'Psicólogo',
        'servico_social': 'Serviço social',
        'nutricionista': 'Nutricionista',
        'fonoaudiologo': 'Fonoaudiólogo',
        'assistente_social': 'Assistente social',
        'tecnico_de_enfermagem': 'Técnico de enfermagem',
        'tecnicos_de_informatica': 'Técnico de informática',
        'tecnico_de_laboratorio': 'Técnico de laboratório',
        'tecnicos_de_raio_x': 'Técnico de raio x',
        // 'administrativo': 'Administrativo',
        'recepcionista': 'Recepcionista',
        'guarda': 'Guarda',
        'servidor_de_limpeza': 'Servidor de limpeza',
        'dentista': 'Dentista',
        'tecnico_em_manutencao': 'Técnico em manutenção',
        'terapeuta_ocupacionais': 'Terapeuta ocupacional',
        'outro': 'Outros'
      }
    };
  },
  beforeMount: function beforeMount() {
    if (this.domain === '' || this.domain === undefined || this.domain === null) {
      //  && process.env.NODE_ENV !== 'development'
      this.$router.push('/localidade');
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('account', ['perfis'])), (0, _vuex.mapGetters)(['domain', 'secretaria', 'perfil'])),
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;

        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {// window.addEventListener('storage', this.afterQRScan)
  },
  mounted: function mounted() {
    if (this.loginForm.username === '') {
      this.$refs.username.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  destroyed: function destroyed() {// window.removeEventListener('storage', this.afterQRScan)
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, (0, _vuex.mapMutations)('account', {
    setPerfis: 'SET_PERFIS'
  })), {}, {
    zerarCamposLogin: function zerarCamposLogin() {
      this.loginForm.username = "";
      this.loginForm.password = "";
    }
  }, (0, _vuex.mapMutations)('user', ['SET_SECRETARIA'])), {}, {
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    goForgot: function goForgot() {
      this.$router.push('/recuperar-senha');
    },
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    changelocalidade: function changelocalidade() {
      this.$router.push('/localidade');
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      var self = this;

      if (!this.usarCelular) {
        this.loginForm.username = this.loginForm.username.replace(/\./g, "").replace("-", "");
      } else {
        this.loginForm.username = this.loginForm.username.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
      }

      console.log("Login Form:", this.loginForm);
      this.$refs.loginForm.validate(function (valid) {
        if (true) {
          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.loginForm).then(function (res) {
            localStorage.setItem('roles', JSON.stringify(res));

            _localforage.default.getItem('secretaria_scheme').then(function (sec_schema) {
              _this2.loading = false;

              _this2.setPerfis(res.filter(function (p) {
                return (p.role === 'medico' || p.role === 'admin' || p.role === 'recepcionista' || p.role === 'assistente_social' || p.role === 'enfermeiro' || p.role === 'fisioterapeuta') && (p.codigo_instituicao === sec_schema && p.tipo_instituicao === 'SECRETARIA' || p.tipo_instituicao === 'HOSPITAL' || p.tipo_instituicao === 'EMPRESA');
              }));
            }); // console.log(res)
            // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
            // this.loading = false

          }).catch(function (err) {
            console.log("error", err);
            console.log("error", err.response);
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }

        return acc;
      }, {});
    },
    selectPerfil: function selectPerfil(index) {
      var _this3 = this;

      console.log(this.perfil.tipo_instituicao);
      this.$store.dispatch('user/selectPerfil', this.perfis[index]).then(function () {
        var defaultroute = _this3.perfil.tipo_instituicao === 'HOSPITAL' ? '/pacientes' : _this3.perfil.tipo_instituicao === 'EMPRESA' ? '/profissionais' : '/empresa';
        console.log("Selecionou o perfil.");
        console.log("Perfil: ", _this3.perfil.tipo_instituicao);

        if (_this3.perfil.tipo_instituicao === "EMPRESA") {
          _this3.$router.push({
            path: '/empresa',
            query: _this3.otherQuery
          });
        } else {
          _this3.$router.push({
            path: _this3.redirect || '/',
            query: _this3.otherQuery
          });
        }
      });
    } // afterQRScan() {
    //   if (e.key === 'x-admin-oauth-code') {
    //     const code = getQueryObject(e.newValue)
    //     const codeMap = {
    //       wechat: 'code',
    //       tencent: 'code'
    //     }
    //     const type = codeMap[this.auth_type]
    //     const codeName = code[type]
    //     if (codeName) {
    //       this.$store.dispatch('LoginByThirdparty', codeName).then(() => {
    //         this.$router.push({ path: this.redirect || '/' })
    //       })
    //     } else {
    //       alert('第三方登录失败')
    //     }
    //   }
    // }

  })
};
exports.default = _default;