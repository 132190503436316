"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSecretarias = getSecretarias;

var _request = _interopRequireDefault(require("@/utils/request"));

function getSecretarias(uf) {
  return (0, _request.default)({
    url: '/secretarias?uf=' + uf,
    method: 'get'
  });
}