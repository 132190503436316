"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRole = addRole;
exports.deleteRole = deleteRole;
exports.getRoles = getRoles;
exports.getRoutes = getRoutes;
exports.listRoles = listRoles;
exports.updateRole = updateRole;

var _request = _interopRequireDefault(require("@/utils/request"));

function listRoles() {
  return (0, _request.default)({
    url: '/core/roles',
    method: 'get'
  });
}

function getRoutes() {
  return (0, _request.default)({
    url: '/vue-element-admin/routes',
    method: 'get'
  });
}

function getRoles() {
  return (0, _request.default)({
    url: '/vue-element-admin/roles',
    method: 'get'
  });
}

function addRole(data) {
  return (0, _request.default)({
    url: '/vue-element-admin/role',
    method: 'post',
    data: data
  });
}

function updateRole(id, data) {
  return (0, _request.default)({
    url: "/vue-element-admin/role/".concat(id),
    method: 'put',
    data: data
  });
}

function deleteRole(id) {
  return (0, _request.default)({
    url: "/vue-element-admin/role/".concat(id),
    method: 'delete'
  });
}