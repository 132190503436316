var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "components-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "editor-container" },
      [
        _c("dnd-list", {
          attrs: {
            list1: _vm.list1,
            list2: _vm.list2,
            "list1-title": "List",
            "list2-title": "Article pool",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v("drag-list base on\n    "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/SortableJS/Vue.Draggable",
            target: "_blank",
          },
        },
        [_vm._v("Vue.Draggable")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }