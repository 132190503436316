"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listRecados = listRecados;

var _request = _interopRequireDefault(require("@/utils/request"));

function listRecados() {
  return (0, _request.default)({
    url: '/cms/recados/',
    method: 'get'
  });
}