"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  email: function email(state) {
    return state.user.email;
  },
  id: function id(state) {
    return state.user.id;
  },
  domain: function domain(state) {
    return state.user.domain;
  },
  secretaria: function secretaria(state) {
    return state.user.secretaria;
  },
  secretaria_scheme: function secretaria_scheme(state) {
    return state.user.secretaria_scheme;
  },
  secretaria_co_uf: function secretaria_co_uf(state) {
    return state.user.secretaria_co_uf;
  },
  secretaria_co_localidade: function secretaria_co_localidade(state) {
    return state.user.secretaria_co_localidade;
  },
  secretaria_no_localidade: function secretaria_no_localidade(state) {
    return state.user.secretaria_no_localidade;
  },
  casosResumo: function casosResumo(state) {
    return state.stats.casosResumo;
  },
  username: function username(state) {
    return state.user.username;
  },
  perfil: function perfil(state) {
    return state.user.perfil;
  }
};
var _default = getters;
exports.default = _default;