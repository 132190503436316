var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainchatpage" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.imagemodalname,
            visible: _vm.imagemodalvisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imagemodalvisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticStyle: { "max-width": "100%" },
              attrs: { src: _vm.imagemodalurl },
            }),
          ]),
        ]
      ),
      _c("div", { staticClass: "chatmaincontainer" }, [
        _c(
          "div",
          { staticClass: "chatlist" },
          _vm._l(_vm.orderedChatList, function (chat, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "chat",
                on: {
                  click: function ($event) {
                    return _vm.openChat(chat.id)
                  },
                },
              },
              [
                _c("i", {
                  class: {
                    "el-icon-finished": chat.status === "encerrado",
                    "el-icon-service": chat.status === "andamento",
                    "el-icon-message-solid": chat.status === "pendente",
                  },
                  style: {
                    "padding-right": "0.5rem",
                    color: {
                      encerrado: "green",
                      pendente: "orange",
                      andamento: "grey",
                    }[chat.status],
                    "font-size": "1.4rem",
                  },
                }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "14px",
                      "padding-bottom": "0.2rem",
                      display: "inline-block",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        {
                          encerrado: "Encerrado",
                          andamento: "Em andamento",
                          pendente: "Pendente",
                        }[chat.status]
                      )
                    ),
                  ]
                ),
                _c("br"),
                _vm._v(
                  _vm._s(
                    _vm
                      .moment(new Date(chat.horaultimamensagem.getTime()))
                      .format("hh:mm")
                  ) +
                    " - " +
                    _vm._s(chat.nome)
                ),
                chat.status === "pendente" || chat.status === "andamento"
                  ? _c("br")
                  : _vm._e(),
                chat.status === "pendente" || chat.status === "andamento"
                  ? _c("br")
                  : _vm._e(),
                _c(
                  "el-popconfirm",
                  {
                    attrs: {
                      title: "Iniciar atendimento",
                      confirmButtonText: "Sim",
                      cancelButtonText: "Não",
                    },
                    on: {
                      onConfirm: function ($event) {
                        return _vm.acceptChat(chat.id)
                      },
                    },
                  },
                  [
                    chat.status === "pendente"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "success",
                              round: "",
                              size: "small",
                            },
                            slot: "reference",
                          },
                          [_vm._v("Atender")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-popconfirm",
                  {
                    attrs: {
                      title: "Finalizar atendimento",
                      confirmButtonText: "Sim",
                      cancelButtonText: "Não",
                      iconColor: "red",
                      confirmButtonType: "danger",
                    },
                    on: {
                      onConfirm: function ($event) {
                        return _vm.finishChat(chat.id)
                      },
                    },
                  },
                  [
                    chat.status === "andamento"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              round: "",
                              size: "small",
                            },
                            slot: "reference",
                          },
                          [_vm._v("Encerrar")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
        _vm.chat
          ? _c("div", { staticClass: "chatwindow" }, [
              _c(
                "div",
                {
                  staticClass: "chatmessagescontainer",
                  attrs: { id: "chatmessages" },
                },
                _vm._l(_vm.chat.mensagens, function (mensagem, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "chatmessage",
                      class: {
                        saicmessage: mensagem.fromsaic === true,
                        usermessage: mensagem.fromsaic === false,
                      },
                    },
                    [
                      mensagem.type === "message"
                        ? _c("div", { staticClass: "chatmessagealigned" }, [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "0.8rem" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm
                                      .moment(new Date(mensagem.hora.getTime()))
                                      .format("hh:mm")
                                  )
                                ),
                              ]
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(mensagem.nome) +
                                ": " +
                                _vm._s(mensagem.content)
                            ),
                          ])
                        : _vm._e(),
                      mensagem.type === "file"
                        ? _c(
                            "div",
                            {
                              staticClass: "filebox",
                              staticStyle: {
                                "font-size": "1rem",
                                "margin-top": "0.3rem",
                                width: "110px",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "0.8rem" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .moment(
                                          new Date(mensagem.hora.getTime())
                                        )
                                        .format("hh:mm")
                                    )
                                  ),
                                ]
                              ),
                              _c("br"),
                              _vm._v(_vm._s(mensagem.nome)),
                              _c("br"),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      _vm.imagemodalvisible = true
                                      _vm.imagemodalurl = mensagem.content.url
                                      _vm.imagemodalname =
                                        mensagem.content.filename
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { width: "100px" },
                                    attrs: { src: mensagem.content.url },
                                  }),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "filename",
                                      staticStyle: { "font-size": "12px" },
                                    },
                                    [_vm._v(_vm._s(mensagem.content.filename))]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "chatoptions" }, [
                _c("div", { staticClass: "upload" }, [
                  _c(
                    "div",
                    {
                      staticClass: "upload-btn-wrapper",
                      staticStyle: { width: "50px", float: "left" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          class: {
                            btndisabled:
                              !_vm.chat ||
                              (_vm.chat.status === "andamento" &&
                                _vm.chat.responsavel !== _vm.email) ||
                              _vm.chat.status === "pendente",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "20px" },
                            attrs: {
                              src: require("@/assets/images/downloaded_image.svg"),
                            },
                          }),
                        ]
                      ),
                      _c("input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          type: "file",
                          accept: ".png,.jpg,.jpeg,.gif",
                          disabled:
                            !_vm.chat ||
                            (_vm.chat.status === "andamento" &&
                              _vm.chat.responsavel !== _vm.email) ||
                            _vm.chat.status === "pendente",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.uploadimage($event)
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "textinput" },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 4,
                        placeholder: "Escreva algo...",
                        disabled:
                          !_vm.chat ||
                          (_vm.chat.status === "andamento" &&
                            _vm.chat.responsavel !== _vm.email) ||
                          _vm.chat.status === "pendente",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.sendMessage("message")
                        },
                      },
                      model: {
                        value: _vm.textToSend,
                        callback: function ($$v) {
                          _vm.textToSend = $$v
                        },
                        expression: "textToSend",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "buttonsend" }, [
                  _c(
                    "div",
                    {
                      staticClass: "buttonsendinternal",
                      on: {
                        click: function ($event) {
                          return _vm.sendMessage("message")
                        },
                      },
                    },
                    [_vm._v("Enviar")]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }