var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "Gerando o PDF..." },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "article__heading" }, [
        _c("div", { staticClass: "article__heading__title" }, [
          _vm._v(_vm._s(_vm.title) + " "),
        ]),
        _c("br"),
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
            [
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("span", [_vm._v("RESULTADOS")])]
                ),
                _c("div", { staticClass: "content-card" }, [
                  _c("span", [_vm._v(_vm._s(_vm.dados.length))]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", gutter: 32 } },
        [
          _c(
            "div",
            { staticClass: "result-table-style" },
            [
              _c(
                "el-table",
                { staticClass: "table-style", attrs: { data: _vm.dados } },
                [
                  _c("el-table-column", {
                    attrs: { prop: "nome", label: "NOME" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "username", label: "USUÁRIO" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "email", label: "E-MAIL" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "criado_em[0]", label: "DATA" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.dados[scope.$index].criado_em[0].replace(
                                  /(\d{4})-(\d{2})-(\d{2})/,
                                  "$3/$2/$1"
                                )
                              ) + "          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "printlogo" } }, [
      _c("div", { staticClass: "header-logo-saic" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/SAICovid-19.png") },
        }),
      ]),
      _c("div", { staticClass: "header-text" }, [
        _c("span", [_vm._v("INFATEC - SOLUÇÕES TECNOLÓGICAS")]),
        _c("br"),
        _c("span", [_vm._v("TERESINA - PI")]),
        _c("br"),
        _c("span", [_vm._v("SISTEMA DE ACOMPANHAMENTO INTEGRAL AO COVID-19")]),
      ]),
      _c("div", { staticClass: "header-logo-infatec" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/infatec-logo.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }