"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      titulo: "Meu PDF",
      conteudo: "Meu conteudo"
    };
  },
  methods: {}
};
exports.default = _default;