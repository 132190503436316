exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "rgba(255,255,255,0.733)",
	"menuActiveText": "#67ca5a",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#571c4b",
	"menuHover": "#46173c",
	"subMenuBg": "#3a1332",
	"subMenuHover": "#290d23",
	"sideBarWidth": "210px"
};