var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checkedProfile && _vm.perfil.tipo_instituicao === "SECRETARIA"
    ? _c(
        "div",
        { staticClass: "dashboard-container" },
        [_c(_vm.currentRole, { tag: "component" })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }