"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterIndicador = filterIndicador;
exports.getIndicadores = getIndicadores;

var _request = _interopRequireDefault(require("@/utils/request"));

function getIndicadores() {
  return (0, _request.default)({
    url: '/stats/indicadores/',
    method: 'get'
  });
}

function filterIndicador(numIndicador, data) {
  return (0, _request.default)({
    url: '/stats/indicadores/' + numIndicador + '/?' + data,
    method: 'get'
  });
}