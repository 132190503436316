var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticStyle: { "margin-bottom": "20px" } }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("About me")])]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "user-profile" }, [
      _c(
        "div",
        { staticClass: "box-center" },
        [
          _c(
            "pan-thumb",
            {
              attrs: {
                image: _vm.user.avatar,
                height: "100px",
                width: "100px",
                hoverable: false,
              },
            },
            [
              _c("div", [_vm._v("Hello")]),
              _vm._v("\n        " + _vm._s(_vm.user.role) + "\n      "),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "box-center" }, [
        _c("div", { staticClass: "user-name text-center" }, [
          _vm._v(_vm._s(_vm.user.name)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "user-role text-center text-muted" }, [
          _vm._v(_vm._s(_vm._f("uppercaseFirst")(_vm.user.role))),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "user-bio" }, [
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "education" } }),
            _c("span", [_vm._v("Education")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _vm._v(
              "\n          JS in Computer Science from the University of Technology\n        "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "user-skills user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { "icon-class": "skill" } }),
            _c("span", [_vm._v("Skills")]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("Vue")]),
              _vm._v(" "),
              _c("el-progress", { attrs: { percentage: 70 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("JavaScript")]),
              _vm._v(" "),
              _c("el-progress", { attrs: { percentage: 18 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("Css")]),
              _vm._v(" "),
              _c("el-progress", { attrs: { percentage: 12 } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("ESLint")]),
              _vm._v(" "),
              _c("el-progress", {
                attrs: { percentage: 100, status: "success" },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }