var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _vm.secretaria === "CAXIAS - MA "
        ? [
            _c("h1", { staticClass: "dash-titles" }, [_vm._v("Vacinados")]),
            _vm._v(" "),
            _vm._m(0),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("h1", { staticClass: "dash-titles" }, [_vm._v("Casos COVID-19")]),
      _vm._v(" "),
      _c("panel-group", { attrs: { data: this.casos } }),
      _vm._v(" "),
      _c("h2", { staticClass: "dash-titles" }, [_vm._v("Grupos de Risco")]),
      _vm._v(" "),
      _c("panel-group", { attrs: { data: this.fichas } }),
      _vm._v(" "),
      _c("h1", { staticClass: "dash-titles" }, [
        _vm._v("Mapa de Calor do Sistema"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "el-checkbox-group-style" },
        [
          _c(
            "el-checkbox-group",
            {
              model: {
                value: _vm.filtro_mapa_calor,
                callback: function ($$v) {
                  _vm.filtro_mapa_calor = $$v
                },
                expression: "filtro_mapa_calor",
              },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "32px" },
                  attrs: { gutter: 5 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 8, sm: 8, lg: 8 } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { label: "grupo-risco" },
                          on: {
                            change: function ($event) {
                              return _vm.updatedSelectedItems("grupo-risco")
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "17px" } }, [
                            _vm._v("Grupo de Risco"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "padding-left": "20px" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_gestante" } },
                                [_vm._v("Gestante")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_doenca_respiratoria" } },
                                [_vm._v("Doença Respiratória")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_hipertensao_arterial" } },
                                [_vm._v("Hipertensão Arterial")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_diabete" } },
                                [_vm._v("Diabetes")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_avc" } },
                                [_vm._v("AVC")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_infarto" } },
                                [_vm._v("Infarto")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_tuberculose" } },
                                [_vm._v("Tuberculose")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_cancer" } },
                                [_vm._v("Cancer")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_doenca_cardiaca" } },
                                [_vm._v("Doença Cardíaca")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_problema_rins" } },
                                [_vm._v("Problemas Renais")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_morador_rua" } },
                                [_vm._v("Situação de Rua")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "st_fumante" } },
                                [_vm._v("Fumante")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 8, sm: 8, lg: 8 } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { label: "casos-covid-19" },
                          on: {
                            change: function ($event) {
                              return _vm.updatedSelectedItems("casos-covid-19")
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "17px" } }, [
                            _vm._v("Casos COVID-19"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "padding-left": "20px" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "confirmado" } },
                                [_vm._v("Confirmado")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "suspeito" } },
                                [_vm._v("Suspeito")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "recuperado" } },
                                [_vm._v("Recuperado")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c("el-checkbox", { attrs: { label: "obito" } }, [
                                _vm._v("Óbito"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { xs: 8, sm: 8, lg: 8 } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { label: "usuario" },
                          on: {
                            change: function ($event) {
                              return _vm.updatedSelectedItems("usuario")
                            },
                          },
                        },
                        [
                          _c("span", { staticStyle: { "font-size": "17px" } }, [
                            _vm._v("Usuário"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "padding-left": "20px" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "agente" } },
                                [_vm._v("Agente")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "cidadao" } },
                                [_vm._v("Cidadão")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "32px" } },
        [
          _c(
            "el-col",
            {
              staticClass: "card-panel-col",
              staticStyle: { background: "#fff", padding: "10px" },
              attrs: { xs: 24, sm: 24, md: 24 },
            },
            [
              _c(
                "div",
                { staticStyle: { height: "500px" } },
                [
                  _c(
                    "el-tooltip",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: {
                        effect: "dark",
                        content: _vm.label,
                        "visible-arrow": false,
                        manual: "",
                      },
                      model: {
                        value: _vm.tooltip,
                        callback: function ($$v) {
                          _vm.tooltip = $$v
                        },
                        expression: "tooltip",
                      },
                    },
                    [
                      _c(
                        "l-map",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: function () {
                                return (_vm.tooltip = false)
                              },
                              expression: "() => tooltip = false",
                            },
                            {
                              name: "tap-outside",
                              rawName: "v-tap-outside",
                              value: function () {
                                return (_vm.tooltip = false)
                              },
                              expression: "() => tooltip = false",
                            },
                          ],
                          ref: "map",
                          staticStyle: { height: "100%", width: "100%" },
                          attrs: { zoom: _vm.zoom, center: _vm.center },
                          on: {
                            "update:zoom": _vm.zoomUpdated,
                            "update:bounds": _vm.boundsUpdated,
                            mouseout: function ($event) {
                              _vm.tooltip = false
                            },
                            mousemove: _vm.onmousemove,
                          },
                        },
                        [_c("l-tile-layer", { attrs: { url: _vm.url } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", { staticStyle: { "text-align": "left" } }, [
        _vm._v("Número de Usuários Ativos"),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px",
            "margin-bottom": "32px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { xs: 12, sm: 12, lg: 24 } },
            [_c("line-chart", { attrs: { "chart-data": _vm.lineData1 } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", { staticStyle: { "text-align": "left" } }, [
        _vm._v("Gestantes em Acompanhamento"),
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px",
            "margin-bottom": "32px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { xs: 12, sm: 12, lg: 24 } },
            [_c("line-chart", { attrs: { "chart-data": _vm.lineData2 } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 8 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("h3", { staticStyle: { "text-align": "left" } }, [
                  _vm._v("Casos de doença respiratória por tipo"),
                ]),
                _vm._v(" "),
                _c("pie-chart", { attrs: { data: this.pieData } }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 16 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("h3", { staticStyle: { "text-align": "left" } }, [
                  _vm._v("Distribuição grupo de risco por bairro"),
                ]),
                _vm._v(" "),
                _c("bar-chart", { attrs: { data: this.barData } }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-row", { attrs: { gutter: 8 } }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "relative",
          overflow: "hidden",
          width: "100%",
          "padding-top": "56.25%",
        },
      },
      [
        _c("iframe", {
          staticClass: "responsive-iframe",
          staticStyle: { border: "0" },
          attrs: {
            src: "https://datastudio.google.com/embed/reporting/b35a12cd-610d-4de8-9d86-6f4da91ac0c8/page/8TVeC",
            frameborder: "0",
            allowfullscreen: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }