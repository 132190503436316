var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "errPage-container" },
    [
      _c("ErrorA"),
      _vm._v(" "),
      _c("ErrorB"),
      _vm._v(" "),
      _c("h3", [_vm._v("Please click the bug icon in the upper right corner")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v(
        "\n    Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.\n    "
      ),
      _c(
        "a",
        {
          staticClass: "link-type",
          attrs: {
            target: "_blank",
            href: "https://panjiachen.github.io/vue-element-admin-site/guide/advanced/error.html",
          },
        },
        [_vm._v("\n      Document introduction\n    ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "#" } }, [
      _c("img", {
        attrs: {
          src: "https://wpimg.wallstcn.com/360e4842-4db5-42d0-b078-f9a84a825546.gif",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }