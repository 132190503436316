"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuedraggable = _interopRequireDefault(require("vuedraggable"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  name: 'DragKanbanDemo',
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    headerText: {
      type: String,
      default: 'Header'
    },
    options: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  methods: {
    setData: function setData(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/Sortable/issues/1012
      dataTransfer.setData('Text', '');
    }
  }
};
exports.default = _default2;