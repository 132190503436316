"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ErrorTestA = _interopRequireDefault(require("./components/ErrorTestA"));

var _ErrorTestB = _interopRequireDefault(require("./components/ErrorTestB"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'ErrorLog',
  components: {
    ErrorA: _ErrorTestA.default,
    ErrorB: _ErrorTestB.default
  }
};
exports.default = _default;