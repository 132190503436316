"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.string.starts-with");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.array.sort");

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.regexp.split");

var _GithubCorner = _interopRequireDefault(require("@/components/GithubCorner"));

var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup"));

var _LineChart = _interopRequireDefault(require("./components/LineChart"));

var _RaddarChart = _interopRequireDefault(require("./components/RaddarChart"));

var _PieChart = _interopRequireDefault(require("./components/PieChart"));

var _BarChart = _interopRequireDefault(require("./components/BarChart"));

var _TransactionTable = _interopRequireDefault(require("./components/TransactionTable"));

var _TodoList = _interopRequireDefault(require("./components/TodoList"));

var _BoxCard = _interopRequireDefault(require("./components/BoxCard"));

var _vue2Leaflet = require("vue2-leaflet");

var _leafletGeosearch = require("leaflet-geosearch");

var _vue2LeafletGeosearch = _interopRequireDefault(require("vue2-leaflet-geosearch"));

var _vuex = require("vuex");

require("leaflet.heat");

var _leaflet2 = require("leaflet");

var _localforage = _interopRequireDefault(require("localforage"));

var _vueClickOutside = _interopRequireDefault(require("vue-click-outside"));

var _vueTapOutside = _interopRequireDefault(require("vue-tap-outside"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var lineChartData = {
  users: {
    categories: ['Usuários', 'Profissionais'],
    labels: ['Set/19', 'Out/19', 'Nov/19', 'Dez/19', 'Jan/20', 'Fev/20', 'Mar/20'],
    expectedData: [0, 0, 0, 0, 0, 0, 0],
    actualData: [0, 0, 0, 0, 0, 0, 0]
  }
};
var provider = new _leafletGeosearch.OpenStreetMapProvider();
var _default = {
  name: 'DashboardAdmin',
  directives: {
    ClickOutside: _vueClickOutside.default,
    TapOutside: _vueTapOutside.default
  },
  components: {
    GithubCorner: _GithubCorner.default,
    PanelGroup: _PanelGroup.default,
    LineChart: _LineChart.default,
    RaddarChart: _RaddarChart.default,
    PieChart: _PieChart.default,
    BarChart: _BarChart.default,
    TransactionTable: _TransactionTable.default,
    TodoList: _TodoList.default,
    BoxCard: _BoxCard.default,
    LMap: _vue2Leaflet.LMap,
    LTileLayer: _vue2Leaflet.LTileLayer,
    VGeosearch: _vue2LeafletGeosearch.default,
    LCircle: _vue2Leaflet.LCircle
  },
  data: function data() {
    return {
      heatMapLayer: null,
      categoria: null,
      subcategoria: null,
      filtro_mapa_calor: [],
      label: '0',
      tooltip: false,
      mapaAtual: [],
      center: [0, 0],
      lineChartData: lineChartData.users,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      // center: [-8.1079074, -34.9195285],
      bounds: null,
      geosearchOptions: {
        // Important part Here
        provider: new _leafletGeosearch.OpenStreetMapProvider()
      },
      selected_condition: 26,
      conditions: [{
        id: 25,
        nome: 'Hipertensão Arterial'
      }, {
        id: 26,
        nome: 'Diabetes'
      }, {
        id: 29,
        nome: 'Doença Cardíaca'
      }, {
        id: 31,
        nome: 'Problemas Renais'
      }],
      cities_condition: {},
      latLngCities: {},
      isLoading: false,
      isConsultingAddress: false,
      geoData: [],
      casos: {
        gutter: 10,
        offset: 1,
        col: 4,
        panels: [{
          label: 'Recuperados',
          value: 4339
        }, {
          label: 'Suspeitos',
          value: 38
        }, {
          label: 'Obitos',
          value: 113
        }, {
          label: 'Confirmados',
          value: 4862
        }]
      },
      fichas: {
        gutter: 10,
        offset: 0,
        col: 6,
        panels: [{
          label: 'Diabetes',
          value: 0
        }, {
          label: 'Hipertensão',
          value: 0
        }, {
          label: 'Prob. Renais',
          value: 0
        }, {
          label: 'Prob. Respiratório',
          value: 0
        }]
      },
      pieData: [{
        value: 137,
        name: 'Asma'
      }, {
        value: 6,
        name: 'Enfisema'
      }, {
        value: 64,
        name: 'Outros'
      }],
      pieChartsData: {
        'ma-caxias': [{
          value: 137,
          name: 'Asma'
        }, {
          value: 6,
          name: 'Enfisema'
        }, {
          value: 64,
          name: 'Outros'
        }],
        'pi-pimenteiras': [{
          value: 121,
          name: 'Asma'
        }, {
          value: 4,
          name: 'Enfisema'
        }, {
          value: 105,
          name: 'Outros'
        }],
        'pi-elesbao-veloso': [{
          value: 137,
          name: 'Asma'
        }, {
          value: 6,
          name: 'Enfisema'
        }, {
          value: 64,
          name: 'Outros'
        }],
        'pi-pio-ix': [{
          value: 0,
          name: 'Asma'
        }, {
          value: 0,
          name: 'Enfisema'
        }, {
          value: 2,
          name: 'Outros'
        }],
        localhost: [{
          value: 137,
          name: 'Asma'
        }, {
          value: 6,
          name: 'Enfisema'
        }, {
          value: 64,
          name: 'Outros'
        }]
      },
      lineData: {
        recuperados: 0,
        suspeitos: 0,
        confirmados: 0,
        obitos: 0
      },
      lineChartsData: {
        'ma-caxias': {
          recuperados: 0,
          suspeitos: 20,
          confirmados: 2,
          obitos: 0
        },
        'pi-pimenteiras': {
          recuperados: 0,
          suspeitos: 0,
          confirmados: 0,
          obitos: 1
        },
        'pi-elesbao-veloso': {
          recuperados: 0,
          suspeitos: 0,
          confirmados: 0,
          obitos: 0
        },
        'pi-pio-ix': {
          recuperados: 0,
          suspeitos: 0,
          confirmados: 0,
          obitos: 0
        },
        localhost: {
          recuperados: 10,
          suspeitos: 10,
          confirmados: 1,
          obitos: 0
        }
      },
      barData: {
        labels: ['Zona Rural', 'Centro', 'Vila Nova', 'Conjunto Habitacional', 'Assentamento'],
        data: [{
          name: 'Grupo de Risco',
          values: [964, 616, 108, 24, 48]
        }]
      },
      barChartsData: {
        'ma-caxias': {
          labels: ['Zona Rural', 'Centro', 'Vila Nova', 'Conjunto Habitacional', 'Assentamento'],
          data: [{
            name: 'Grupo de Risco',
            values: [964, 616, 108, 24, 48]
          }]
        },
        'pi-pimenteiras': {
          labels: ['Zona Rural', 'Centro', 'Vila Nova', 'Conjunto Habitacional', 'Assentamento'],
          data: [{
            name: 'Grupo de Risco',
            values: [964, 616, 108, 24, 48]
          }]
        },
        'pi-elesbao-veloso': {
          labels: ['Zona Rural', 'Matias', 'Piçara', 'Santa Clara', 'Cirino'],
          data: [{
            name: 'Grupo de Risco',
            values: [573, 301, 298, 198, 177]
          }]
        },
        'pi-pio-ix': {
          labels: ['Zona Rural', 'Centro'],
          data: [{
            name: 'Grupo de Risco',
            values: [9, 1]
          }]
        },
        localhost: {
          labels: ['Zona Rural', 'Centro', 'Vila Nova', 'Conjunto Habitacional', 'Assentamento'],
          data: [{
            name: 'Grupo de Risco',
            values: [964, 616, 108, 24, 48]
          }]
        }
      },
      lineChartsData1: {
        'pi-elesbao-veloso': {
          categories: ['Cidadãos', 'Profissionais'],
          labels: ['Abr/20', 'Mai/20', 'Jun/20', 'Jul/20', 'Ago/20'],
          expectedData: [0, 38, 77, 109, 109],
          actualData: [88, 89, 89, 89, 89]
        },
        'pi-pimenteiras': {
          categories: ['Cidadãos', 'Profissionais'],
          labels: ['Abr/20', 'Mai/20', 'Jun/20', 'Jul/20', 'Ago/20'],
          expectedData: [0, 31, 121, 130, 130],
          actualData: [72, 73, 73, 73, 73]
        },
        'ma-caxias': {
          categories: ['Cidadãos', 'Profissionais'],
          labels: ['Abr/20', 'Mai/20', 'Jun/20', 'Jul/20', 'Ago/20'],
          expectedData: [0, 9, 10, 78, 81],
          actualData: [0, 787, 787, 787, 795]
        },
        'pi-pio-ix': {
          categories: ['Cidadãos', 'Profissionais'],
          labels: ['Abr/20', 'Mai/20', 'Jun/20', 'Jul/20', 'Ago/20'],
          expectedData: [0, 0, 3, 3, 3],
          actualData: [0, 0, 0, 0, 0]
        }
      },
      lineChartsData2: {
        'pi-elesbao-veloso': {
          categories: ['Semanas < 20', 'Semanas > 20'],
          labels: ['Set/19', 'Out/19', 'Nov/19', 'Dez/19', 'Jan/20', 'Fev/20', 'Mar/20', 'Abr/20'],
          expectedData: [1, 6, 1, 1, 0, 4, 5],
          actualData: [4, 5, 3, 0, 7, 7, 4, 2]
        },
        'pi-pimenteiras': {
          categories: ['Semanas < 20', 'Semanas > 20'],
          labels: ['Fev/20', 'Mar/20', 'Abr/20', 'Mai/20', 'Jun/20'],
          expectedData: [2, 1, 0, 4, 6],
          actualData: [0, 1, 2, 5, 4]
        },
        'ma-caxias': {
          categories: ['Semanas < 20', 'Semanas > 20'],
          labels: ['Set/19', 'Out/19', 'Nov/19', 'Dez/19', 'Jan/20', 'Fev/20', 'Mar/20'],
          expectedData: [49, 53, 42, 39, 51, 23, 13],
          actualData: [83, 88, 75, 63, 70, 40, 18]
        },
        'pi-pio-ix': {
          categories: ['Semanas < 20', 'Semanas > 20'],
          labels: ['Set/19', 'Out/19', 'Nov/19', 'Dez/19', 'Jan/20', 'Fev/20', 'Mar/20'],
          expectedData: [0, 0, 0, 0, 0, 0, 0],
          actualData: [0, 0, 0, 0, 0, 0, 0]
        }
      },
      lineData1: {
        categories: ['Semanas < 20', 'Semanas > 20'],
        labels: ['Fev/20', 'Mar/20'],
        expectedData: [0, 0],
        actualData: [0, 0]
      },
      lineData2: {
        categories: ['Semanas < 20', 'Semanas > 20'],
        labels: ['Fev/20', 'Mar/20'],
        expectedData: [0, 0],
        actualData: [0, 0]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.isLoading = true;
    var domain = this.$store.getters.domain;
    var domainOnly = domain.split(':')[0];
    var subdomain = domainOnly.split('.')[0];

    _localforage.default.getItem('secretaria_lat').then(function (lat) {
      return Promise.all([lat, _localforage.default.getItem('secretaria_lng')]);
    }).then(function (center) {
      _this.center = center;
    });

    this.$store.dispatch('stats/getCasosResumo').then(function (casos) {// this.casos = casos;
    });
    this.$store.dispatch('stats/getFichasResumo').then(function (fichas) {
      _this.fichas = fichas;
    });
    this.pieData = this.pieChartsData[subdomain];
    this.lineData = this.lineChartsData[subdomain];
    this.barData = this.barChartsData[subdomain];
    this.lineData2 = this.lineChartsData2[subdomain];
    this.lineData1 = this.lineChartsData1[subdomain];
    this.$store.dispatch('stats/getMapa').then(function (mapa) {
      var heatMap = mapa.filter(function (item) {
        return item != null && item.lat != null && item.lng != null;
      }).map(function (item) {
        return [item.lat, item.lng, 300];
      });
      _this.mapaAtual = mapa || [];
      _this.heatMapLayer = L.heatLayer(heatMap, {
        radius: 10
      }).addTo(_this.$refs.map.mapObject);
    }).finally(function () {
      _this.isLoading = false;
    }); // this.$store.dispatch("stats/getCasos").then( casosResumo => {
    //   this.casosResumo = casosResumo;
    // })
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    points: function points(state) {
      return state.stats.mapa || [];
    }
  })), (0, _vuex.mapGetters)(['secretaria'])),
  methods: {
    distancia: function distancia(ponto1, ponto2) {
      var deg2rad = function deg2rad(deg) {
        return deg * (Math.PI / 180);
      };

      var R = 6371;
      var dLat = deg2rad(ponto2[0] - ponto1[0]);
      var dLng = deg2rad(ponto2[1] - ponto1[1]);
      var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(ponto1[0])) * Math.cos(deg2rad(ponto1[0])) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },
    onmousemove: function onmousemove(ev) {
      var x = ev.originalEvent.pageX;
      var y = ev.originalEvent.pageY;
      this.tooltip = true;
      var tooltipElement = document.querySelector('.el-tooltip__popper.is-dark');

      if (tooltipElement) {
        var transl = 'translate(' + (x + 10) + 'px, ' + (y + 10) + 'px)';
        tooltipElement.style.top = '0px';
        tooltipElement.style.left = '0px';
        tooltipElement.style.webkitTransform = transl;
        this.atualizarLabel([ev.latlng.lat, ev.latlng.lng]);
      }
    },
    atualizarLabel: function atualizarLabel(ponto) {
      var _this2 = this;

      var bairrosProximos = this.mapaAtual.filter(function (bairro) {
        return _this2.distancia(ponto, [bairro.lat, bairro.lng]) <= 1;
      });

      if (bairrosProximos.length > 0) {
        bairrosProximos = bairrosProximos.map(function (bairro) {
          return _objectSpread(_objectSpread({}, bairro), {}, {
            distancia: _this2.distancia(ponto, [bairro.lat, bairro.lng])
          });
        });
        bairrosProximos = bairrosProximos.sort(function (a, b) {
          return a.distancia < b.distancia ? -1 : 1;
        });
        this.label = "".concat(bairrosProximos[0].value);
      } else {
        this.label = '0';
      }
    },
    handleSetLineChartData: function handleSetLineChartData(type) {
      // this.selected_condition = type
      this.lineChartData = lineChartData[type];
    },
    zoomUpdated: function zoomUpdated(zoom) {
      this.tooltip = false;
      this.zoom = zoom;
    },
    // centerUpdated(center) {
    //   this.center = center;
    // },
    boundsUpdated: function boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    updatedSelectedItems: function updatedSelectedItems(grupo_selecionado) {
      var keys = Object.keys(this.points[0]);

      if (grupo_selecionado == 'grupo-risco') {
        this.filtro_mapa_calor = this.filtro_mapa_calor.filter(function (item) {
          return !item.startsWith('st_');
        });
      }

      if (grupo_selecionado == 'casos-covid-19') {
        this.filtro_mapa_calor = this.filtro_mapa_calor.filter(function (item) {
          return !item.startsWith('caso_');
        });
      }

      if (grupo_selecionado == 'usuario') {
        this.filtro_mapa_calor = this.filtro_mapa_calor.filter(function (item) {
          return item != 'agente' && item != 'cidadao';
        });
      }

      if (this.filtro_mapa_calor.indexOf('grupo-risco') >= 0) {
        var _keys = Object.keys(this.points[0]['condicoes']);

        this.filtro_mapa_calor = this.filtro_mapa_calor.concat(_keys.filter(function (item) {
          return item.startsWith('st_');
        }));
      }

      if (this.filtro_mapa_calor.indexOf('casos-covid-19') >= 0) {
        var casos = ['confirmado', 'suspeito', 'recuperado', 'obito'];
        this.filtro_mapa_calor = this.filtro_mapa_calor.concat(casos);
      }

      if (this.filtro_mapa_calor.indexOf('usuario') >= 0) {
        this.filtro_mapa_calor = this.filtro_mapa_calor.concat(['agente', 'cidadao']);
      }
    },
    filter: function filter(categorias_selecionadas) {
      var points = this.points;

      if (categorias_selecionadas.length > 0) {
        points = points.filter(function (item) {
          if (item.categoria == 'grupo_de_risco') {
            for (var index = 0; index < categorias_selecionadas.length; index++) {
              var key = categorias_selecionadas[index];

              if (item.hasOwnProperty('condicoes') && item['condicoes'].hasOwnProperty(key) && item['condicoes'][key]['status'] == 1) {
                return true;
              }
            }
          } else if (item.categoria == 'caso') {
            if (categorias_selecionadas.indexOf(item.value) >= 0 && item.lat != null && item.lng != null) {
              return true;
            }
          }

          return false; // categorias_selecionadas.indexOf(item.categoria) >= 0 ||
          // categorias_selecionadas.indexOf(item.subcategoria) >= 0
        });
      } else {
        points = [];
      }

      var heatMap = points.map(function (item) {
        return [item.lat, item.lng, 300];
      }); // if (heatMap.length > 0) {
      //   this.centerUpdated([
      //     heatMap.reduce((a, item) => a + item[0], 0) / heatMap.length,
      //     heatMap.reduce((a, item) => a + item[1], 0) / heatMap.length
      //   ]);
      // }

      this.mapaAtual = points;
      this.heatMapLayer.setLatLngs(heatMap);
    },
    // handleMap() {
    //   var heat = L.heatLayer(
    //     [
    //       [50.5, 30.5, 0.2], // lat, lng, intensity
    //       [50.6, 30.4, 0.5]
    //     ],
    //     { radius: 25 }
    //   ).addTo(map);
    // },
    handleData: function handleData() {
      var _this3 = this;

      this.isLoading = true;
      this.$store.dispatch('stats/getGrupoRisco', this.selected_condition).then(function (response) {
        _this3.mapsData = response;

        for (var key in _this3.mapsData) {
          if (_this3.mapsData.hasOwnProperty(key)) {
            // const element = response[key];
            _this3.getMunicipiosCoords(key);
          }
        } // let tempArray = []
        // for (let index = 0; index < 30; index++) {
        //   const element = response[index];
        //   const input = element['municipio'] + ' Northeast Region, Brazil'
        //   console.log(input)
        //   const results = provider.search({ query: input }).then(results => {
        //     tempArray.push({
        //       'municipio' : element['municipio'],
        //       'lat': results[0].y,
        //       'lon': results[0].x,
        //       'center': latLng(results[0].y,results[0].x),
        //       'label': results[0].label,
        //       'cidadaos': element['cidadaos'],
        //       'radius': 10 * element['cidadaos']
        //       })
        //       // this.cities_condition[this.selected_condition] = tempArray
        //     console.log(results);
        //   })
        // }
        // this.$set(this.cities_condition, this.selected_condition, tempArray)


        _this3.isLoading = false;
      }).catch(function (err) {
        console.log(err);
      });
    },
    getLatLong: function getLatLong(localidade) {
      // console.log(this.isConsultingAddress)
      // while (this.isConsultingAddress == true) {
      //   // console.log("waiting")
      // }
      // if (!this.latLngCities.hasOwnProperty(localidade.cod)) {
      //   this.isConsultingAddress = true
      //   console.log(localidade.cod)
      //   const input = localidade.name + ' Northeast Region, Brazil'
      // return provider.search({ query: input }).then(results => {
      //       this.$set(this.latLngCities, localidade.cod, { 'lat': results[0].y, 'lng': results[0].x})
      //       this.isConsultingAddress = false
      //       return { 'lat': results[0].y, 'lng': results[0].x}
      //     })
      // }
      for (var index = 0; index < this.geoData.length; index++) {
        if (this.geoData[index].cod_localidade == localidade.cod) {
          return this.geoData[index];
        }
      }

      return null;
    },
    getMunicipiosCoords: function getMunicipiosCoords(pergunta_id) {
      console.log('entrou');
      console.log(pergunta_id);
      var data = this.mapsData[pergunta_id];
      console.log(this.mapsData);
      var tempArray = [];

      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          var element = data[key];
          var coordObj = this.getLatLong({
            cod: key,
            name: element.nome_localidade
          });

          if (coordObj != null) {
            if (element.hasOwnProperty('sim')) {
              tempArray.push([coordObj.lat, coordObj.lng, element['sim']]);
            }
          } // const input = element['nome_localidade'] + ' Northeast Region, Brazil'
          // console.log(input)
          // const results = provider.search({ query: input }).then(results => {
          //   tempArray.push([results[0].y, results[0].x, element['cidadaos']])
          //     // this.cities_condition[this.selected_condition] = tempArray
          // })

        }
      }

      this.$set(this.cities_condition, pergunta_id, tempArray);
      console.log('saiu');
    }
  },
  watch: {
    filtro_mapa_calor: function filtro_mapa_calor(value, oldValue) {
      if (value !== oldValue) {
        this.filter(value);
      }
    }
  }
};
exports.default = _default;