var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainarelatoriovaliacoescontainerpage" } },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [
              _vm._v("Relatório de Avaliações"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("strong", [_vm._v("Filtros")]),
                    _c("img", {
                      staticStyle: {
                        width: "1.5rem",
                        float: "right",
                        "margin-left": "0.5rem",
                      },
                      attrs: { src: require("@/assets/images/icon-pdf.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          float: "right",
                          padding: "3px 0",
                          "font-size": "14px",
                          "font-weight": "bold",
                        },
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.dadosSelected,
                        },
                        on: { click: _vm.generatePdf },
                      },
                      [_vm._v("Gerar PDF")]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "1.5rem",
                        float: "right",
                        "margin-right": "3rem",
                        "margin-left": "0.5rem",
                      },
                      attrs: { src: require("@/assets/images/icon-excel.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          float: "right",
                          padding: "3px 0",
                          "font-size": "14px",
                          "font-weight": "bold",
                        },
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.dadosSelected,
                        },
                        on: { click: _vm.generateExcel },
                      },
                      [_vm._v("Gerar Excel")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-style" },
                  [
                    _c(
                      "el-form",
                      { staticClass: "form-style", attrs: { inline: true } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Risco")]),
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "Risco" },
                                model: {
                                  value: _vm.filtros.risco,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "risco", $$v)
                                  },
                                  expression: "filtros.risco",
                                },
                              },
                              _vm._l(_vm.riscos, function (risco, i) {
                                return _c("el-option", {
                                  attrs: {
                                    label: risco.label,
                                    value: risco.value,
                                    kei: i,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Período de")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Período de:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_inicial,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "data_inicial", $$v)
                                },
                                expression: "filtros.data_inicial",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Período até")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Período até:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_final,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "data_final", $$v)
                                },
                                expression: "filtros.data_final",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("br", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.perfil.tipo_instituicao != "EMPRESA",
                              expression:
                                "perfil.tipo_instituicao != 'EMPRESA'",
                            },
                          ],
                        }),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Bairro")]),
                            _c("el-input", {
                              attrs: { placeholder: "Bairro" },
                              model: {
                                value: _vm.filtros.bairro,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "bairro", $$v)
                                },
                                expression: "filtros.bairro",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.perfil.tipo_instituicao != "EMPRESA",
                                expression:
                                  "perfil.tipo_instituicao != 'EMPRESA'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Código ESUS")]),
                            _c("el-input", {
                              attrs: {
                                placeholder: "Código esus",
                                type: "number",
                              },
                              model: {
                                value: _vm.filtros.cod_cidadao_esus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "cod_cidadao_esus", $$v)
                                },
                                expression: "filtros.cod_cidadao_esus",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tags-filters-style" },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("Filtrar por: ")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.data_inicial == "" &&
                                      _vm.filtros.cod_cidadao_esus == "" &&
                                      _vm.filtros.data_final == "" &&
                                      _vm.filtros.risco == "" &&
                                      _vm.filtros.bairro == "",
                                    expression:
                                      "(filtros.data_inicial == '') && (filtros.cod_cidadao_esus == '') && (filtros.data_final == '') && (filtros.risco == '') && (filtros.bairro == '')",
                                  },
                                ],
                              },
                              [_vm._v("Os seus filtros aparecerão aqui.")]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.risco != "",
                                    expression: "filtros.risco != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.risco)
                                  },
                                },
                              },
                              [_vm._v("Risco: " + _vm._s(_vm.filtros.risco))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.data_inicial != "",
                                    expression: "filtros.data_inicial != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_inicial
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Período de: " +
                                    _vm._s(_vm.filtros.data_inicial)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.data_final != "",
                                    expression: "filtros.data_final != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_final
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Período até: " +
                                    _vm._s(_vm.filtros.data_final)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.cod_cidadao_esus != "",
                                    expression:
                                      "filtros.cod_cidadao_esus != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.cod_cidadao_esus
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Cód. Esus: " +
                                    _vm._s(_vm.filtros.cod_cidadao_esus)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.bairro != "",
                                    expression: "filtros.bairro != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.bairro)
                                  },
                                },
                              },
                              [_vm._v("Bairro: " + _vm._s(_vm.filtros.bairro))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.zona != "",
                                    expression: "filtros.zona != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.zona)
                                  },
                                },
                              },
                              [_vm._v("Zona: " + _vm._s(_vm.filtros.zona))]
                            ),
                          ],
                          1
                        ),
                        _c("el-alert", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errorResult == true,
                              expression: "errorResult == true",
                            },
                          ],
                          staticStyle: { margin: "1rem" },
                          attrs: {
                            title:
                              "Ocorreu um erro ao buscar dados no servidor.",
                            type: "error",
                            "show-icon": "",
                          },
                        }),
                        _c(
                          "el-form-item",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "medium",
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.cleanFilters },
                              },
                              [_vm._v("Limpar")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.sending,
                                  disabled: _vm.sending,
                                  size: "medium",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.filter },
                              },
                              [_vm._v("Filtrar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex" },
            },
            [
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.qtd_formularios_respondidos.label)
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content-card",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.qtd_formularios_respondidos.value)
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.valor_medio_respostas[2].label)
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content-card",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.valor_medio_respostas[2].value)
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.resultados.riscos.label) + "(%)"),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "content-card" }, [
                  _c("img", {
                    staticStyle: { width: "20px" },
                    attrs: {
                      src: require("@/assets/images/downloaded_greenflag.svg"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "Baixo: " +
                        _vm._s(_vm.resultados.riscos.total_risco_baixo) +
                        "%"
                    ),
                  ]),
                  _c("img", {
                    staticStyle: { width: "20px", "margin-left": "1rem" },
                    attrs: {
                      src: require("@/assets/images/downloaded_yellowflag.svg"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "Médio: " +
                        _vm._s(_vm.resultados.riscos.total_risco_medio) +
                        "%"
                    ),
                  ]),
                  _c("img", {
                    staticStyle: { width: "20px", "margin-left": "1rem" },
                    attrs: {
                      src: require("@/assets/images/downloaded_redflag.svg"),
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "Alto: " +
                        _vm._s(_vm.resultados.riscos.total_risco_alto) +
                        "%"
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected && !_vm.dadosSelectedStatusRisco,
                  expression: "dadosSelected && !dadosSelectedStatusRisco",
                },
              ],
              staticClass: "row-bg charts-style",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dadosSelected && !_vm.dadosSelectedStatusRisco,
                      expression: "dadosSelected && !dadosSelectedStatusRisco",
                    },
                  ],
                  attrs: { xs: 24, sm: 24, lg: 12 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Riscos (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieGraph,
                        attrs: { data: _vm.pieData, nameGraph: "Riscos" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dadosSelected,
                      expression: "dadosSelected",
                    },
                  ],
                  attrs: { xs: 24, sm: 24, lg: 12 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Sexo (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieGraphSexo,
                        attrs: { data: _vm.pieDataSexo, nameGraph: "Sexo" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected && _vm.dadosSelectedStatusRisco,
                  expression: "dadosSelected && dadosSelectedStatusRisco",
                },
              ],
              staticClass: "row-bg charts-style",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dadosSelected && _vm.dadosSelectedStatusRisco,
                      expression: "dadosSelected && dadosSelectedStatusRisco",
                    },
                  ],
                  attrs: { xs: 32, sm: 32, lg: 32 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Sexo (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieGraphSexo,
                        attrs: { data: _vm.pieDataSexo, nameGraph: "Sexo" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex", gutter: 32 },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dadosSelected,
                      expression: "dadosSelected",
                    },
                  ],
                  attrs: { xs: 32, sm: 24, lg: 24, offset: 0 },
                },
                _vm._l(_vm.resultados.valor_medio_respostas, function (item) {
                  return _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dadosSelected,
                          expression: "dadosSelected",
                        },
                      ],
                      key: item.key,
                      staticClass: "box-card-estatisticas",
                    },
                    [
                      _vm.dadosSelected
                        ? _c(
                            "el-row",
                            { staticClass: "row-style", attrs: { gutter: 32 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "title-col",
                                  attrs: { xs: 14, sm: 14, lg: 14, offset: 0 },
                                },
                                [_vm._v(_vm._s(item.label))]
                              ),
                              _c(
                                "el-col",
                                { attrs: { xs: 6, sm: 6, lg: 6, offset: 0 } },
                                [_vm._v(_vm._s(item.value))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex", gutter: 32 } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dadosSelected,
                      expression: "dadosSelected",
                    },
                  ],
                  staticClass: "table-style",
                  attrs: { data: _vm.dados },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "status_risco", label: "RISCO" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.dados[scope.$index].status_risco ===
                            "risco_alto"
                              ? _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-right": "1rem",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/downloaded_redflag.svg"),
                                  },
                                })
                              : _vm._e(),
                            _vm.dados[scope.$index].status_risco ===
                            "risco_baixo"
                              ? _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-right": "1rem",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/downloaded_greenflag.svg"),
                                  },
                                })
                              : _vm._e(),
                            _vm.dados[scope.$index].status_risco ===
                            "risco_medio"
                              ? _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    "margin-right": "1rem",
                                  },
                                  attrs: {
                                    src: require("@/assets/images/downloaded_yellowflag.svg"),
                                  },
                                })
                              : _vm._e(),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  {
                                    risco_baixo: "Baixo",
                                    risco_medio: "Médio",
                                    risco_alto: "Alto",
                                  }[_vm.dados[scope.$index].status_risco]
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "perfil_cidadao.nome_completo",
                      label: "NOME",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "perfil_cidadao.celular", label: "CONTATO" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "criado_em", label: "FEITA EM" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .moment(_vm.dados[scope.$index].criado_em)
                                  .format("DD/MM/YYYY")
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cod_cidadao_esus", label: "CÓD ESUS" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }