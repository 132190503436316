"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeStatusCaso = changeStatusCaso;
exports.filterCasos = filterCasos;
exports.listaCasos = listaCasos;
exports.newCaso = newCaso;

var _request = _interopRequireDefault(require("@/utils/request"));

function newCaso(data) {
  return (0, _request.default)({
    url: '/casos/reportados/',
    method: 'post',
    data: data
  });
}

function listaCasos() {
  return (0, _request.default)({
    url: '/casos/reportados/',
    method: 'get'
  });
}

function changeStatusCaso(status, id) {
  return (0, _request.default)({
    url: '/casos/reportados/' + id + '/',
    method: 'put',
    'data': {
      status: status
    }
  });
}

function filterCasos(data) {
  return (0, _request.default)({
    url: '/casos/reportados/?' + data,
    method: 'get'
  });
}