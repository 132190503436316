var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container documentation-container" },
    [
      _c(
        "a",
        {
          staticClass: "document-btn",
          attrs: {
            target: "_blank",
            href: "https://panjiachen.github.io/vue-element-admin-site/",
          },
        },
        [_vm._v("Documentation")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "document-btn",
          attrs: {
            target: "_blank",
            href: "https://github.com/PanJiaChen/vue-element-admin/",
          },
        },
        [_vm._v("Github Repository")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "document-btn",
          attrs: {
            target: "_blank",
            href: "https://panjiachen.gitee.io/vue-element-admin-site/zh/",
          },
        },
        [_vm._v("国内文档")]
      ),
      _vm._v(" "),
      _c("dropdown-menu", {
        staticClass: "document-btn",
        attrs: { items: _vm.articleList, title: "系列文章" },
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "document-btn",
          attrs: {
            target: "_blank",
            href: "https://panjiachen.github.io/vue-element-admin-site/zh/job/",
          },
        },
        [_vm._v("内推招聘")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }