"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _echarts = _interopRequireDefault(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

//
//
//
//
require('echarts/theme/infographic'); // echarts theme


var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    data: {
      type: Array
    },
    nameGraph: {
      type: String
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'infographic');
      var legendas = [];

      for (var i = 0; i < this.data.length; i++) {
        legendas.push(this.data[i].name);
      }

      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: legendas
        },
        series: [{
          name: this.nameGraph,
          type: 'pie',
          roseType: 'radius',
          radius: [15, 95],
          center: ['50%', '38%'],
          data: this.data,
          animationEasing: 'cubicInOut',
          animationDuration: 2600
        }]
      });
    }
  }
};
exports.default = _default;