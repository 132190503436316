"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("@/utils");

require("@/assets/custom-theme/index.css");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// the theme changed version element-ui css
var _default = {
  name: 'Theme',
  data: function data() {
    return {
      theme: false,
      tags: [{
        name: 'Tag One',
        type: ''
      }, {
        name: 'Tag Two',
        type: 'info'
      }, {
        name: 'Tag Three',
        type: 'success'
      }, {
        name: 'Tag Four',
        type: 'warning'
      }, {
        name: 'Tag Five',
        type: 'danger'
      }],
      slideValue: 50,
      radio: 3
    };
  },
  watch: {
    theme: function theme() {
      (0, _utils.toggleClass)(document.body, 'custom-theme');
    }
  }
};
exports.default = _default;