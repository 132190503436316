"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.altaPaciente = altaPaciente;
exports.editPaciente = editPaciente;
exports.filterPacientes = filterPacientes;
exports.getPaciente = getPaciente;
exports.listPacientes = listPacientes;
exports.newPaciente = newPaciente;

var _request = _interopRequireDefault(require("@/utils/request"));

function newPaciente(data) {
  return (0, _request.default)({
    url: '/internacoes/pacientes/',
    method: 'post',
    data: data
  });
}

function getPaciente(id) {
  return (0, _request.default)({
    url: '/internacoes/pacientes/' + id,
    method: 'get'
  });
}

function listPacientes() {
  return (0, _request.default)({
    url: '/internacoes/pacientes/',
    method: 'get'
  });
}

function editPaciente(data, id) {
  return (0, _request.default)({
    url: '/internacoes/pacientes/' + id,
    method: 'patch',
    data: data
  });
}

function filterPacientes(data) {
  return (0, _request.default)({
    url: '/internacoes/pacientes/?' + data,
    method: 'get'
  });
}

function altaPaciente(data) {
  return _request.default.post("/internacoes/pacientes/".concat(data.id, "/alta/"), data);
}