var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "recados-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.modalVisible,
            width: "50%",
            center: "",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalVisible = $event
            },
          },
        },
        [
          _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("h1", { staticClass: "recado-title" }, [
                  _vm._v(_vm._s(_vm.recadoSelecionado.nome_autor)),
                ]),
              ]
            ),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  { staticClass: "row-style", attrs: { gutter: 32 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "title-col",
                        attrs: { xs: 12, sm: 12, lg: 12, offset: 0 },
                      },
                      [_vm._v("Nome do funcionário")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } },
                      [_vm._v(_vm._s(_vm.recadoSelecionado.nome_completo))]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 32 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "title-col",
                        attrs: { xs: 12, sm: 12, lg: 12, offset: 0 },
                      },
                      [_vm._v("Setor")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } },
                      [_vm._v(_vm._s(_vm.recadoSelecionado.setor))]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "row-style", attrs: { gutter: 32 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "title-col",
                        attrs: { xs: 12, sm: 12, lg: 12, offset: 0 },
                      },
                      [_vm._v("Título")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } },
                      [_vm._v(_vm._s(_vm.recadoSelecionado.titulo))]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 32 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "title-col",
                        attrs: { xs: 12, sm: 12, lg: 12, offset: 0 },
                      },
                      [_vm._v("Data de criação")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.recadoSelecionado.criado_em.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "row-style", attrs: { gutter: 32 } },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "title-col",
                        attrs: { xs: 12, sm: 12, lg: 12, offset: 0 },
                      },
                      [_vm._v("Conteúdo")]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } },
                      [_vm._v(_vm._s(_vm.recadoSelecionado.conteudo))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("h1", { staticClass: "recado-title" }, [_vm._v("Recados")]),
          ]),
          _c(
            "el-main",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                    [
                      _c("el-card", { staticClass: "box-card-results" }, [
                        _c(
                          "div",
                          {
                            staticClass: "title-card",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [_c("span", [_vm._v("Nº DE RECADOS")])]
                        ),
                        _c("div", { staticClass: "content-card" }, [
                          _c("span", [_vm._v(_vm._s(_vm.listaRecados.length))]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                _vm._l(_vm.listaRecados, function (item) {
                  return _c("el-card", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.mostrarRecado(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "card-title",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [_c("h3", [_vm._v(_vm._s(item.autor.nome))])]
                        ),
                        _c(
                          "div",
                          { staticClass: "card-content" },
                          [
                            _c("el-col", { attrs: { xs: 8, sm: 8, lg: 4 } }, [
                              _c("div", { staticClass: "data-style" }, [
                                _c(
                                  "h3",
                                  [
                                    void 0,
                                    _vm._v(
                                      _vm._s(
                                        item.criado_em.replace(
                                          /(\d{4})-(\d{2})-(\d{2})/,
                                          "$3/$2/$1"
                                        )
                                      )
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                            _c(
                              "el-col",
                              { attrs: { xs: 16, sm: 16, lg: 20 } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(item.conteudo.slice(0, 80)) + " ..."
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }