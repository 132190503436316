var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "pageeditarsetormaincontainer" } },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.editar == "setor",
                    expression: "editar == 'setor'",
                  },
                ],
                staticClass: "title",
              },
              [
                _vm._v(
                  "Editar setor: " + _vm._s(_vm.setor ? _vm.setor.nome : "")
                ),
              ]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.editar == "funcionario",
                    expression: "editar == 'funcionario'",
                  },
                ],
                staticClass: "title",
              },
              [
                _vm._v(
                  "Editar funcionário: " +
                    _vm._s(_vm.funcionario ? _vm.funcionario.nome_completo : "")
                ),
              ]
            ),
          ]),
          _c(
            "el-main",
            [
              _vm.setor
                ? _c("formSetor", { attrs: { setor: _vm.setor, edit: true } })
                : _vm._e(),
              _vm.funcionario
                ? _c("formFuncionario", {
                    attrs: { funcionario: _vm.funcionario, edit: true },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }