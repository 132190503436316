"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.string.starts-with");

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  components: {
    CountTo: _vueCountTo.default
  },
  data: function data() {
    return {
      isLoading: true,
      filtros: {
        tipobusca: '',
        nome: '',
        cpf: ''
      },
      barreirasPerPage: 20,
      currentPage: 1
    };
  },
  created: function created() {
    var _this = this;

    this.isLoading = true;
    this.listBarreiras().catch(function (err) {
      console.log("error", err);
      console.log("error", err.response);
    }).finally(function (rr) {
      _this.isLoading = false;
    });
    this.listEstados().catch(function (err) {
      console.log("error", err);
      console.log("error", err.response);
    });
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    count: function count(state) {
      return state.barreiras.count;
    },
    next: function next(state) {
      return state.barreiras.next;
    },
    previous: function previous(state) {
      return state.barreiras.previous;
    },
    results: function results(state) {
      return state.barreiras.results;
    },
    estados: function estados(state) {
      return state.barreiras.estados;
    },
    cidadesOrigem: function cidadesOrigem(state) {
      return state.barreiras.cidadesOrigem;
    },
    cidadesDestino: function cidadesDestino(state) {
      return state.barreiras.cidadesDestino;
    },
    origem: function origem(state) {
      return state.barreiras.origem;
    },
    destino: function destino(state) {
      return state.barreiras.destino;
    }
  })), {}, {
    barreirasFiltered: function barreirasFiltered() {
      var self = this;
      return this.results.filter(function (a) {
        //return self.filtros.bairro === '' || a.bairro.toLowerCase().startsWith(self.filtros.bairro.toLowerCase())
        return self.filtros.nome === '' || a.nome.toLowerCase().indexOf(self.filtros.nome.toLowerCase()) !== -1;
      }).filter(function (a) {
        if (a.cpf != null) {
          return self.filtros.tipobusca === 'cpf' ? a.cpf ? a.cpf.toString().startsWith(self.filtros.cpf.toString()) : true : true;
        } else {
          return true;
        }
      });
    },
    barreirasPaginated: function barreirasPaginated() {
      return this.barreirasFiltered.slice((this.currentPage - 1) * this.barreirasPerPage, this.currentPage * this.barreirasPerPage);
    }
  }),
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapActions)({
    listBarreiras: "barreiras/list",
    listEstados: "barreiras/listEstados",
    listCidades: "barreiras/listCidades",
    selecionarEstado: "barreiras/selecionarEstado",
    selecionarCidade: "barreiras/selecionarCidade"
  })), {}, {
    casoAlerta: function casoAlerta(_ref) {
      var row = _ref.row,
          rowIndex = _ref.rowIndex;

      if (row.alerta) {
        return "warning-row";
      }

      return "";
    },
    changePage: function changePage(current) {
      this.currentPage = current;
    } // limparFiltros() {
    //   this.origem.estado = "";
    //   this.origem.cidade = "";
    //   this.destino.estado = "";
    //   this.destino.cidade = "";
    //   document.location.reload(true);
    // }

  }),
  // Watch
  watch: {
    tipoBusca: function tipoBusca() {
      this.filtros.nome = '';
      this.filtros.cpf = '';
    }
  }
};
exports.default = _default;