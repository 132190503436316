var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "sticky",
        { attrs: { "z-index": 10, "class-name": "sub-navbar" } },
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" } },
            [
              _c("el-button", { attrs: { plain: "" } }, [
                _vm._v("\n        Platform"),
                _c("i", { staticClass: "el-icon-caret-bottom el-icon--right" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "no-border",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticStyle: { padding: "5px 15px" },
                      model: {
                        value: _vm.platforms,
                        callback: function ($$v) {
                          _vm.platforms = $$v
                        },
                        expression: "platforms",
                      },
                    },
                    _vm._l(_vm.platformsOptions, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item.key, attrs: { label: item.key } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" } },
            [
              _c("el-button", { attrs: { plain: "" } }, [
                _vm._v("\n        Link"),
                _c("i", { staticClass: "el-icon-caret-bottom el-icon--right" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "no-padding no-border",
                  staticStyle: { width: "300px" },
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "Please enter the content" },
                      model: {
                        value: _vm.url,
                        callback: function ($$v) {
                          _vm.url = $$v
                        },
                        expression: "url",
                      },
                    },
                    [
                      _c("template", { slot: "prepend" }, [
                        _vm._v("\n            Url\n          "),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "time-container" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  format: "yyyy-MM-dd HH:mm:ss",
                  placeholder: "Release time",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "success" },
            },
            [_vm._v("\n      publish\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "components-container" },
        [
          _c("aside", [
            _vm._v(
              "\n      Sticky header, When the page is scrolled to the preset position will be sticky on the top.\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c(
            "sticky",
            { attrs: { "sticky-top": 200 } },
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v(" placeholder"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
          _vm._v(" "),
          _c("div", [_vm._v("placeholder")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }