"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
var _default = {
  created: function created() {
    this.b = b; // eslint-disable-line
  }
};
exports.default = _default;