"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _formagente = _interopRequireDefault(require("./formagente"));

var _agente = require("@/api/agente");

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'pageEditarAgente',
  components: {
    formagente: _formagente.default
  },
  data: function data() {
    return {
      agente: null
    };
  },
  created: function created() {
    var _this = this;

    var self = this;
    (0, _agente.getAgenteById)(self.$route.params.id).then(function (r) {
      // console.log(r)
      _this.agente = r;
      console.log("Agente após getAgenteByID:  ", _this.agente);
    }).catch(function (e) {
      console.log(e);
    });
  }
};
exports.default = _default;