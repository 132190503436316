"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkUser = checkUser;
exports.getInfo = getInfo;
exports.getUser = getUser;
exports.listUsers = listUsers;
exports.login = login;
exports.logout = logout;
exports.logsAccess = logsAccess;
exports.passwordReset = passwordReset;
exports.passwordResetConfirm = passwordResetConfirm;

var _request = _interopRequireDefault(require("@/utils/request"));

function logsAccess(data) {
  return (0, _request.default)({
    url: "/logging/logs/?" + data,
    method: 'get'
  });
}

function listUsers() {
  return (0, _request.default)({
    url: "/auth/users/",
    method: 'get'
  });
}

function login(data) {
  return (0, _request.default)({
    url: '/auth/token/login/',
    method: 'post',
    data: data
  });
}

function checkUser(cpf) {
  return (0, _request.default)({
    url: "/usuarios/check/cpf/".concat(cpf, "/"),
    method: 'get'
  });
}

function getInfo(token) {
  return (0, _request.default)({
    url: '/auth/users/me/',
    method: 'get'
  });
}

function logout() {
  return (0, _request.default)({
    url: '/auth/token/logout',
    method: 'post'
  });
}

function getUser(id) {
  return (0, _request.default)({
    url: '/auth/users/' + id,
    method: 'get'
  });
}

function passwordReset(data) {
  return (0, _request.default)({
    url: 'autenticacao/users/reset_password/',
    method: 'post',
    data: data
  });
}

function passwordResetConfirm(data) {
  return (0, _request.default)({
    url: 'auth/users/reset_password_confirm/',
    method: 'post',
    data: data
  });
}