"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPostById = getPostById;
exports.listPosts = listPosts;
exports.listPostsNoticia = listPostsNoticia;
exports.listPostsSobre = listPostsSobre;
exports.newPost = newPost;
exports.updatePost = updatePost;

var _request = _interopRequireDefault(require("@/utils/request"));

function newPost(data) {
  return (0, _request.default)({
    url: '/cms/posts/',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

function updatePost(data, id) {
  return (0, _request.default)({
    url: '/cms/posts/' + id,
    method: 'patch',
    data: data
  });
}

function getPostById(id) {
  return (0, _request.default)({
    url: '/cms/posts/' + id,
    method: 'get'
  });
}

function listPosts() {
  return (0, _request.default)({
    url: '/cms/posts/',
    method: 'get'
  });
}

function listPostsSobre() {
  return (0, _request.default)({
    url: '/cms/posts/?tipo=sobre',
    method: 'get'
  });
}

function listPostsNoticia() {
  return (0, _request.default)({
    url: '/cms/posts/',
    method: 'get'
  });
}