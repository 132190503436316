"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _formSetor = _interopRequireDefault(require("./formSetor"));

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "viewCadastroSetor",
  components: {
    formSetor: _formSetor.default
  }
};
exports.default = _default;