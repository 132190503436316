"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    CountTo: _vueCountTo.default
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type);
    }
  },
  props: {
    data: Object
  }
};
exports.default = _default;