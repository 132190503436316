"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRole = getRole;
exports.getToken = getToken;
exports.removeRole = removeRole;
exports.removeToken = removeToken;
exports.setRole = setRole;
exports.setToken = setToken;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'Admin-Token';
var RoleKey = 'Role';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}

function getRole() {
  return _jsCookie.default.get(RoleKey);
}

function setRole(role) {
  return _jsCookie.default.set(RoleKey, role);
}

function removeRole() {
  return _jsCookie.default.remove(RoleKey);
}