"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'tabComponent',
  props: ['showclosebutton', 'tabcolor', 'tabname', 'tabwidth', 'alert', 'maximized'],
  data: function data() {
    return {// maximized: true
    };
  },
  methods: {
    minmax: function minmax() {
      this.$emit('minmax'); // this.maximized = !this.maximized
    }
  }
};
exports.default = _default;