var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "container-centralCasoCadastro" } },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [
              _vm._v("Cadastro de Novo Caso"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              { attrs: { id: "formcadastrocasocontainermain" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: false,
                      model: _vm.formData,
                      "label-width": "auto",
                      "label-position": "top",
                      size: "medium",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.perfil.st_escola,
                            expression: "perfil.st_escola",
                          },
                        ],
                      },
                      [
                        _c("span", [
                          _vm._v("Aluno ou funcionário"),
                          _c("span", [_vm._v("*")]),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "input-style",
                            attrs: { placeholder: "Selecione uma das opções" },
                            model: {
                              value: _vm.alunoOuFuncionario,
                              callback: function ($$v) {
                                _vm.alunoOuFuncionario = $$v
                              },
                              expression: "alunoOuFuncionario",
                            },
                          },
                          _vm._l(_vm.opcoesBusca, function (valor, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { label: valor.label, value: valor.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.alunoOuFuncionario == "funcionario" ||
                              _vm.perfil.st_escola == false,
                            expression:
                              "alunoOuFuncionario == 'funcionario' || perfil.st_escola == false",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("CPF"), _c("span", [_vm._v("*")])]),
                        _c(
                          "el-select",
                          {
                            staticClass: "input-style",
                            attrs: { placeholder: "CPF do funcionário" },
                            model: {
                              value: _vm.cpf,
                              callback: function ($$v) {
                                _vm.cpf = $$v
                              },
                              expression: "cpf",
                            },
                          },
                          _vm._l(_vm.funcionarios, function (valor, i) {
                            return _c("el-option", {
                              key: valor.usuario,
                              attrs: { label: valor.cpf, value: valor.cpf },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.alunoOuFuncionario == "aluno",
                            expression: "alunoOuFuncionario == 'aluno'",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("CPF"), _c("span", [_vm._v("*")])]),
                        _c(
                          "el-select",
                          {
                            staticClass: "input-style",
                            attrs: { placeholder: "CPF do aluno" },
                            model: {
                              value: _vm.cpf,
                              callback: function ($$v) {
                                _vm.cpf = $$v
                              },
                              expression: "cpf",
                            },
                          },
                          _vm._l(_vm.alunos, function (valor, i) {
                            return _c("el-option", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: valor.cpf,
                                  expression: "valor.cpf",
                                },
                              ],
                              key: valor.usuario,
                              attrs: { label: valor.cpf, value: valor.cpf },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.funcionarioSelecionado == true,
                            expression: "funcionarioSelecionado == true",
                          },
                        ],
                      },
                      [
                        _c("span", [
                          _vm._v("Nome do funcionário"),
                          _c("span", [_vm._v("*")]),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "input-style",
                            attrs: {
                              disabled: "",
                              placeholder: "Nome do funcionário",
                            },
                            model: {
                              value: _vm.formData.nome,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "nome", $$v)
                              },
                              expression: "formData.nome",
                            },
                          },
                          _vm._l(_vm.funcionarios, function (valor, i) {
                            return _c("el-option", {
                              key: valor.usuario,
                              attrs: {
                                label: valor.nome_completo,
                                value: valor.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.funcionarioSelecionado == true &&
                              _vm.alunoOuFuncionario == "funcionario",
                            expression:
                              "(funcionarioSelecionado == true) && (alunoOuFuncionario == 'funcionario')",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("Setor")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "input-style",
                            attrs: { disabled: "", placeholder: "Setor" },
                            model: {
                              value: _vm.formData.setor,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "setor", $$v)
                              },
                              expression: "formData.setor",
                            },
                          },
                          _vm._l(_vm.setores, function (valor, i) {
                            return _c("el-option", {
                              key: valor.id,
                              attrs: { label: valor.nome, value: valor.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.funcionarioSelecionado == true,
                            expression: "funcionarioSelecionado == true",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("Data de Nascimento")]),
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.formData.nascimento,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "nascimento", $$v)
                            },
                            expression: "formData.nascimento",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.funcionarioSelecionado == true,
                            expression: "funcionarioSelecionado == true",
                          },
                        ],
                      },
                      [
                        _c("span", [
                          _vm._v("Enfermidade"),
                          _c("span", [_vm._v("*")]),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "input-style",
                            attrs: { placeholder: "Enfermidade" },
                            model: {
                              value: _vm.formData.enfermidade,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "enfermidade", $$v)
                              },
                              expression: "formData.enfermidade",
                            },
                          },
                          _vm._l(_vm.enfermidades, function (valor, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: { label: valor.nome, value: valor.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.funcionarioSelecionado == true,
                            expression: "funcionarioSelecionado == true",
                          },
                        ],
                      },
                      [
                        _c("span", [
                          _vm._v("Situação - Covid"),
                          _c("span", [_vm._v("*")]),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "input-style",
                            attrs: { placeholder: "Situação" },
                            model: {
                              value: _vm.formData.situacao,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "situacao", $$v)
                              },
                              expression: "formData.situacao",
                            },
                          },
                          _vm._l(_vm.situacao, function (valor, i) {
                            return _c("el-option", {
                              key: valor.id,
                              attrs: { label: valor.nome, value: valor.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.funcionarioSelecionado == true,
                            expression: "funcionarioSelecionado == true",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("Atestado")]),
                        _c("div", { staticClass: "alertImageSize" }, [
                          _vm._v("Arquivos jpg/png de até 500kb"),
                        ]),
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action:
                                "https://jsonplaceholder.typicode.com/posts/",
                              "on-change": _vm.handleAvatarSuccess,
                              "show-file-list": false,
                              multiple: false,
                              "auto-upload": false,
                            },
                          },
                          [
                            _vm.formData.atestado
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.formData.atestado },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.error !== ""
                      ? _c("el-alert", {
                          attrs: {
                            title: _vm.error,
                            type: "error",
                            closable: false,
                          },
                        })
                      : _vm._e(),
                    _vm.success
                      ? _c("el-alert", {
                          attrs: {
                            title:
                              "Caso " +
                              (_vm.funcionario !== null
                                ? "atualizado"
                                : "criado") +
                              " com sucesso",
                            type: "success",
                            closable: false,
                          },
                        })
                      : _vm._e(),
                    _c("br"),
                    _c(
                      "el-form-item",
                      { staticClass: "buttons" },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.funcionarioSelecionado == true,
                                expression: "funcionarioSelecionado == true",
                              },
                            ],
                            attrs: {
                              type: "primary",
                              loading: _vm.sending,
                              disabled: _vm.sending,
                              size: "medium",
                            },
                            on: { click: _vm.submit },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.caso === null ? "Cadastrar" : "Salvar")
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }