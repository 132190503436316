"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCidadaoByCPF = getCidadaoByCPF;
exports.listaCidadaos = listaCidadaos;

var _request = _interopRequireDefault(require("@/utils/request"));

function listaCidadaos() {
  return (0, _request.default)({
    url: '/core/cidadaos/',
    method: 'get'
  });
}

function getCidadaoByCPF(cpf) {
  return (0, _request.default)({
    url: '/core/cidadao/cpf/' + cpf,
    method: 'get'
  });
}