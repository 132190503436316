"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterFuncionarios = filterFuncionarios;
exports.getFuncionarioByCPF = getFuncionarioByCPF;
exports.getFuncionarioById = getFuncionarioById;
exports.listFuncionarios = listFuncionarios;
exports.newFuncionario = newFuncionario;
exports.updateFuncionario = updateFuncionario;

var _request = _interopRequireDefault(require("@/utils/request"));

function newFuncionario(data) {
  return (0, _request.default)({
    url: '/core/funcionarios/',
    method: 'post',
    data: data
  });
}

function updateFuncionario(data, id) {
  return (0, _request.default)({
    url: '/core/funcionarios/' + id,
    method: 'patch',
    data: data
  });
}

function getFuncionarioById(id) {
  return (0, _request.default)({
    url: '/core/funcionarios/' + id,
    method: 'get'
  });
}

function listFuncionarios() {
  return (0, _request.default)({
    url: '/core/funcionarios/',
    method: 'get'
  });
}

function filterFuncionarios(data) {
  return (0, _request.default)({
    url: '/core/funcionarios/?' + data,
    method: 'get'
  });
}

function getFuncionarioByCPF(cpf) {
  return (0, _request.default)({
    url: '/core/funcionarios/cpf/' + cpf,
    method: 'get'
  });
}