"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _formpostagem = _interopRequireDefault(require("./formpostagem"));

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'viewNovaPostagem',
  components: {
    formpostagem: _formpostagem.default
  }
};
exports.default = _default;