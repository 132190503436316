"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _codemirror = _interopRequireDefault(require("codemirror"));

require("codemirror/addon/lint/lint.css");

require("codemirror/lib/codemirror.css");

require("codemirror/theme/rubyblue.css");

require("codemirror/mode/javascript/javascript");

require("codemirror/addon/lint/lint");

require("codemirror/addon/lint/json-lint");

//
//
//
//
//
//
require('script-loader!jsonlint');

var _default = {
  name: 'JsonEditor',

  /* eslint-disable vue/require-prop-types */
  props: ['value'],
  data: function data() {
    return {
      jsonEditor: false
    };
  },
  watch: {
    value: function value(_value) {
      var editorValue = this.jsonEditor.getValue();

      if (_value !== editorValue) {
        this.jsonEditor.setValue(JSON.stringify(this.value, null, 2));
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.jsonEditor = _codemirror.default.fromTextArea(this.$refs.textarea, {
      lineNumbers: true,
      mode: 'application/json',
      gutters: ['CodeMirror-lint-markers'],
      theme: 'rubyblue',
      lint: true
    });
    this.jsonEditor.setValue(JSON.stringify(this.value, null, 2));
    this.jsonEditor.on('change', function (cm) {
      _this.$emit('changed', cm.getValue());

      _this.$emit('input', cm.getValue());
    });
  },
  methods: {
    getValue: function getValue() {
      return this.jsonEditor.getValue();
    }
  }
};
exports.default = _default;