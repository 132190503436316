"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _PieChart = _interopRequireDefault(require("@/components/Charts/PieChart"));

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var _default = {
  components: {
    PieChart: _PieChart.default
  },
  data: function data() {
    return {
      filterSelectedStatus: false,
      data_hora_print: '',
      fullscreenLoading: true,
      title: '',
      dados: [],
      tp_relatorio: '',
      key: '',
      resultados: {
        riscos: {
          label: "Riscos",
          total_risco_baixo: 0,
          total_risco_medio: 0,
          total_risco_alto: 0
        },
        qtd_formularios_respondidos: {
          label: "Número de formulários respondidos",
          value: ""
        },
        valor_medio_respostas: [{
          label: "QTD MULHERES",
          tag: "feminino",
          value: 0
        }, {
          label: "QTD HOMENS",
          tag: "masculino",
          value: 0
        }, {
          label: "Média dos dias apresentando sintomas",
          tag: "sintomas_qtd_dias",
          value: 0
        }, {
          label: "GRÁVIDAS",
          tag: "gravidez",
          value: 0
        }, {
          label: "PARTOS",
          tag: "partos",
          value: 0
        }, {
          label: "VIAJOU RECENT",
          tag: "viajou_recentement",
          value: 0
        }, {
          label: "CONTATO CASO CONF",
          tag: "contato_com_caso_confirmado",
          value: 0
        }, {
          label: "QTD FUMANTES",
          tag: "fumante",
          value: 0
        }, {
          label: "FEBRE",
          tag: "sintomas_febre",
          value: 0
        }, {
          label: "DIFICULDADE RESPIR",
          tag: "sintomas_dificuldade_respiratoria",
          value: 0
        }, {
          label: "DOR GARGANTA",
          tag: "sintomas_dor_garganta",
          value: 0
        }, {
          label: "TOSSE SECA",
          tag: "sintomas_tosse_seca",
          value: 0
        }, {
          label: "DOR CABEÇA",
          tag: "sintomas_dor_de_cabeca",
          value: 0
        }, {
          label: "DOR CORPO",
          tag: "sintomas_dor_no_corpo",
          value: 0
        }, {
          label: "ESPIRROS",
          tag: "sintomas_espirros",
          value: 0
        }, {
          label: "DIABÉTICOS",
          tag: "doencas_diabetes",
          value: 0
        }, {
          label: "DOENÇAS RESPIRAT",
          tag: "doencas_respitaroria_ou_cronica",
          value: 0
        }, {
          label: "MED PRESSÃO",
          tag: "usa_medicacao_para_pressao",
          value: 0
        }, {
          label: "DOENÇA RENAL",
          tag: "doencas_renal_cronica",
          value: 0
        }, {
          label: "MEDICAÇÃO DEPRIME",
          tag: "medicacao_deprime_defesa_do_corpo",
          value: 0
        }, {
          label: "DIARREIA",
          tag: "sintomas_diarreia",
          value: 0
        }, {
          label: "OUTROS SINTOMAS",
          tag: "outros_sintomas_boca_ou_dedos_roxo",
          value: 0
        }]
      }
    };
  },
  created: function created() {
    this.title = this.$route.query.title;
    this.dados = JSON.parse(localStorage.getItem('filtro'));
    this.tp_relatorio = this.$route.query.relatorio;
    this.filterSelectedStatus = JSON.parse(localStorage.getItem('statusRisco'));

    if (this.tp_relatorio == 'indicadores') {
      this.key = this.dados[0].key;
    } else if (this.tp_relatorio == "avaliacoes") {
      this.resultados = JSON.parse(localStorage.getItem('results'));
    }

    console.log("Dados: ", this.dados);
    console.log("Tp INdi: ", this.key);
  },
  mounted: function mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      //document.title = this.title;
      document.title = "Expedido por: " + this.username;
      setTimeout(function () {
        _this.fullscreenLoading = false;

        _this.$nextTick(function () {
          window.print();
        });
      }, 5000);
    }
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['username', 'domain']))
};
exports.default = _default;