var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "Gerando o PDF..." },
    },
    [
      _c("div", { staticClass: "article__heading" }, [
        _c("div", { staticClass: "article__heading__title" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { color: "#ccc" } }),
      _vm._v(" "),
      _c("div", {
        ref: "content",
        staticClass: "node-article-content",
        domProps: { innerHTML: _vm._s(_vm.content) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }