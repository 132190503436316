"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _clipboard = _interopRequireDefault(require("./clipboard"));

var install = function install(Vue) {
  Vue.directive('Clipboard', _clipboard.default);
};

if (window.Vue) {
  window.clipboard = _clipboard.default;
  Vue.use(install); // eslint-disable-line
}

_clipboard.default.install = install;
var _default = _clipboard.default;
exports.default = _default;