var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "components-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("count-to", {
        ref: "example",
        staticClass: "example",
        attrs: {
          "start-val": _vm._startVal,
          "end-val": _vm._endVal,
          duration: _vm._duration,
          decimals: _vm._decimals,
          separator: _vm._separator,
          prefix: _vm._prefix,
          suffix: _vm._suffix,
          autoplay: false,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-left": "25%", "margin-top": "40px" } },
        [
          _c(
            "label",
            { staticClass: "label", attrs: { for: "startValInput" } },
            [
              _vm._v("startVal:\n      "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.setStartVal,
                    expression: "setStartVal",
                    modifiers: { number: true },
                  },
                ],
                attrs: { type: "number", name: "startValInput" },
                domProps: { value: _vm.setStartVal },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.setStartVal = _vm._n($event.target.value)
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("label", { staticClass: "label", attrs: { for: "endValInput" } }, [
            _vm._v("endVal:\n      "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.setEndVal,
                  expression: "setEndVal",
                  modifiers: { number: true },
                },
              ],
              attrs: { type: "number", name: "endVaInput" },
              domProps: { value: _vm.setEndVal },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.setEndVal = _vm._n($event.target.value)
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "label", attrs: { for: "durationInput" } },
            [
              _vm._v("duration:\n      "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.setDuration,
                    expression: "setDuration",
                    modifiers: { number: true },
                  },
                ],
                attrs: { type: "number", name: "durationInput" },
                domProps: { value: _vm.setDuration },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.setDuration = _vm._n($event.target.value)
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "startBtn example-btn", on: { click: _vm.start } },
            [_vm._v("\n      Start\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pause-resume-btn example-btn",
              on: { click: _vm.pauseResume },
            },
            [_vm._v("\n      pause/resume\n    ")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "label", attrs: { for: "decimalsInput" } },
            [
              _vm._v("decimals:\n      "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.setDecimals,
                    expression: "setDecimals",
                    modifiers: { number: true },
                  },
                ],
                attrs: { type: "number", name: "decimalsInput" },
                domProps: { value: _vm.setDecimals },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.setDecimals = _vm._n($event.target.value)
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "label", attrs: { for: "separatorInput" } },
            [
              _vm._v("separator:\n      "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.setSeparator,
                    expression: "setSeparator",
                  },
                ],
                attrs: { name: "separatorInput" },
                domProps: { value: _vm.setSeparator },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.setSeparator = $event.target.value
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("label", { staticClass: "label", attrs: { for: "prefixInput" } }, [
            _vm._v("prefix:\n      "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.setPrefix,
                  expression: "setPrefix",
                },
              ],
              attrs: { name: "prefixInput" },
              domProps: { value: _vm.setPrefix },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.setPrefix = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "label", attrs: { for: "suffixInput" } }, [
            _vm._v("suffix:\n      "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.setSuffix,
                  expression: "setSuffix",
                },
              ],
              attrs: { name: "suffixInput" },
              domProps: { value: _vm.setSuffix },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.setSuffix = $event.target.value
                },
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("aside", [
        _vm._v(
          "<count-to :start-val='" +
            _vm._s(_vm._startVal) +
            "' :end-val='" +
            _vm._s(_vm._endVal) +
            "' :duration='" +
            _vm._s(_vm._duration) +
            "'\n    :decimals='" +
            _vm._s(_vm._decimals) +
            "' :separator='" +
            _vm._s(_vm._separator) +
            "' :prefix='" +
            _vm._s(_vm._prefix) +
            "' :suffix='" +
            _vm._s(_vm._suffix) +
            "'\n    :autoplay=false>"
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/PanJiaChen/vue-countTo",
            target: "_blank",
          },
        },
        [_vm._v("countTo-component")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }