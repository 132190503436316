"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Keyboard = _interopRequireDefault(require("@/components/Charts/Keyboard"));

//
//
//
//
//
//
var _default = {
  name: 'KeyboardChart',
  components: {
    Chart: _Keyboard.default
  }
};
exports.default = _default;