var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "components-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "editor-container" },
      [
        _c("json-editor", {
          ref: "jsonEditor",
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("aside", [
      _vm._v("Json-Editor is base on "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/codemirror/CodeMirror",
            target: "_blank",
          },
        },
        [_vm._v("CodeMirrorr")]
      ),
      _vm._v(". Lint\n    base on "),
      _c(
        "a",
        {
          attrs: {
            href: "https://github.com/codemirror/CodeMirror/blob/master/addon/lint/json-lint.js",
            target: "_blank",
          },
        },
        [_vm._v("json-lint")]
      ),
      _vm._v("."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }