var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card" },
    [
      _c(
        "el-steps",
        {
          attrs: {
            "finish-status": "success",
            active: _vm.active,
            "align-center": "",
          },
        },
        [
          _c("el-step", { attrs: { title: "Dados Pessoais" } }),
          _c("el-step", { attrs: { title: "Endereço" } }),
          _c("el-step", { attrs: { title: "Autenticação" } }),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "formfuncionariocontainermain" } },
        [
          _c(
            "el-form",
            {
              attrs: {
                inline: false,
                model: _vm.formData,
                "label-width": "auto",
                "label-position": "top",
                size: "medium",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 0,
                      expression: "active === 0",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("Nome"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              regex:
                                "[0-9a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*",
                            },
                            expression:
                              "{'regex': '[0-9a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*'}",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: { placeholder: "Nome Completo", type: "text" },
                        model: {
                          value: _vm.formData.nome,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "nome", $$v)
                          },
                          expression: "formData.nome",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.edit,
                          expression: "!edit",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("E-mail"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: {
                          disabled: _vm.showPasswordInput === false,
                          placeholder: "Ex.: saic@saic.br",
                          type: "email",
                        },
                        model: {
                          value: _vm.formData.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "email", $$v)
                          },
                          expression: "formData.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("CPF"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "999.999.999-99",
                            expression: "'999.999.999-99'",
                          },
                        ],
                        attrs: {
                          disabled: _vm.showPasswordInput === false,
                          placeholder: "Ex.: 000.000.000-00",
                        },
                        model: {
                          value: _vm.formData.cpf,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "cpf", $$v)
                          },
                          expression: "formData.cpf",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [
                        _vm._v("Data de Nascimento"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99/99/9999",
                            expression: "'99/99/9999'",
                          },
                        ],
                        attrs: { placeholder: "Ex.: dd/mm/aaaa" },
                        model: {
                          value: _vm.formData.nascimento,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "nascimento", $$v)
                          },
                          expression: "formData.nascimento",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.edit,
                          expression: "!edit",
                        },
                      ],
                    },
                    [
                      _c("span", [
                        _vm._v("Telefone"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "(99) 99999-9999",
                            expression: "'(99) 99999-9999'",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: {
                          disabled: _vm.showPasswordInput === false,
                          placeholder: "Ex.: (00) 9 9999-9999",
                        },
                        model: {
                          value: _vm.formData.telefone,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "telefone", $$v)
                          },
                          expression: "formData.telefone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 1,
                      expression: "active === 1",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("CEP")]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "99999-999",
                            expression: "'99999-999'",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: { placeholder: "Ex.: 00000-00" },
                        model: {
                          value: _vm.formData.cep,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "cep", $$v)
                          },
                          expression: "formData.cep",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("Estado"), _c("span", [_vm._v("*")])]),
                      _c(
                        "el-select",
                        {
                          staticClass: "input-style",
                          attrs: { placeholder: "Estado" },
                          model: {
                            value: _vm.formData.estado,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "estado", $$v)
                            },
                            expression: "formData.estado",
                          },
                        },
                        _vm._l(_vm.listaestados, function (estado, i) {
                          return _c("el-option", {
                            key: i,
                            attrs: { label: estado.no_uf, value: estado.co_uf },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("Cidade"), _c("span", [_vm._v("*")])]),
                      _c("el-autocomplete", {
                        staticClass: "input-style",
                        attrs: {
                          "fetch-suggestions": _vm.listacidadescomplete,
                          placeholder: "Selecione",
                        },
                        on: { select: _vm.handleSelectCidade },
                        model: {
                          value: _vm.formData.cidadeselected,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "cidadeselected", $$v)
                          },
                          expression: "formData.cidadeselected",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("Bairro"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        staticClass: "input-style",
                        attrs: { type: "text", placeholder: "Nome do Bairro" },
                        model: {
                          value: _vm.formData.bairro,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "bairro", $$v)
                          },
                          expression: "formData.bairro",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("Rua"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        attrs: { type: "text", placeholder: "Nome da Rua" },
                        model: {
                          value: _vm.formData.rua,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "rua", $$v)
                          },
                          expression: "formData.rua",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("Número"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        attrs: { placeholder: "Número", type: "number" },
                        model: {
                          value: _vm.formData.numero,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "numero", $$v)
                          },
                          expression: "formData.numero",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { inline: false } },
                    [
                      _c("span", [_vm._v("Ponto de Referência")]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              regex:
                                "[0-9a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*",
                            },
                            expression:
                              "{'regex': '[0-9a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*'}",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: {
                          placeholder: "Ponto de Referência",
                          type: "text",
                        },
                        model: {
                          value: _vm.formData.referencia,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "referencia", $$v)
                          },
                          expression: "formData.referencia",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _vm.showPasswordInput || !_vm.hasUser ? _c("span") : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.active === 2,
                      expression: "active === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("span", [_vm._v("Senha"), _c("span", [_vm._v("*")])]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: { regex: "[0-9a-zA-Z]*" },
                            expression: "{'regex': '[0-9a-zA-Z]*'}",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: {
                          placeholder:
                            "Mínimo de 8 caracteres contendo números e letras",
                          type: "password",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.formData.password1,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "password1", $$v)
                          },
                          expression: "formData.password1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("span", [
                        _vm._v("Repetir Senha"),
                        _c("span", [_vm._v("*")]),
                      ]),
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: { regex: "[0-9a-zA-Z]*" },
                            expression: "{'regex': '[0-9a-zA-Z]*'}",
                          },
                        ],
                        staticClass: "input-style",
                        attrs: {
                          placeholder:
                            "Mínimo de 8 caracteres contendo números e letras",
                          type: "password",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.formData.password2,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "password2", $$v)
                          },
                          expression: "formData.password2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                ],
                1
              ),
              _vm.error !== ""
                ? _c("el-alert", {
                    attrs: { title: _vm.error, type: "error", closable: false },
                  })
                : _vm._e(),
              _vm.success
                ? _c("el-alert", {
                    attrs: {
                      title:
                        "Aluno " +
                        (_vm.funcionario !== null ? "atualizado" : "criado") +
                        " com sucesso",
                      type: "success",
                      closable: false,
                    },
                  })
                : _vm._e(),
              _c("br"),
              _c(
                "el-form-item",
                { staticClass: "buttons" },
                [
                  _vm.active != 0
                    ? _c(
                        "el-button",
                        { attrs: { size: "medium" }, on: { click: _vm.prev } },
                        [_vm._v("Voltar")]
                      )
                    : _vm._e(),
                  _vm.active === 0 || _vm.active === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.next(_vm.formData)
                            },
                          },
                        },
                        [_vm._v("Avançar")]
                      )
                    : _vm._e(),
                  _vm.active === 2
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.sending,
                            disabled: _vm.sending,
                            size: "medium",
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.funcionario === null ? "Cadastrar" : "Salvar"
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }