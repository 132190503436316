var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "maincontainerviewbarreiraslisting" },
        [
          _c("el-row", { attrs: { type: "flex", justify: "center" } }, [
            _c("h2", [_vm._v("Barreiras Sanitárias")]),
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            {
              staticClass: "panel-group",
              attrs: { gutter: 40, type: "flex", justify: "center" },
            },
            [
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 10, sm: 10, lg: 8 },
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-people" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "peoples",
                            "class-name": "card-panel-icon",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("Visitantes"),
                        ]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.count,
                            duration: 3000,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 10, sm: 10, lg: 8 },
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-message" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "peoples",
                            "class-name": "card-panel-icon",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "card-panel-description" },
                      [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("Casos de alerta"),
                        ]),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": 0,
                            duration: 3000,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 10, type: "flex", justify: "start" } },
            [
              _c("el-col", { attrs: { md: 2, offset: 2 } }, [
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v("Filtros:"),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 10, type: "flex", justify: "start" } },
            [
              _c(
                "el-col",
                { attrs: { md: 4, offset: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        value: _vm.origem.estado,
                        filterable: "",
                        placeholder: "Estado de origem",
                        "no-match-text": "Estado não encontrado",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.selecionarEstado({ estado: $event })
                        },
                      },
                    },
                    _vm._l(_vm.estados, function (item) {
                      return _c("el-option", {
                        key: item.co_uf,
                        attrs: { label: item.no_uf, value: item.co_uf },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { md: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        value: _vm.origem.cidade,
                        filterable: "",
                        placeholder: "Cidade de origem",
                        "no-match-text": "Cidade não encontrada",
                        "no-data-text": "Selecione o estado",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.selecionarCidade({ cidade: $event })
                        },
                      },
                    },
                    _vm._l(_vm.cidadesOrigem, function (item) {
                      return _c("el-option", {
                        key: item.co_localidade,
                        attrs: {
                          label: item.no_localidade,
                          value: item.no_localidade,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { md: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        value: _vm.destino.estado,
                        filterable: "",
                        placeholder: "Estado de destino",
                        "no-match-text": "Estado não encontrado",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.selecionarEstado({
                            estado: $event,
                            origem: false,
                          })
                        },
                      },
                    },
                    _vm._l(_vm.estados, function (item) {
                      return _c("el-option", {
                        key: item.co_uf,
                        attrs: { label: item.no_uf, value: item.co_uf },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { md: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        value: _vm.destino.cidade,
                        filterable: "",
                        placeholder: "Cidade de destino",
                        "no-match-text": "Cidade não encontrada",
                        "no-data-text": "Selecione o estado",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.selecionarCidade({
                            cidade: $event,
                            origem: false,
                          })
                        },
                      },
                    },
                    _vm._l(_vm.cidadesDestino, function (item) {
                      return _c("el-option", {
                        key: item.co_localidade,
                        attrs: {
                          label: item.no_localidade,
                          value: item.no_localidade,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c("el-card", { staticClass: "box-card-results" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-card",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("RESULTADOS")])]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-card" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.barreirasFiltered.length)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "40px" } },
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%", "font-size": "11px" },
                      attrs: {
                        data: _vm.barreirasPaginated,
                        "row-class-name": _vm.casoAlerta,
                      },
                    },
                    [
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "nome", label: "NOME" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "cpf", label: "CPF" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "profissao", label: "PROFISSÃO" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "uf_origem",
                          label: "UF ORIG",
                          width: "90",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "localidade_origem",
                          label: "CID ORIG",
                          width: "100",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bairro_origem",
                          label: "BR ORIG",
                          width: "90",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "uf_destino",
                          label: "UF DEST",
                          width: "90",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "localidade_destino",
                          label: "CID DEST",
                          width: "90",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bairro_destino",
                          label: "BR DEST",
                          width: "90",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "temperatura", label: "TEMP" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "data_nascimento", label: "DT NASC" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        staticClass: "table-column-style",
                        attrs: { prop: "sintomas", label: "SINTOMAS" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" } },
            [
              _vm.barreirasFiltered.length > 0
                ? _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-size": _vm.barreirasPerPage,
                      layout: "prev, pager, next",
                      total: _vm.barreirasFiltered.length,
                    },
                    on: { "current-change": _vm.changePage },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }