var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "pagehospitalsecretariamaincontainer" } },
    [
      _vm.hospital
        ? _c(
            "el-row",
            { staticStyle: { "padding-top": "0.4rem" }, attrs: { gutter: 32 } },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, lg: 24, offset: 0 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            "/hospitais/" +
                              _vm.$route.params.id +
                              "/cadastrarprofissional"
                          )
                        },
                      },
                    },
                    [_vm._v("Cadastrar profissional")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hospital
        ? _c(
            "el-row",
            { staticStyle: { "padding-top": "1rem" }, attrs: { gutter: 32 } },
            [
              _c("el-col", { attrs: { xs: 24, sm: 24, lg: 24, offset: 0 } }, [
                _c("span", { staticStyle: { "font-size": "20px" } }, [
                  _vm._v("Nome: " + _vm._s(_vm.hospital.nome)),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      _vm.hospital
        ? _c(
            "el-row",
            { staticStyle: { "padding-top": "0.4rem" }, attrs: { gutter: 32 } },
            [
              _c("el-col", { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } }, [
                _vm._v("Estado"),
              ]),
              _c("el-col", { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } }, [
                _vm._v(_vm._s(_vm.hospital.uf)),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.hospital
        ? _c(
            "el-row",
            { staticStyle: { "padding-top": "0.4rem" }, attrs: { gutter: 32 } },
            [
              _c("el-col", { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } }, [
                _vm._v("Cidade"),
              ]),
              _c("el-col", { attrs: { xs: 12, sm: 12, lg: 12, offset: 0 } }, [
                _vm._v(_vm._s(_vm.hospital.localidade)),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }