"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));

var _index = _interopRequireDefault(require("@/directive/clipboard/index.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// use clipboard directly
// use clipboard by v-directive
var _default = {
  name: 'ClipboardDemo',
  directives: {
    clipboard: _index.default
  },
  data: function data() {
    return {
      activeName: 'directly',
      inputData: 'https://github.com/PanJiaChen/vue-element-admin'
    };
  },
  methods: {
    handleCopy: function handleCopy(text, event) {
      (0, _clipboard.default)(text, event);
    },
    clipboardSuccess: function clipboardSuccess() {
      this.$message({
        message: 'Copy successfully',
        type: 'success',
        duration: 1500
      });
    }
  }
};
exports.default = _default;