"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;

var _settings = _interopRequireDefault(require("@/settings"));

var title = _settings.default.title || 'Vue Element Admin';

function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, " - ").concat(title);
  }

  return "".concat(title);
}