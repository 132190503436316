var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "maincontainerviewacasosreport" } },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [
              _vm._v("Relatório Central de Casos"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("strong", [_vm._v("Filtros")]),
                    _c("img", {
                      staticStyle: {
                        width: "1.5rem",
                        float: "right",
                        "margin-left": "0.5rem",
                      },
                      attrs: { src: require("@/assets/images/icon-pdf.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          float: "right",
                          padding: "3px 0",
                          "font-size": "14px",
                          "font-weight": "bold",
                        },
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.dadosSelected,
                        },
                        on: { click: _vm.generatePdf },
                      },
                      [_vm._v("Gerar PDF")]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "1.5rem",
                        float: "right",
                        "margin-right": "3rem",
                        "margin-left": "0.5rem",
                      },
                      attrs: { src: require("@/assets/images/icon-excel.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          float: "right",
                          padding: "3px 0",
                          "font-size": "14px",
                          "font-weight": "bold",
                        },
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.dadosSelected,
                        },
                        on: { click: _vm.generateExcel },
                      },
                      [_vm._v("Gerar Excel")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-style" },
                  [
                    _c(
                      "el-form",
                      { staticClass: "form-style", attrs: { inline: true } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Nome")]),
                            _c("el-input", {
                              attrs: { placeholder: "Nome" },
                              model: {
                                value: _vm.filtros.nome,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "nome", $$v)
                                },
                                expression: "filtros.nome",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("CPF")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "###.###.###-##",
                                  expression: "'###.###.###-##'",
                                },
                              ],
                              attrs: { placeholder: "Ex.: 000.000.000-00" },
                              model: {
                                value: _vm.filtros.cpf,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "cpf", $$v)
                                },
                                expression: "filtros.cpf",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Setor")]),
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "Setor" },
                                model: {
                                  value: _vm.filtros.setor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "setor", $$v)
                                  },
                                  expression: "filtros.setor",
                                },
                              },
                              _vm._l(_vm.listaSetores, function (setor, key) {
                                return _c("el-option", {
                                  attrs: {
                                    label: setor.nome.toUpperCase(),
                                    value: setor.id,
                                    kei: key,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Status")]),
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "Status" },
                                model: {
                                  value: _vm.filtros.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "status", $$v)
                                  },
                                  expression: "filtros.status",
                                },
                              },
                              _vm._l(_vm.status, function (stat, key) {
                                return _c("el-option", {
                                  attrs: {
                                    label: stat.label,
                                    value: stat.value,
                                    kei: key,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Bairro")]),
                            _c("el-input", {
                              attrs: { placeholder: "Bairro" },
                              model: {
                                value: _vm.filtros.bairro,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "bairro", $$v)
                                },
                                expression: "filtros.bairro",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtros.status == "suspeito",
                                expression: "filtros.status == 'suspeito'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Suspeitos de")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Suspeitos de:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_suspeito_minima,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "data_suspeito_minima",
                                    $$v
                                  )
                                },
                                expression: "filtros.data_suspeito_minima",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtros.status == "suspeito",
                                expression: "filtros.status == 'suspeito'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Suspeitos até")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Suspeitos até:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_suspeito_maxima,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "data_suspeito_maxima",
                                    $$v
                                  )
                                },
                                expression: "filtros.data_suspeito_maxima",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtros.status == "confirmado",
                                expression: "filtros.status == 'confirmado'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Confirmados de")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Confirmados de:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_confirmado_minima,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "data_confirmado_minima",
                                    $$v
                                  )
                                },
                                expression: "filtros.data_confirmado_minima",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtros.status == "confirmado",
                                expression: "filtros.status == 'confirmado'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Confirmados até")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Confirmados até:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_confirmado_maxima,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "data_confirmado_maxima",
                                    $$v
                                  )
                                },
                                expression: "filtros.data_confirmado_maxima",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtros.status == "recuperado",
                                expression: "filtros.status == 'recuperado'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Recuperados de")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Recuperados de:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_recuperado_minima,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "data_recuperado_minima",
                                    $$v
                                  )
                                },
                                expression: "filtros.data_recuperado_minima",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtros.status == "recuperado",
                                expression: "filtros.status == 'recuperado'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Recuperados até")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Recuperados até:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_recuperado_maxima,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "data_recuperado_maxima",
                                    $$v
                                  )
                                },
                                expression: "filtros.data_recuperado_maxima",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtros.status == "obito",
                                expression: "filtros.status == 'obito'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Óbitos de")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Óbitos de:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_obito_minima,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "data_obito_minima",
                                    $$v
                                  )
                                },
                                expression: "filtros.data_obito_minima",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtros.status == "obito",
                                expression: "filtros.status == 'obito'",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Óbitos até")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Óbitos até:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_obito_maxima,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "data_obito_maxima",
                                    $$v
                                  )
                                },
                                expression: "filtros.data_obito_maxima",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tags-filters-style" },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("Filtrar por: ")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.cpf === "" &&
                                      _vm.filtros.nome === "" &&
                                      _vm.filtros.setor === "" &&
                                      _vm.filtros.bairro === "" &&
                                      _vm.filtros.status === "" &&
                                      _vm.filtros.estado === "" &&
                                      _vm.filtros.data_suspeito_minima === "" &&
                                      _vm.filtros.data_suspeito_maxima === "" &&
                                      _vm.filtros.data_confirmado_minima ===
                                        "" &&
                                      _vm.filtros.data_recuperado_maxima ===
                                        "" &&
                                      _vm.filtros.data_confirmado_maxima ===
                                        "" &&
                                      _vm.filtros.data_recuperado_minima ===
                                        "" &&
                                      _vm.filtros.data_obito_minima === "" &&
                                      _vm.filtros.data_obito_maxima === "" &&
                                      _vm.filtros.zona === "" &&
                                      _vm.filtros.bairro === "",
                                    expression:
                                      "(filtros.cpf === '') && (filtros.nome === '') && (filtros.setor === '') &&(filtros.bairro === '') && (filtros.status === '') && (filtros.estado === '') && (filtros.data_suspeito_minima === '') && (filtros.data_suspeito_maxima === '') && (filtros.data_confirmado_minima=== '') && (filtros.data_recuperado_maxima === '') && (filtros.data_confirmado_maxima === '') && (filtros.data_recuperado_minima === '') && (filtros.data_obito_minima === '')  && (filtros.data_obito_maxima === '') && (filtros.zona === '') && (filtros.bairro === '')",
                                  },
                                ],
                              },
                              [_vm._v("Os seus filtros aparecerão aqui.")]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.nome != "",
                                    expression: "filtros.nome != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.nome)
                                  },
                                },
                              },
                              [_vm._v("Nome: " + _vm._s(_vm.filtros.nome))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.cpf != "",
                                    expression: "filtros.cpf != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.cpf)
                                  },
                                },
                              },
                              [_vm._v("CPF: " + _vm._s(_vm.filtros.cpf))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.setor != "",
                                    expression: "filtros.setor != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.setor)
                                  },
                                },
                              },
                              [_vm._v("Setor: " + _vm._s(_vm.filtros.setor))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.status != "",
                                    expression: "filtros.status != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.status)
                                  },
                                },
                              },
                              [_vm._v("Status: " + _vm._s(_vm.filtros.status))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.estado != "",
                                    expression: "filtros.estado != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.estado)
                                  },
                                },
                              },
                              [_vm._v("Estado: " + _vm._s(_vm.estado))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.cidade != "",
                                    expression: "filtros.cidade != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.cidade)
                                  },
                                },
                              },
                              [_vm._v("Cidade: " + _vm._s(_vm.filtros.cidade))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.bairro != "",
                                    expression: "filtros.bairro != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.bairro)
                                  },
                                },
                              },
                              [_vm._v("Bairro: " + _vm._s(_vm.filtros.bairro))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.data_suspeito_minima != "",
                                    expression:
                                      "filtros.data_suspeito_minima != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_suspeito_minima
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Suspeitos de: " +
                                    _vm._s(_vm.filtros.data_suspeito_minima)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.data_suspeito_maxima != "",
                                    expression:
                                      "filtros.data_suspeito_maxima != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_suspeito_maxima
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Suspeitos até: " +
                                    _vm._s(_vm.filtros.data_suspeito_maxima)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.data_confirmado_minima != "",
                                    expression:
                                      "filtros.data_confirmado_minima != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_confirmado_minima
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Confirmados de: " +
                                    _vm._s(_vm.filtros.data_confirmado_minima)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.data_confirmado_maxima != "",
                                    expression:
                                      "filtros.data_confirmado_maxima != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_confirmado_maxima
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Confirmados até: " +
                                    _vm._s(_vm.filtros.data_confirmado_maxima)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.data_recuperado_minima != "",
                                    expression:
                                      "filtros.data_recuperado_minima != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_recuperado_minima
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Recuperados de: " +
                                    _vm._s(_vm.filtros.data_recuperado_minima)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.data_recuperado_maxima != "",
                                    expression:
                                      "filtros.data_recuperado_maxima != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_recuperado_maxima
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Recuperados até: " +
                                    _vm._s(_vm.filtros.data_recuperado_maxima)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.data_obito_minima != "",
                                    expression:
                                      "filtros.data_obito_minima != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_obito_minima
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Óbitos de: " +
                                    _vm._s(_vm.filtros.data_obito_minima)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.data_obito_maxima != "",
                                    expression:
                                      "filtros.data_obito_maxima != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_obito_maxima
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Óbitos até: " +
                                    _vm._s(_vm.filtros.data_obito_maxima)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.zona != "",
                                    expression: "filtros.zona != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.zona)
                                  },
                                },
                              },
                              [_vm._v("Zona: " + _vm._s(_vm.filtros.zona))]
                            ),
                          ],
                          1
                        ),
                        _c("el-alert", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errorResult == true,
                              expression: "errorResult == true",
                            },
                          ],
                          staticStyle: { margin: "1rem" },
                          attrs: {
                            title:
                              "Ocorreu um erro ao buscar os dados. Por favor, atualize a página e tente novamente.",
                            type: "error",
                            "show-icon": "",
                          },
                        }),
                        _c(
                          "el-form-item",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "medium",
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.cleanFilters },
                              },
                              [_vm._v("Limpar")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.sending,
                                  disabled: _vm.sending,
                                  size: "medium",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.filter },
                              },
                              [_vm._v("Filtrar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex" },
            },
            [
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.resultados.casos_totais.label)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content-card",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.resultados.casos_totais.value)),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex" },
            },
            [
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "20px" },
                      attrs: {
                        src: require("@/assets/images/downloaded_greenflag.svg"),
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.casos_recuperados.label) + " (%)"
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content-card",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.casos_recuperados.value) +
                          "%      "
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "20px", "margin-left": "1rem" },
                      attrs: {
                        src: require("@/assets/images/downloaded_yellowflag.svg"),
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.casos_confirmados.label) + " (%)"
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content-card",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.casos_confirmados.value) + "%"
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "20px", "margin-left": "1rem" },
                      attrs: {
                        src: require("@/assets/images/downloaded_yellowflag.svg"),
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.casos_suspeitos.label) + " (%)"
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content-card",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.casos_suspeitos.value) + "%"
                      ),
                    ]),
                  ]
                ),
              ]),
              _c("el-card", { staticClass: "box-card-results" }, [
                _c(
                  "div",
                  {
                    staticClass: "title-card",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "20px", "margin-left": "1rem" },
                      attrs: {
                        src: require("@/assets/images/downloaded_redflag.svg"),
                      },
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.resultados.casos_obitos.label) + " (%)"
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "content-card",
                    staticStyle: { "font-size": "25px" },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.resultados.casos_obitos.value) + "%"),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex", gutter: 32 },
            },
            [
              _c(
                "el-card",
                { staticClass: "result-style" },
                [
                  _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "nome_completo",
                          label: "NOME",
                          width: "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cpf", label: "CPF" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "data_nascimento", label: "NASC" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.dados[
                                      scope.$index
                                    ].data_nascimento.replace(
                                      /(\d{4})-(\d{2})-(\d{2})/,
                                      "$3/$2/$1"
                                    )
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "status", label: "STATUS" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "data_confirmado", label: "CONF" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.dados[
                                      scope.$index
                                    ].data_confirmado.replace(
                                      /(\d{4})-(\d{2})-(\d{2})/,
                                      "$3/$2/$1"
                                    )
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "data_obito", label: "ÓBITO" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.dados[scope.$index].data_obito.replace(
                                      /(\d{4})-(\d{2})-(\d{2})/,
                                      "$3/$2/$1"
                                    )
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "data_recuperado", label: "RECUP" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.dados[
                                      scope.$index
                                    ].data_recuperado.replace(
                                      /(\d{4})-(\d{2})-(\d{2})/,
                                      "$3/$2/$1"
                                    )
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "data_suspeito", label: "SUSP" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.dados[
                                      scope.$index
                                    ].data_suspeito.replace(
                                      /(\d{4})-(\d{2})-(\d{2})/,
                                      "$3/$2/$1"
                                    )
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "setor", label: "SETOR" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.dados[scope.$index].setor.toUpperCase()
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }