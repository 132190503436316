var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainchat" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.imagemodalname,
            visible: _vm.imagemodalvisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imagemodalvisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("img", {
              staticStyle: { "max-width": "100%" },
              attrs: { src: _vm.imagemodalurl },
            }),
          ]),
        ]
      ),
      _c(
        "div",
        { attrs: { id: "fixedchatmaincontainer" } },
        [
          _c(
            "audio",
            {
              ref: "audiomessage",
              staticStyle: { display: "none" },
              attrs: { volume: "1.0" },
            },
            [
              _c("source", {
                attrs: {
                  src: "/234524__foolboymedia__notification-up-1.wav",
                  type: "audio/wav",
                },
              }),
            ]
          ),
          _vm._l(_vm.openedChats, function (tab, i) {
            return _c(
              "tab",
              {
                key: i,
                staticStyle: { "padding-right": "2rem" },
                attrs: {
                  tabcolor: "#304156",
                  tabwidth: "280px",
                  tabname: tab.nome,
                  showclosebutton: true,
                  maximized: tab.visible,
                },
                on: {
                  close: function ($event) {
                    return _vm.closetab(tab.id)
                  },
                  minmax: function ($event) {
                    return _vm.minmax(tab.id)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.finishChat(tab.id)
                      },
                    },
                  },
                  [
                    tab.status === "andamento" &&
                    tab.responsavel === _vm.username
                      ? _c("el-alert", {
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            title: "Encerrar",
                            type: "error",
                            closable: false,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.startChat(tab.id)
                      },
                    },
                  },
                  [
                    tab.status === "pendente"
                      ? _c("el-alert", {
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            title: "Atender",
                            type: "success",
                            closable: false,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "chatcontent",
                    staticStyle: { "min-height": "140px", width: "280px" },
                    attrs: { id: tab.id + "-chat" },
                  },
                  _vm._l(tab.messages, function (message) {
                    return _c(
                      "div",
                      {
                        staticClass: "chatmessagebox",
                        style: {
                          "justify-content": message.fromsaic
                            ? "flex-end"
                            : "flex-start",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "alignedcontainer",
                            class: {
                              "me-message": message.fromsaic === true,
                              "from-message": message.fromsaic === false,
                              "file-message": message.type === "file",
                            },
                            style: {
                              "margin-top": message.showDay
                                ? "2rem !important"
                                : "0;",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: message.showDay,
                                    expression: "message.showDay",
                                  },
                                ],
                                staticClass: "messagedate",
                                staticStyle: {
                                  "padding-bottom": "0",
                                  position: "relative",
                                  width: "100%",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "line",
                                  staticStyle: {
                                    position: "absolute",
                                    width: "240px",
                                    "border-top": "1px solid #DDD",
                                    top: "calc(50% - 30px - 0.5rem)",
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "content",
                                    staticStyle: {
                                      position: "absolute",
                                      width: "240px",
                                      top: "-45px",
                                      display: "flex",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "background-color": "white",
                                          display: "inline-block",
                                          padding: "0 1rem",
                                          color: "#777",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .moment(
                                                new Date(
                                                  message.hora.seconds * 1000
                                                )
                                              )
                                              .format("DD") +
                                              " de " +
                                              _vm
                                                .moment(
                                                  new Date(
                                                    message.hora.seconds * 1000
                                                  )
                                                )
                                                .lang("pt-br")
                                                .format("MMMM")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "messagefrom",
                                staticStyle: { "font-size": "13px" },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(message.nome))]
                                ),
                                _c("span", { staticStyle: { color: "grey" } }, [
                                  _vm._v(
                                    " - " +
                                      _vm._s(
                                        _vm
                                          .moment(
                                            new Date(
                                              message.hora.seconds * 1000
                                            )
                                          )
                                          .format("hh:mm")
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            message.type === "message"
                              ? _c("div", { staticClass: "messagebox" }, [
                                  _vm._v(_vm._s(message.content)),
                                ])
                              : _vm._e(),
                            message.type === "file"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "filebox",
                                    staticStyle: {
                                      "font-size": "1rem",
                                      "margin-top": "0.3rem",
                                      width: "110px",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            _vm.imagemodalvisible = true
                                            _vm.imagemodalurl =
                                              message.content.url
                                            _vm.imagemodalname =
                                              message.content.filename
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: { width: "100px" },
                                          attrs: { src: message.content.url },
                                        }),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "filename",
                                            staticStyle: {
                                              "font-size": "12px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(message.content.filename)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: message.fromsaic === true,
                                    expression: "message.fromsaic === true",
                                  },
                                ],
                                staticClass: "checks",
                                staticStyle: {
                                  "background-color": "#bddac1",
                                  float: "right",
                                  "border-radius": "1rem",
                                  padding: "0.4rem",
                                },
                              },
                              [
                                tab.ultimavisualizacaocidadao &&
                                tab.ultimavisualizacaocidadao < message.hora
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "15px",
                                        float: "right",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/downloaded_check_fill.svg"),
                                      },
                                    })
                                  : _c("img", {
                                      staticStyle: {
                                        width: "15px",
                                        float: "right",
                                      },
                                      attrs: {
                                        src: require("@/assets/images/downloaded_check_all.svg"),
                                      },
                                    }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "chatoptions",
                    staticStyle: { width: "280px", float: "left" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "chatoptionsrow1",
                        staticStyle: {
                          padding: "0.3rem",
                          "box-sizing": "border-box !important",
                          width: "280px",
                          float: "left",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "col1",
                            staticStyle: { width: "100%", float: "left" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                "font-size": "12px",
                                "min-height": "70px !important",
                              },
                              attrs: {
                                type: "textarea",
                                rows: 2,
                                placeholder: "Mensagem",
                                disabled:
                                  tab.responsavel !== _vm.username &&
                                  tab.status !== "encerrado",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.updatevisualizacao(tab.id)
                                },
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.sendMessage(tab.id, "message")
                                },
                              },
                              model: {
                                value: tab.messagetosend,
                                callback: function ($$v) {
                                  _vm.$set(tab, "messagetosend", $$v)
                                },
                                expression: "tab.messagetosend",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "chatoptionsrow2",
                        staticStyle: {
                          float: "left",
                          width: "280px",
                          padding: "0.3rem",
                          "box-sizing": "border-box",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "upload-btn-wrapper",
                            staticStyle: { width: "50px", float: "left" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                class: {
                                  btndisabled:
                                    (tab.responsavel !== _vm.username &&
                                      tab.status !== "encerrado") ||
                                    tab.sendingmessage,
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { width: "20px" },
                                  attrs: {
                                    src: require("@/assets/images/downloaded_image.svg"),
                                  },
                                }),
                              ]
                            ),
                            _c("input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                type: "file",
                                accept: ".png,.jpg,.jpeg,.gif",
                                disabled:
                                  (tab.responsavel !== _vm.username &&
                                    tab.status !== "encerrado") ||
                                  tab.sendingmessage,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.test($event, tab.id)
                                },
                              },
                            }),
                          ]
                        ),
                        _c("div", { staticStyle: { float: "right" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "flexsend",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                                width: "100%",
                                height: "20px",
                                cursor: "pointer",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-top": "5px",
                                    padding: "7px 10px",
                                  },
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-position",
                                    disabled:
                                      (tab.responsavel !== _vm.username &&
                                        tab.status !== "encerrado") ||
                                      tab.messagetosend === "" ||
                                      tab.sendingmessage,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendMessage(tab.id, "message")
                                    },
                                  },
                                },
                                [_vm._v("Enviar")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          _c(
            "tab",
            {
              attrs: {
                tabname: "Chats",
                alert: _vm.somePendente,
                maximized: _vm.chatlistOpen,
              },
              on: {
                minmax: function ($event) {
                  _vm.chatlistOpen = !_vm.chatlistOpen
                },
              },
            },
            [
              _c("div", { staticClass: "chatlist" }, [
                _c(
                  "ul",
                  _vm._l(_vm.chatlist, function (chat) {
                    return _c(
                      "li",
                      {
                        style: {
                          "background-color":
                            chat.ultimamensagemfromcidadao &&
                            chat.ultimavisualizacaosaic &&
                            chat.horaultimamensagem >
                              chat.ultimavisualizacaosaic
                              ? "#f5f779 !important"
                              : "unset",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.openChat(chat.id)
                          },
                        },
                      },
                      [
                        _c("i", {
                          class: {
                            "el-icon-finished": chat.status === "encerrado",
                            "el-icon-service": chat.status === "andamento",
                            "el-icon-message-solid": chat.status === "pendente",
                          },
                          style: {
                            "padding-right": "0.5rem",
                            color: {
                              encerrado: "green",
                              pendente: "orange",
                              andamento: "grey",
                            }[chat.status],
                            "font-size": "1.1rem",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "padding-bottom": "0.2rem",
                              display: "inline-block",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                {
                                  encerrado: "Encerrado",
                                  andamento: "Em andamento",
                                  pendente: "Pendente",
                                }[chat.status]
                              )
                            ),
                          ]
                        ),
                        _c("br"),
                        _vm._v(_vm._s(chat.nome)),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }