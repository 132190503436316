var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "use clipboard  directly", name: "directly" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px", "max-width": "100%" },
                attrs: { placeholder: "Please input" },
                model: {
                  value: _vm.inputData,
                  callback: function ($$v) {
                    _vm.inputData = $$v
                  },
                  expression: "inputData",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-document" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCopy(_vm.inputData, $event)
                    },
                  },
                },
                [_vm._v("\n        copy\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            {
              attrs: {
                label: "use clipboard by v-directive",
                name: "v-directive",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px", "max-width": "100%" },
                attrs: { placeholder: "Please input" },
                model: {
                  value: _vm.inputData,
                  callback: function ($$v) {
                    _vm.inputData = $$v
                  },
                  expression: "inputData",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.inputData,
                      expression: "inputData",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.clipboardSuccess,
                      expression: "clipboardSuccess",
                      arg: "success",
                    },
                  ],
                  attrs: { type: "primary", icon: "el-icon-document" },
                },
                [_vm._v("\n        copy\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }