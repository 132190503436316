var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 10 } },
        _vm._l(_vm.data.panels, function (panel) {
          return _c(
            "el-col",
            {
              key: panel.label,
              staticClass: "card-panel-col",
              attrs: { xs: 12, sm: 12, lg: 4 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card-panel",
                  on: {
                    click: function ($event) {
                      return _vm.handleSetLineChartData(26)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "card-panel-description" },
                    [
                      _c("div", { staticClass: "card-panel-text" }, [
                        _vm._v(
                          "\n          " + _vm._s(panel.label) + "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("count-to", {
                        staticClass: "card-panel-num",
                        attrs: {
                          "start-val": 0,
                          "end-val": panel.value,
                          duration: 3500,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }