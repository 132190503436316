"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _typeof2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/typeof.js"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

var _layout = _interopRequireDefault(require("@/layout"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var nestedRouter = {
  path: '/nested',
  component: _layout.default,
  redirect: '/nested/menu1/menu1-1',
  name: 'Nested',
  meta: {
    title: 'Nested Routes',
    icon: 'nested'
  },
  children: [{
    path: 'menu1',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/nested/menu1/index'));
      });
    },
    // Parent router-view
    name: 'Menu1',
    meta: {
      title: 'Menu 1'
    },
    redirect: '/nested/menu1/menu1-1',
    children: [{
      path: 'menu1-1',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/nested/menu1/menu1-1'));
        });
      },
      name: 'Menu1-1',
      meta: {
        title: 'Menu 1-1'
      }
    }, {
      path: 'menu1-2',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/nested/menu1/menu1-2'));
        });
      },
      name: 'Menu1-2',
      redirect: '/nested/menu1/menu1-2/menu1-2-1',
      meta: {
        title: 'Menu 1-2'
      },
      children: [{
        path: 'menu1-2-1',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/nested/menu1/menu1-2/menu1-2-1'));
          });
        },
        name: 'Menu1-2-1',
        meta: {
          title: 'Menu 1-2-1'
        }
      }, {
        path: 'menu1-2-2',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require('@/views/nested/menu1/menu1-2/menu1-2-2'));
          });
        },
        name: 'Menu1-2-2',
        meta: {
          title: 'Menu 1-2-2'
        }
      }]
    }, {
      path: 'menu1-3',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/nested/menu1/menu1-3'));
        });
      },
      name: 'Menu1-3',
      meta: {
        title: 'Menu 1-3'
      }
    }]
  }, {
    path: 'menu2',
    name: 'Menu2',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/nested/menu2/index'));
      });
    },
    meta: {
      title: 'Menu 2'
    }
  }]
};
var _default = nestedRouter;
exports.default = _default;