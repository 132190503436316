"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _remoteSearch = require("@/api/remote-search");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        success: 'success',
        pending: 'danger'
      };
      return statusMap[status];
    },
    orderNoFilter: function orderNoFilter(str) {
      return str.substring(0, 30);
    }
  },
  data: function data() {
    return {
      list: null
    };
  },
  created: function created() {// this.fetchData()
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      (0, _remoteSearch.transactionList)().then(function (response) {
        _this.list = response.data.items.slice(0, 8);
      });
    }
  }
};
exports.default = _default;