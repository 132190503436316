"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

require("core-js/modules/web.dom.iterable");

var _localforage = _interopRequireDefault(require("localforage"));

var _firebaseConfig = require("@/firebaseConfig");

var _moment = _interopRequireDefault(require("moment"));

var _vuex = require("vuex");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

var _default = {
  name: 'pageChatSuporte',
  data: function data() {
    return {
      'sendingmessage': false,
      'imagemodalname': '',
      'imagemodalvisible': false,
      'imagemodalurl': '',
      'unsubscribeChatList': null,
      chats: [],
      'textToSend': '',
      chat: null
    };
  },
  destroyed: function destroyed() {
    if (this.unsubscribeChatList) {
      this.unsubscribeChatList();
    }
  },
  created: function created() {
    var self = this;

    _localforage.default.getItem('secretaria_scheme').then(function (secretaria_scheme) {
      self.unsubscribeChatList = _firebaseConfig.db.collection('chat-dash-suporte').where('secretaria_schema', '==', secretaria_scheme).orderBy('horaultimamensagem', 'desc').onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            var newDocData = change.doc.data();
            self.chats.push({
              'id': change.doc.ref.id,
              'nome': newDocData.nome,
              'responsavel': newDocData.responsavel,
              'status': newDocData.status,
              'horaultimamensagem': newDocData.horaultimamensagem.toDate()
            });
          }

          if (change.type === "modified") {
            var changedDocData = change.doc.data();
            var changeChat = self.chats.filter(function (c) {
              return c.id === change.doc.ref.id;
            })[0];

            if (self.chat && self.chat.id === change.doc.ref.id) {
              self.chat.responsavel = changedDocData.responsavel;
              self.chat.status = changedDocData.status;
            }

            changeChat.nome = changedDocData.nome;
            changeChat.responsavel = changedDocData.responsavel;
            changeChat.status = changedDocData.status;
            changeChat.horaultimamensagem = changedDocData.horaultimamensagem.toDate();
          }
        });
      });
    });
  },
  computed: _objectSpread({
    orderedChatList: function orderedChatList() {
      return this.chats;
    }
  }, (0, _vuex.mapGetters)(['secretaria_scheme', 'email', 'name'])),
  methods: {
    uploadimage: function uploadimage(event) {
      var _this = this;

      var self = this;
      this.$confirm('Enviar arquivo ' + event.srcElement.files[0].name, 'Alerta', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(function () {
        var name = makeid(15);
        var extension = event.srcElement.files[0].name.split('.')[1];
        self.sendingmessage = true;

        var storageref = _firebaseConfig.storage.ref();

        var newfileRef = storageref.child('chat-suporte-files/' + _this.id + '/' + name + '.' + extension);
        newfileRef.put(event.srcElement.files[0]).then(function (snapshot) {
          // console.log(snapshot)
          snapshot.ref.getDownloadURL().then(function (downloadURL) {
            self.sendMessage('file', {
              'filename': event.srcElement.files[0].name,
              'url': downloadURL
            });
            event.target.value = ""; // console.log(downloadURL)
          });
        });
      }).catch(function (e) {
        event.target.value = "";
        self.sendingmessage = false;
      });
    },
    openChat: function openChat(chatId) {
      if (this.chat) {
        this.chat.unsubscribe();
      }

      var self = this;
      var chat = this.chats.filter(function (c) {
        return c.id === chatId;
      })[0];
      var c = {
        'id': chatId,
        'responsavel': chat.responsavel,
        'status': chat.status,
        'mensagens': []
      };
      this.chat = c;
      c.unsubscribe = _firebaseConfig.db.collection('chat-dash-suporte').doc(chatId).collection('messages').orderBy('hora', 'asc').onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            var newDocData = change.doc.data();
            c.mensagens.push({
              'content': newDocData.content,
              'fromsaic': newDocData.fromsaic,
              'hora': newDocData.hora.toDate(),
              'nome': newDocData.nome,
              'type': newDocData.type
            });
            window.setTimeout(function () {
              var element = document.getElementById('chatmessages');
              element.scrollTop = element.scrollHeight - element.clientHeight;
            }, 200);
          }
        });
      }); // console.log(chatId)
    },
    acceptChat: function acceptChat(chatId) {
      var self = this;

      _firebaseConfig.db.collection('chat-dash-suporte').doc(chatId).update({
        'responsavel': self.email,
        'status': 'andamento'
      }).then(function () {
        self.openChat(chatId);
      });
    },
    finishChat: function finishChat(chatId) {
      var self = this;

      _firebaseConfig.db.collection('chat-dash-suporte').doc(chatId).update({
        'responsavel': '',
        'status': 'encerrado'
      });
    },
    sendMessage: function sendMessage(type, content) {
      if (this.chat.status === 'andamento' && this.chat.responsavel !== this.email || this.textToSend === '' && type === 'message') {
        return;
      }

      var self = this;

      _firebaseConfig.db.collection('chat-dash-suporte').doc(this.chat.id).get().then(function (chat) {
        var chatData = chat.data();

        _firebaseConfig.db.runTransaction(function (transaction) {
          return transaction.get(chat.ref).then(function (cdoc) {
            var updateChat = {
              'horaultimamensagem': _firebaseConfig.firebase.firestore.FieldValue.serverTimestamp()
            };

            if (chatData.status === 'encerrado') {
              updateChat.status = 'andamento';
              updateChat.responsavel = self.email;
            }

            transaction.update(chat.ref, updateChat);
            var messageRef = chat.ref.collection('messages').doc();
            transaction.set(messageRef, {
              'type': type,
              'content': content || self.textToSend,
              'fromsaic': true,
              'nome': self.name,
              'hora': _firebaseConfig.firebase.firestore.FieldValue.serverTimestamp()
            });
          });
        }).then(function () {
          console.log("Transaction successfully committed!");
          self.textToSend = '';
          self.sendingmessage = false;
        }).catch(function (error) {
          console.log("Transaction failed: ", error);
          self.sendingmessage = false;
        });
      });
    }
  }
};
exports.default = _default;