"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  perfis: []
};
var mutations = {
  SET_PERFIS: function SET_PERFIS(state, payload) {
    state.perfis = payload;
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations
};
exports.default = _default;