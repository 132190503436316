"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterAgentes = filterAgentes;
exports.getAgenteById = getAgenteById;
exports.listAgentes = listAgentes;
exports.newAgente = newAgente;
exports.updateAgente = updateAgente;

var _request = _interopRequireDefault(require("@/utils/request"));

function newAgente(data) {
  return (0, _request.default)({
    url: '/core/profissionais/',
    method: 'post',
    data: data
  });
}

function updateAgente(data, id) {
  return (0, _request.default)({
    url: '/core/profissionais/' + id,
    method: 'patch',
    data: data
  });
}

function getAgenteById(id) {
  return (0, _request.default)({
    url: '/core/profissionais/' + id,
    method: 'get'
  });
}

function listAgentes() {
  return (0, _request.default)({
    url: '/core/profissionais/',
    method: 'get'
  });
}

function filterAgentes(data) {
  return (0, _request.default)({
    url: '/core/profissionais/?' + data,
    method: 'get'
  });
}