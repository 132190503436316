var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "forgotForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.forgotForm,
            rules: _vm.forgotRules,
            autocomplete: "on",
            "label-position": "left",
          },
        },
        [
          _c("div", { staticClass: "title-container" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  "text-align": "center",
                  "padding-bottom": "1rem",
                },
              },
              [
                _c("img", {
                  staticStyle: { width: "350px" },
                  attrs: { src: require("@/assets/images/SAICovid-19.png") },
                }),
              ]
            ),
            _vm._v(" "),
            _c("h3", [_vm._v("Redefinição de senha")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasMsg,
                  expression: "hasMsg",
                },
              ],
              staticStyle: { border: "1px solid #d0f5e0" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "1rem",
                    "background-color": "#e7faf0",
                    "margin-bottom": "1px",
                    color: "white",
                    cursor: "pointer",
                    "border-radius": "3px",
                    "border-color": "#13ce66",
                  },
                },
                [
                  _c("span", { staticStyle: { color: "#13ce66" } }, [
                    _vm._v(_vm._s(_vm.msg)),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hasMsg,
                  expression: "!hasMsg",
                },
              ],
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.hasToken,
                      expression: "!hasToken",
                    },
                  ],
                  staticStyle: { "margin-top": "1rem" },
                  attrs: { prop: "cpf" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "999.999.999-99",
                        expression: "'999.999.999-99'",
                      },
                    ],
                    ref: "CPF",
                    attrs: {
                      placeholder: "CPF",
                      name: "cpf",
                      type: "text",
                      tabindex: "1",
                      autocomplete: "on",
                    },
                    model: {
                      value: _vm.forgotForm.cpf,
                      callback: function ($$v) {
                        _vm.$set(_vm.forgotForm, "cpf", $$v)
                      },
                      expression: "forgotForm.cpf",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasToken,
                      expression: "hasToken",
                    },
                  ],
                  attrs: {
                    content: "Capslock ativado",
                    placement: "right",
                    manual: "",
                  },
                  model: {
                    value: _vm.capsTooltip,
                    callback: function ($$v) {
                      _vm.capsTooltip = $$v
                    },
                    expression: "capsTooltip",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "password" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          type: _vm.passwordType,
                          placeholder: "Senha",
                          name: "password",
                          tabindex: "2",
                          autocomplete: "on",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.capsTooltip = false
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            return _vm.checkCapslock($event)
                          },
                        },
                        model: {
                          value: _vm.forgotForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.forgotForm, "password", $$v)
                          },
                          expression: "forgotForm.password",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class":
                                _vm.passwordType === "password"
                                  ? "eye"
                                  : "eye-open",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.hasToken,
                      expression: "hasToken",
                    },
                  ],
                  attrs: {
                    content: "Capslock ativado",
                    placement: "right",
                    manual: "",
                  },
                  model: {
                    value: _vm.capsTooltip,
                    callback: function ($$v) {
                      _vm.capsTooltip = $$v
                    },
                    expression: "capsTooltip",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password2" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "password" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password2",
                        attrs: {
                          type: _vm.passwordType,
                          placeholder: "Confirmação da senha",
                          name: "password2",
                          tabindex: "2",
                          autocomplete: "on",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.capsTooltip = false
                          },
                        },
                        nativeOn: {
                          keyup: [
                            function ($event) {
                              return _vm.checkCapslock($event)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleLogin($event)
                            },
                          ],
                        },
                        model: {
                          value: _vm.forgotForm.password2,
                          callback: function ($$v) {
                            _vm.$set(_vm.forgotForm, "password2", $$v)
                          },
                          expression: "forgotForm.password2",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class":
                                _vm.passwordType === "password"
                                  ? "eye"
                                  : "eye-open",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-bottom": "30px" },
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleButton($event)
                    },
                  },
                },
                [_vm._v("Enviar")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticStyle: {
                color: "#4c4c4c",
                "text-decoration": "underline",
                "font-size": "13px",
                float: "right",
                cursor: "pointer",
              },
              on: { click: _vm.goLogin },
            },
            [_vm._v(" Voltar para login!")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }