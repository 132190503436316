var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "main-article",
      attrs: { id: "dados", "element-loading-text": "Gerando o PDF..." },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "article__heading" }, [
        _c("div", { staticClass: "article__heading__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("div", { staticStyle: { color: "#ccc" } }),
      ]),
      _vm.tp_relatorio == "avaliacoes"
        ? _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _vm.tp_relatorio == "avaliacoes"
                ? _c(
                    "el-card",
                    {
                      staticClass: "box-card-results",
                      staticStyle: { width: "25%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title-card",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.resultados.qtd_formularios_respondidos.label
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm.tp_relatorio == "avaliacoes"
                        ? _c(
                            "div",
                            {
                              staticClass: "content-card",
                              staticStyle: { "font-size": "25px" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.resultados.qtd_formularios_respondidos
                                      .value
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.tp_relatorio == "avaliacoes"
                ? _c(
                    "el-card",
                    {
                      staticClass: "box-card-results",
                      staticStyle: { width: "25%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title-card",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.resultados.valor_medio_respostas[2].label
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "content-card",
                          staticStyle: { "font-size": "25px" },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.resultados.valor_medio_respostas[2].value
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.tp_relatorio == "avaliacoes"
                ? _c(
                    "el-card",
                    {
                      staticClass: "box-card-results",
                      staticStyle: { width: "45%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title-card",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.resultados.riscos.label) + "(%)"),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "content-card" }, [
                        _c("img", {
                          staticStyle: { width: "20px" },
                          attrs: {
                            src: require("@/assets/images/downloaded_greenflag.svg"),
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            "Baixo: " +
                              _vm._s(_vm.resultados.riscos.total_risco_baixo) +
                              "%"
                          ),
                        ]),
                        _c("img", {
                          staticStyle: { width: "20px", "margin-left": "1rem" },
                          attrs: {
                            src: require("@/assets/images/downloaded_yellowflag.svg"),
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            "Médio: " +
                              _vm._s(_vm.resultados.riscos.total_risco_medio) +
                              "%"
                          ),
                        ]),
                        _c("img", {
                          staticStyle: { width: "20px", "margin-left": "1rem" },
                          attrs: {
                            src: require("@/assets/images/downloaded_redflag.svg"),
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            "Alto: " +
                              _vm._s(_vm.resultados.riscos.total_risco_alto) +
                              "%"
                          ),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.filterSelectedStatus,
              expression: "!filterSelectedStatus",
            },
          ],
          staticClass: "row-bg charts-style",
          attrs: { type: "flex", gutter: 32 },
        },
        [
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("h3", { staticStyle: { "text-align": "left" } }, [
                  _vm._v("Riscos (%)"),
                ]),
                _c("pie-chart", { attrs: { data: _vm.resultados.pieData } }),
              ],
              1
            ),
          ]),
          _c("el-col", { attrs: { xs: 24, sm: 24, lg: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("h3", { staticStyle: { "text-align": "left" } }, [
                  _vm._v("Sexo (%)"),
                ]),
                _c("pie-chart", {
                  attrs: { data: _vm.resultados.pieDataSexo },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.filterSelectedStatus,
              expression: "filterSelectedStatus",
            },
          ],
          staticClass: "row-bg charts-style",
          attrs: { type: "flex", gutter: 32 },
        },
        [
          _c("el-col", { attrs: { xs: 32, sm: 32, lg: 32 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [
                _c("h3", { staticStyle: { "text-align": "left" } }, [
                  _vm._v("Sexo (%)"),
                ]),
                _c("pie-chart", {
                  attrs: { data: _vm.resultados.pieDataSexo },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", gutter: 32 } },
        [
          _c(
            "el-col",
            { attrs: { xs: 24, sm: 24, lg: 24, offset: 0 } },
            _vm._l(_vm.resultados.valor_medio_respostas, function (item) {
              return _c(
                "el-card",
                { key: item.key, staticClass: "box-card-estatisticas" },
                [
                  _c(
                    "el-row",
                    { staticClass: "row-style", attrs: { gutter: 32 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "title-col",
                          attrs: { xs: 14, sm: 14, lg: 14, offset: 0 },
                        },
                        [_vm._v(_vm._s(item.label))]
                      ),
                      _c(
                        "el-col",
                        {
                          staticClass: "value-col-style",
                          attrs: { xs: 2, sm: 2, lg: 2, offset: 0 },
                        },
                        [_vm._v(_vm._s(item.value))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", gutter: 32 } },
        [
          _c(
            "div",
            { staticClass: "result-table-style" },
            [
              _vm.tp_relatorio == "avaliacoes"
                ? _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "status_risco", label: "RISCO" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm.dados[scope.$index].status_risco ===
                                  "risco_alto"
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_redflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].status_risco ===
                                  "risco_baixo"
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_greenflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.dados[scope.$index].status_risco ===
                                  "risco_medio"
                                    ? _c("img", {
                                        staticStyle: {
                                          width: "20px",
                                          "margin-right": "1rem",
                                        },
                                        attrs: {
                                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        {
                                          risco_baixo: "Baixo",
                                          risco_medio: "Médio",
                                          risco_alto: "Alto",
                                        }[_vm.dados[scope.$index].status_risco]
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3457511194
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "perfil_cidadao.nome_completo",
                          label: "NOME",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "perfil_cidadao.celular",
                          label: "CONTATO",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "criado_em", label: "FEITA EM" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .moment(_vm.dados[scope.$index].dt)
                                        .format("DD/MM/YYYY")
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1546696571
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cod_cidadao_esus", label: "CÓD ESUS" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("br"),
      _c("div", { staticClass: "footer" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.domain) +
              "                                           Expedido por: " +
              _vm._s(_vm.username)
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "printlogo" } }, [
      _c("div", { staticClass: "header-logo-saic" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/SAICovid-19.png") },
        }),
      ]),
      _c("div", { staticClass: "header-text" }, [
        _c("span", [_vm._v("INFATEC - SOLUÇÕES TECNOLÓGICAS")]),
        _c("br"),
        _c("span", [_vm._v("TERESINA - PI")]),
        _c("br"),
        _c("span", [_vm._v("SISTEMA DE ACOMPANHAMENTO INTEGRAL AO COVID-19")]),
      ]),
      _c("div", { staticClass: "header-logo-infatec" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/infatec-logo.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }