var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "maincontainerviewrelatorioagentes" } },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("span", { staticClass: "title" }, [
              _vm._v("Relatório dos Agentes"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("strong", [_vm._v("Filtros")]),
                    _c("img", {
                      staticStyle: {
                        width: "1.5rem",
                        float: "right",
                        "margin-left": "0.5rem",
                      },
                      attrs: { src: require("@/assets/images/icon-pdf.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          float: "right",
                          padding: "3px 0",
                          "font-size": "14px",
                          "font-weight": "bold",
                        },
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.dadosSelected,
                        },
                        on: { click: _vm.generatePdf },
                      },
                      [_vm._v("Gerar PDF")]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "1.5rem",
                        float: "right",
                        "margin-right": "3rem",
                        "margin-left": "0.5rem",
                      },
                      attrs: { src: require("@/assets/images/icon-excel.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          float: "right",
                          padding: "3px 0",
                          "font-size": "14px",
                          "font-weight": "bold",
                        },
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.dadosSelected,
                        },
                        on: { click: _vm.generateExcel },
                      },
                      [_vm._v("Gerar Excel")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-style" },
                  [
                    _c(
                      "el-form",
                      { staticClass: "form-style", attrs: { inline: true } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Nome do Agente")]),
                            _c("el-input", {
                              attrs: { placeholder: "Nome do Agente" },
                              model: {
                                value: _vm.filtros.agente,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "agente", $$v)
                                },
                                expression: "filtros.agente",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Bairro")]),
                            _c("el-input", {
                              attrs: { placeholder: "Bairro" },
                              model: {
                                value: _vm.filtros.bairro,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "bairro", $$v)
                                },
                                expression: "filtros.bairro",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Nascidos de")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Nascidos de:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_inicial,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "data_inicial", $$v)
                                },
                                expression: "filtros.data_inicial",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { staticClass: "form-item-style" },
                          [
                            _c("span", [_vm._v("Nascidos até  ")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Nascidos até:",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.data_final,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filtros, "data_final", $$v)
                                },
                                expression: "filtros.data_final",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "tags-filters-style" },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("Filtrar por: ")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.filtros.agente === "" &&
                                      _vm.filtros.bairro === "" &&
                                      _vm.filtros.data_inicial === "" &&
                                      _vm.filtros.data_final === "" &&
                                      _vm.filtros.zona === "",
                                    expression:
                                      "(filtros.agente === '') &&(filtros.bairro === '') && (filtros.data_inicial === '') && (filtros.data_final === '') && (filtros.zona === '')",
                                  },
                                ],
                              },
                              [_vm._v("Os seus filtros aparecerão aqui.")]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.agente != "",
                                    expression: "filtros.agente != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.agente)
                                  },
                                },
                              },
                              [_vm._v("Agente: " + _vm._s(_vm.filtros.agente))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.bairro != "",
                                    expression: "filtros.bairro != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.bairro)
                                  },
                                },
                              },
                              [_vm._v("Bairro: " + _vm._s(_vm.filtros.bairro))]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.data_inicial != "",
                                    expression: "filtros.data_inicial != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_inicial
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Nascidos de: " +
                                    _vm._s(_vm.filtros.data_inicial)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.data_final != "",
                                    expression: "filtros.data_final != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(
                                      _vm.filtros.data_final
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Nascidos até: " +
                                    _vm._s(_vm.filtros.data_final)
                                ),
                              ]
                            ),
                            _c(
                              "el-tag",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtros.zona != "",
                                    expression: "filtros.zona != ''",
                                  },
                                ],
                                attrs: { closable: "", type: "info" },
                                on: {
                                  close: function ($event) {
                                    return _vm.handleClose(_vm.filtros.zona)
                                  },
                                },
                              },
                              [_vm._v("Zona: " + _vm._s(_vm.filtros.zona))]
                            ),
                          ],
                          1
                        ),
                        _c("el-alert", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errorResult == true,
                              expression: "errorResult == true",
                            },
                          ],
                          staticStyle: { margin: "1rem" },
                          attrs: {
                            title:
                              "Ocorreu um erro ao buscar os dados. Por favor, atualize a página e tente novamente.",
                            type: "error",
                            "show-icon": "",
                          },
                        }),
                        _c(
                          "el-form-item",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "medium",
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.cleanFilters },
                              },
                              [_vm._v("Limpar")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.sending,
                                  disabled: _vm.sending,
                                  size: "medium",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.filter },
                              },
                              [_vm._v("Filtrar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { xs: 22, sm: 22, lg: 22, offset: 1 } },
                [
                  _c("el-card", { staticClass: "box-card-results" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-card",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("RESULTADOS")])]
                    ),
                    _c("div", { staticClass: "content-card" }, [
                      _c("span", [_vm._v(_vm._s(_vm.dados.length))]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dadosSelected,
                  expression: "dadosSelected",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex", gutter: 32 },
            },
            [
              _c(
                "el-card",
                { staticClass: "result-style" },
                [
                  _c(
                    "el-table",
                    { staticClass: "table-style", attrs: { data: _vm.dados } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "nome_completo", label: "NOME COMP" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cpf", label: "CPF" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cep", label: "CEP" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "uf", label: "ESTADO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "localidade", label: "CIDADE" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "bairro", label: "BAIRRO" },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "data_nascimento", label: "DT NASC" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.dados[
                                      scope.$index
                                    ].data_nascimento.replace(
                                      /(\d{4})-(\d{2})-(\d{2})/,
                                      "$3/$2/$1"
                                    )
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cns", label: "CNS" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }