var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "main-article",
      attrs: { "element-loading-text": "Gerando o PDF..." },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "article__heading" }, [
        _c("div", { staticClass: "article__heading__title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("br"),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tp_relatorio == "central_casos_funcionarios",
                expression: "tp_relatorio == 'central_casos_funcionarios'",
              },
            ],
            staticClass: "article__heading__subtitle",
          },
          [_vm._v(" " + _vm._s(_vm.subtitle))]
        ),
        _c("div", { staticStyle: { color: "#ccc" } }),
      ]),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex" } },
        [
          _c("el-card", { staticClass: "box-card-results" }, [
            _c(
              "div",
              {
                staticClass: "title-card",
                attrs: { slot: "header" },
                slot: "header",
              },
              [_c("span", [_vm._v(_vm._s(_vm.resultados.casos_totais.label))])]
            ),
            _c(
              "div",
              {
                staticClass: "content-card",
                staticStyle: { "font-size": "15px" },
              },
              [_c("span", [_vm._v(_vm._s(_vm.resultados.casos_totais.value))])]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex" } },
        [
          _c("el-card", { staticClass: "box-card-results" }, [
            _c(
              "div",
              {
                staticClass: "title-card",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("img", {
                  staticStyle: { width: "20px" },
                  attrs: {
                    src: require("@/assets/images/downloaded_greenflag.svg"),
                  },
                }),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.resultados.casos_recuperados.label) + " (%)"
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-card",
                staticStyle: { "font-size": "15px" },
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.resultados.casos_recuperados.value) + "%      "
                  ),
                ]),
              ]
            ),
          ]),
          _c("el-card", { staticClass: "box-card-results" }, [
            _c(
              "div",
              {
                staticClass: "title-card",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("img", {
                  staticStyle: { width: "20px", "margin-left": "1rem" },
                  attrs: {
                    src: require("@/assets/images/downloaded_yellowflag.svg"),
                  },
                }),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.resultados.casos_confirmados.label) + " (%)"
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-card",
                staticStyle: { "font-size": "15px" },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.resultados.casos_confirmados.value) + "%"),
                ]),
              ]
            ),
          ]),
          _c("el-card", { staticClass: "box-card-results" }, [
            _c(
              "div",
              {
                staticClass: "title-card",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("img", {
                  staticStyle: { width: "20px", "margin-left": "1rem" },
                  attrs: {
                    src: require("@/assets/images/downloaded_yellowflag.svg"),
                  },
                }),
                _c("span", [
                  _vm._v(_vm._s(_vm.resultados.casos_suspeitos.label) + " (%)"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-card",
                staticStyle: { "font-size": "15px" },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.resultados.casos_suspeitos.value) + "%"),
                ]),
              ]
            ),
          ]),
          _c("el-card", { staticClass: "box-card-results" }, [
            _c(
              "div",
              {
                staticClass: "title-card",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c("img", {
                  staticStyle: { width: "20px", "margin-left": "1rem" },
                  attrs: {
                    src: require("@/assets/images/downloaded_redflag.svg"),
                  },
                }),
                _c("span", [
                  _vm._v(_vm._s(_vm.resultados.casos_obitos.label) + " (%)"),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "content-card",
                staticStyle: { "font-size": "15px" },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.resultados.casos_obitos.value) + "%"),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tp_relatorio == "central_casos",
              expression: "tp_relatorio == 'central_casos'",
            },
          ],
          staticClass: "row-bg",
          attrs: { type: "flex", gutter: 32 },
        },
        [
          _c(
            "el-table",
            { staticClass: "table-style", attrs: { data: _vm.dados } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "cidadao.nome_completo",
                  label: "NOME",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "cidadao.cpf", label: "CPF" },
              }),
              _c("el-table-column", {
                attrs: { prop: "data_nascimento", label: "NASC" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[
                              scope.$index
                            ].cidadao.data_nascimento.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "STATUS" },
              }),
              _c("el-table-column", {
                attrs: { prop: "data_confirmado", label: "CONF" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_confirmado.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "data_obito", label: "ÓBITO" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_obito.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "data_recuperado", label: "RECUP" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_recuperado.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "data_supeito", label: "SUSP" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_suspeito.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "data_suspeito_risco_medio",
                  label: "SUSP MÉDIO",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[
                              scope.$index
                            ].data_suspeito_risco_medio.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "data_suspeito_risco_alto", label: "SUSP ALTO" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[
                              scope.$index
                            ].data_suspeito_risco_alto.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tp_relatorio == "central_casos_funcionarios",
              expression: "tp_relatorio == 'central_casos_funcionarios'",
            },
          ],
          staticClass: "row-bg",
          attrs: { type: "flex", gutter: 32 },
        },
        [
          _c(
            "el-table",
            { staticClass: "table-style", attrs: { data: _vm.dados } },
            [
              _c("el-table-column", {
                attrs: { prop: "nome_completo", label: "NOME", width: "100" },
              }),
              _c("el-table-column", { attrs: { prop: "cpf", label: "CPF" } }),
              _c("el-table-column", {
                attrs: { prop: "data_nascimento", label: "NASC" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_nascimento.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "STATUS" },
              }),
              _c("el-table-column", {
                attrs: { prop: "data_confirmado", label: "CONF" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_confirmado.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "data_obito", label: "ÓBITO" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_obito.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "data_recuperado", label: "RECUP" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_recuperado.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "data_supeito", label: "SUSP" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[scope.$index].data_suspeito.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "data_suspeito_risco_medio",
                  label: "SUSP MÉDIO",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[
                              scope.$index
                            ].data_suspeito_risco_medio.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "data_suspeito_risco_alto", label: "SUSP ALTO" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.dados[
                              scope.$index
                            ].data_suspeito_risco_alto.replace(
                              /(\d{4})-(\d{2})-(\d{2})/,
                              "$3/$2/$1"
                            )
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "printlogo" } }, [
      _c("div", { staticClass: "header-logo-saic" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/SAICovid-19.png") },
        }),
      ]),
      _c("div", { staticClass: "header-text" }, [
        _c("span", [_vm._v("INFATEC - SOLUÇÕES TECNOLÓGICAS")]),
        _c("br"),
        _c("span", [_vm._v("TERESINA - PI")]),
        _c("br"),
        _c("span", [_vm._v("SISTEMA DE ACOMPANHAMENTO INTEGRAL AO COVID-19")]),
      ]),
      _c("div", { staticClass: "header-logo-infatec" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/infatec-logo.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }