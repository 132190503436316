var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "maincontainerviewindicadores" } },
    [
      _c("el-container"),
      _c("el-header", [
        _c("span", { staticClass: "title" }, [_vm._v("Indicadores")]),
      ]),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            { staticClass: "row-bg", attrs: { type: "flex" } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("strong", [_vm._v("Filtros")]),
                    _c("img", {
                      attrs: { src: require("@/assets/images/icon-pdf.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.indicatorSelected,
                        },
                        on: { click: _vm.generatePdf },
                      },
                      [_vm._v("Gerar PDF")]
                    ),
                    _c("img", {
                      staticClass: "img-2",
                      attrs: { src: require("@/assets/images/icon-excel.png") },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                          target: "_blank",
                          disabled: !_vm.indicatorSelected,
                        },
                        on: { click: _vm.generateExcel },
                      },
                      [_vm._v("Gerar Excel")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "filter-style" },
                  [
                    _c(
                      "el-form",
                      { staticClass: "form-style" },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c("span", [_vm._v("Indicador")]),
                            _c(
                              "el-select",
                              {
                                staticClass: "form-item-style",
                                attrs: { placeholder: "Indicador" },
                                model: {
                                  value: _vm.filtros.indicador,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "indicador", $$v)
                                  },
                                  expression: "filtros.indicador",
                                },
                              },
                              _vm._l(_vm.indicadores, function (ind, i) {
                                return _c("el-option", {
                                  staticClass: "option-style",
                                  attrs: {
                                    label: ind.label,
                                    value: ind.value,
                                    kei: i,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            _c("span", [_vm._v("Unidade básica de saúde")]),
                            _c(
                              "el-select",
                              {
                                staticClass: "form-item-style",
                                attrs: { placeholder: "Ubs" },
                                model: {
                                  value: _vm.filtros.ubs,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filtros, "ubs", $$v)
                                  },
                                  expression: "filtros.ubs",
                                },
                              },
                              _vm._l(_vm.listaUbs, function (ubs, i) {
                                return _c("el-option", {
                                  attrs: { label: ubs, value: ubs, kei: i },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.filtros.indicador != "visao_geral" &&
                                  _vm.filtros.indicador != "",
                                expression:
                                  "(filtros.indicador != 'visao_geral') && (filtros.indicador != '')",
                              },
                            ],
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c("span", [_vm._v("Data inicial")]),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "##/##/####",
                                      expression: "'##/##/####'",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "Data inicial",
                                    "prefix-icon": "el-icon-date",
                                  },
                                  model: {
                                    value: _vm.filtros.dt_atd_mais_recente__gte,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "dt_atd_mais_recente__gte",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filtros.dt_atd_mais_recente__gte",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("el-col", { attrs: { span: 2 } }, [_vm._v(" ")]),
                            _c(
                              "el-col",
                              { attrs: { span: 11 } },
                              [
                                _c("span", [
                                  _vm._v("Data final                        "),
                                ]),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "##/##/####",
                                      expression: "'##/##/####'",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "Data final",
                                    "prefix-icon": "el-icon-date",
                                  },
                                  model: {
                                    value: _vm.filtros.dt_atd_mais_recente__lte,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filtros,
                                        "dt_atd_mais_recente__lte",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filtros.dt_atd_mais_recente__lte",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.filtros.indicador != "visao_geral" &&
                                  _vm.filtros.indicador != "",
                                expression:
                                  "(filtros.indicador != 'visao_geral') && (filtros.indicador != '')",
                              },
                            ],
                            staticClass: "form-item-style",
                          },
                          [
                            _c("span", [_vm._v("Data exata")]),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##/##/####",
                                  expression: "'##/##/####'",
                                },
                              ],
                              attrs: {
                                placeholder: "Data exata",
                                "prefix-icon": "el-icon-date",
                              },
                              model: {
                                value: _vm.filtros.dt_atd_mais_recente__exact,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filtros,
                                    "dt_atd_mais_recente__exact",
                                    $$v
                                  )
                                },
                                expression:
                                  "filtros.dt_atd_mais_recente__exact",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-alert", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.erroBuscaVisaoGeral,
                              expression: "erroBuscaVisaoGeral",
                            },
                          ],
                          attrs: {
                            title: "Busca não permitida",
                            type: "error",
                            description:
                              "A opção 'Visão geral' não pode ser marcada simultaneamente nos filtros Indicador e Quadrimestre.",
                            "show-icon": "",
                          },
                        }),
                        _c("el-alert", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.erroBuscaFiltrosVazio,
                              expression: "erroBuscaFiltrosVazio",
                            },
                          ],
                          attrs: {
                            title: "Busca não permitida",
                            type: "error",
                            description:
                              "Escolha uma opção no filtro de Indicador.",
                            "show-icon": "",
                          },
                        }),
                        _c(
                          "el-form-item",
                          { staticClass: "buttons" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "medium",
                                  icon: "el-icon-delete",
                                },
                                on: { click: _vm.cleanFilters },
                              },
                              [_vm._v("Limpar")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.searching,
                                  disabled: _vm.searching,
                                  size: "medium",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.filter },
                              },
                              [_vm._v("Buscar")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.indicatorSelected,
                  expression: "indicatorSelected",
                },
              ],
            },
            [
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, lg: 24 } },
                [
                  _c("el-card", { staticClass: "box-card-results" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-card",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [_c("span", [_vm._v("RESULTADOS")])]
                    ),
                    _c("div", { staticClass: "content-card" }, [
                      _c("span", [_vm._v(_vm._s(_vm.indicadorDados.length))]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tpFiltro == 1 &&
                    _vm.indicatorSelected &&
                    _vm.indicadorDados.length > 0,
                  expression:
                    "(tpFiltro == 1 && indicatorSelected) && (indicadorDados.length > 0)",
                },
              ],
              staticClass: "row-bg charts-style",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.numIndicador != 2 && _vm.numIndicador != 5,
                      expression: "(numIndicador != 2) && (numIndicador != 5)",
                    },
                  ],
                  attrs: { xs: 24, sm: 24, lg: 14 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Metas (%)                        "),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: { data: _vm.pieDataMetas, nameGraph: "Metas" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 1,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 1))",
                    },
                  ],
                  attrs: { xs: 24, sm: 24, lg: 14 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("1ª ATD. < 20 SEMANAS (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoAtendimento20Semana,
                          nameGraph: "1º atd. < 20 semanas",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.numIndicador == 2,
                      expression: "(numIndicador == 2)",
                    },
                  ],
                  attrs: { xs: 24, sm: 11, lg: 11 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Metas (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: { data: _vm.pieDataMetas, nameGraph: "Metas" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 2,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 2))",
                    },
                  ],
                  attrs: { xs: 24, sm: 11, lg: 11 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Sorologia HIV (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoHIV,
                          nameGraph: "Sorologia HIV",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 2,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 2))",
                    },
                  ],
                  attrs: { xs: 24, sm: 11, lg: 11 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Sorologia Sífilis (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoSifilis,
                          nameGraph: "Sorologia Sífilis",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 4,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 4))",
                    },
                  ],
                  attrs: { xs: 24, sm: 24, lg: 14 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Exame Citopatológico (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoCitopatologico,
                          nameGraph: "Exame Citopatológico",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.numIndicador == 5,
                      expression: "(numIndicador == 5)",
                    },
                  ],
                  attrs: { xs: 24, sm: 11, lg: 11 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Metas (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: { data: _vm.pieDataMetas, nameGraph: "Metas" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 5,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 5))",
                    },
                  ],
                  attrs: { xs: 24, sm: 11, lg: 11 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Vacina Polio (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoPolio,
                          nameGraph: "Vacina Polio",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 5,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 5))",
                    },
                  ],
                  attrs: { xs: 24, sm: 11, lg: 11 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Vacina Penta (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoPenta,
                          nameGraph: "Vacina Penta",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tpFiltro == 1 &&
                    _vm.indicatorSelected &&
                    _vm.indicadorDados.length > 0,
                  expression:
                    "(tpFiltro == 1) && (indicatorSelected) && (indicadorDados.length > 0)",
                },
              ],
              staticClass: "row-bg charts-style-1024",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c("el-col", [
                _c(
                  "div",
                  { staticClass: "chart-wrapper" },
                  [
                    _c("h3", { staticStyle: { "text-align": "left" } }, [
                      _vm._v("Metas (%)"),
                    ]),
                    _c("pie-chart", {
                      key: _vm.keyPieMetas,
                      staticStyle: { height: "400px" },
                      attrs: { data: _vm.pieDataMetas, nameGraph: "Metas" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tpFiltro == 1 &&
                    _vm.indicatorSelected &&
                    _vm.indicadorDados.length > 0,
                  expression:
                    "(tpFiltro == 1 && indicatorSelected && (indicadorDados.length > 0))",
                },
              ],
              staticClass: "row-bg charts-style-1024",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 1,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 1))",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("1ª ATD. < 20 SEMANAS (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoAtendimento20Semana,
                          nameGraph: "1º atd. < 20 semanas",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tpFiltro == 1 &&
                    _vm.indicatorSelected &&
                    _vm.indicadorDados.length > 0,
                  expression:
                    "(tpFiltro == 1 && indicatorSelected && (indicadorDados.length > 0))",
                },
              ],
              staticClass: "row-bg charts-style-1024",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 2,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 2))",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Sorologia HIV (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoHIV,
                          nameGraph: "Sorologia HIV",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tpFiltro == 1 && _vm.indicatorSelected,
                  expression: "(tpFiltro == 1 && indicatorSelected)",
                },
              ],
              staticClass: "row-bg charts-style-1024",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 2 &&
                        _vm.indicadorDados.length > 0,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 2) && (indicadorDados.length > 0))",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Sorologia Sífilis (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoSifilis,
                          nameGraph: "Sorologia Sífilis",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tpFiltro == 1 &&
                    _vm.indicatorSelected &&
                    _vm.indicadorDados.length > 0,
                  expression:
                    "(tpFiltro == 1 && indicatorSelected && (indicadorDados.length > 0))",
                },
              ],
              staticClass: "row-bg charts-style-1024",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 4,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 4))",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Exame Citopatológico (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoCitopatologico,
                          nameGraph: "Exame Citopatológico",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tpFiltro == 1 &&
                    _vm.indicatorSelected &&
                    _vm.indicadorDados.length > 0,
                  expression:
                    "(tpFiltro == 1 && indicatorSelected && (indicadorDados.length > 0))",
                },
              ],
              staticClass: "row-bg charts-style-1024",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 5,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 5))",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Vacina Polio (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoPolio,
                          nameGraph: "Vacina Polio",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.tpFiltro == 1 &&
                    _vm.indicatorSelected &&
                    _vm.indicadorDados.length > 0,
                  expression:
                    "(tpFiltro == 1 && indicatorSelected && (indicadorDados.length > 0))",
                },
              ],
              staticClass: "row-bg charts-style-1024",
              attrs: { type: "flex", gutter: 32, id: "exportContent" },
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.tpFiltro == 1 &&
                        _vm.indicatorSelected &&
                        _vm.numIndicador == 5,
                      expression:
                        "((tpFiltro == 1) && (indicatorSelected) && (numIndicador == 5))",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "chart-wrapper" },
                    [
                      _c("h3", { staticStyle: { "text-align": "left" } }, [
                        _vm._v("Vacina Penta (%)"),
                      ]),
                      _c("pie-chart", {
                        key: _vm.keyPieMetas,
                        staticStyle: { height: "400px" },
                        attrs: {
                          data: _vm.dadosGraficoPenta,
                          nameGraph: "Vacina Penta",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.indicatorSelected,
                  expression: "indicatorSelected",
                },
              ],
              staticClass: "row-bg",
              attrs: { type: "flex", gutter: 32 },
            },
            [
              _c(
                "el-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.indicatorSelected,
                      expression: "indicatorSelected",
                    },
                  ],
                  staticClass: "result-style",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix result-title-style",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("span", { staticStyle: { "font-weigth": "bold" } }, [
                        _vm._v(_vm._s(_vm.titulo_indicador)),
                      ]),
                    ]
                  ),
                  _c("div", { attrs: { id: "exportDoc" } }, [
                    _c("div", { staticClass: "result-indicators-style" }, [
                      _c(
                        "span",
                        { staticClass: "result-indicators-style-title" },
                        [_vm._v("Acompanhe a evolução dos indicadores")]
                      ),
                      _c("br"),
                      _c("br"),
                      _c("img", {
                        staticStyle: {
                          width: "20px",
                          "margin-right": "0.5rem",
                        },
                        attrs: {
                          src: require("@/assets/images/downloaded_redflag.svg"),
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "1rem",
                            color: "#d81e06",
                          },
                        },
                        [_vm._v("< 24.0%")]
                      ),
                      _c("img", {
                        staticStyle: {
                          width: "20px",
                          "margin-right": "0.5rem",
                        },
                        attrs: {
                          src: require("@/assets/images/downloaded_yellowflag.svg"),
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "1rem",
                            color: "#f0ad4e",
                          },
                        },
                        [_vm._v("≥ 24.0% e < 42.0%")]
                      ),
                      _c("img", {
                        staticStyle: {
                          width: "20px",
                          "margin-right": "0.5rem",
                        },
                        attrs: {
                          src: require("@/assets/images/downloaded_greenflag.svg"),
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "1rem",
                            color: "#00a65a",
                          },
                        },
                        [_vm._v("≥ 42.0% e < 60%")]
                      ),
                      _c("img", {
                        staticStyle: {
                          width: "20px",
                          "margin-right": "0.5rem",
                        },
                        attrs: {
                          src: require("@/assets/images/downloaded_blueflag.svg"),
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "margin-right": "1rem",
                            color: "#3c8dbc",
                          },
                        },
                        [_vm._v("≥ 60.0%")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "result-table-style" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tpFiltro == 1 && _vm.numIndicador == 1,
                                expression:
                                  "(tpFiltro == 1) && (numIndicador == 1)",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadoresPaginated },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cnes_list",
                                label: "CNES ESTABELECIMENTO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_unidade_saude_list",
                                label: "EQUIPE",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_profissional_list",
                                label: "PROFISSIONAL",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_cidadao",
                                label: "CIDADAO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cpf",
                                label: "CPF",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cns",
                                label: "CARTAO SUS",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "prim_atd_menos_20_semanas",
                                label: "1º ATD. < 20 SEMANAS",
                                width: "155",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "total_atd",
                                label: "TOTAL DE CONSULTAS",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "META",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadoresPaginated[
                                              scope.$index
                                            ].meta.toFixed(2)
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "STATUS",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 23 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 41 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "detalhes",
                                label: "DETALHES",
                                width: "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      _vm.indicadoresPaginated[scope.$index]
                                        .detalhes,
                                      function (det, key) {
                                        return _c("span", [
                                          _vm._v(_vm._s(det) + " "),
                                          _c("br"),
                                        ])
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tpFiltro == 1 && _vm.numIndicador == 2,
                                expression:
                                  "(tpFiltro == 1) && (numIndicador == 2)",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadoresPaginated },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cnes_list",
                                label: "CNES ESTABELECIMENTO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_unidade_saude_list",
                                label: "EQUIPE",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_profissional_list",
                                label: "PROFISSIONAL",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_cidadao",
                                label: "CIDADAO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cpf",
                                label: "CPF",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cns",
                                label: "CARTAO SUS",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sorologia_sifilis",
                                label: "SIFILIS",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sorologia_hiv",
                                label: "HIV",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "META",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadoresPaginated[
                                              scope.$index
                                            ].meta.toFixed(2)
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "STATUS",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 23 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 41 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "detalhes",
                                label: "DETALHES",
                                width: "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      _vm.indicadoresPaginated[scope.$index]
                                        .detalhes,
                                      function (det, key) {
                                        return _c("span", [
                                          _vm._v(_vm._s(det) + " "),
                                          _c("br"),
                                        ])
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tpFiltro == 1 && _vm.numIndicador == 3,
                                expression:
                                  "(tpFiltro == 1) && (numIndicador == 3)",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadoresPaginated },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cnes_list",
                                label: "CNES ESTABELECIMENTO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_unidade_saude_list",
                                label: "EQUIPE",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_profissional_list",
                                label: "PROFISSIONAL",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_cidadao",
                                label: "CIDADAO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cpf",
                                label: "CPF",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cns",
                                label: "CARTAO SUS",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "total_atd",
                                label: "TOTAL DE CONSULTAS",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "META",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadoresPaginated[
                                              scope.$index
                                            ].meta.toFixed(2)
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "STATUS",
                                width: "70",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 23 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 41 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "detalhes",
                                label: "DETALHES",
                                width: "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      _vm.indicadoresPaginated[scope.$index]
                                        .detalhes,
                                      function (det, key) {
                                        return _c("span", [
                                          _vm._v(_vm._s(det) + " "),
                                          _c("br"),
                                        ])
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tpFiltro == 1 && _vm.numIndicador == 4,
                                expression:
                                  "(tpFiltro == 1) && (numIndicador == 4)",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadoresPaginated },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cnes_list",
                                label: "CNES ESTABELECIMENTO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_unidade_saude_list",
                                label: "EQUIPE",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_profissional_list",
                                label: "PROFISSIONAL",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_cidadao",
                                label: "CIDADAO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cpf",
                                label: "CPF",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cns",
                                label: "CARTAO SUS",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "idade",
                                label: "IDADE",
                                width: "70",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "exame_citopatologico",
                                label: "CITOPATOLÓGICO",
                                width: "140",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "STATUS",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 23 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 41 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "detalhes",
                                label: "DETALHES",
                                width: "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      _vm.indicadoresPaginated[scope.$index]
                                        .detalhes,
                                      function (det, key) {
                                        return _c("span", [
                                          _vm._v(_vm._s(det) + " "),
                                          _c("br"),
                                        ])
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tpFiltro == 1 && _vm.numIndicador == 5,
                                expression:
                                  "(tpFiltro == 1) && (numIndicador == 5)",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadoresPaginated },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cnes_list",
                                label: "CNES ESTABELECIMENTO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_unidade_saude_list",
                                label: "EQUIPE",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_profissional_list",
                                label: "PROFISSIONAL",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_cidadao",
                                label: "CIDADAO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cpf",
                                label: "CPF",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cns",
                                label: "CARTAO SUS",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "vacina_polio",
                                label: "POLIO",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "vacina_penta",
                                label: "PENTA",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "META",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadoresPaginated[
                                              scope.$index
                                            ].meta.toFixed(2)
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "STATUS",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 23 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 41 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "detalhes",
                                label: "DETALHES",
                                width: "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      _vm.indicadoresPaginated[scope.$index]
                                        .detalhes,
                                      function (det, key) {
                                        return _c("span", [
                                          _vm._v(_vm._s(det) + " "),
                                          _c("br"),
                                        ])
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tpFiltro == 1 && _vm.numIndicador == 6,
                                expression:
                                  "(tpFiltro == 1) && (numIndicador == 6)",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadoresPaginated },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cnes_list",
                                label: "CNES ESTABELECIMENTO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_unidade_saude_list",
                                label: "EQUIPE",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_profissional_list",
                                label: "PROFISSIONAL",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_cidadao",
                                label: "CIDADAO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cpf",
                                label: "CPF",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cns",
                                label: "CARTAO SUS",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "dt_inclusao",
                                label: "DT INC HIPER",
                                width: "150",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.indicadoresPaginated[
                                            scope.$index
                                          ].dt_inclusao.replace(
                                            /(\d{4})-(\d{2})-(\d{2})/,
                                            "$3/$2/$1"
                                          )
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "dt_pa_penultimo_semestre",
                                label: "DT P.A. PENÚLT SEMEST",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "total_pa_penultimo_semestre",
                                label: "TOTAL P.A. PENÚLT SEMEST",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "dt_pa_ultimo_semestre",
                                label: "DT P.A. ÚLT SEMEST",
                                width: "150",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "total_pa_ultimo_semestre",
                                label: "TOTAL P.A. ÚLT SEMEST",
                                width: "155",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "STATUS",
                                width: "70",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 23 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 41 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "detalhes",
                                label: "DETALHES",
                                width: "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      _vm.indicadoresPaginated[scope.$index]
                                        .detalhes,
                                      function (det, key) {
                                        return _c("span", [
                                          _vm._v(_vm._s(det) + " "),
                                          _c("br"),
                                        ])
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tpFiltro == 1 && _vm.numIndicador == 7,
                                expression:
                                  "(tpFiltro == 1) && (numIndicador == 7)",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadoresPaginated },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cnes_list",
                                label: "CNES ESTABELECIMENTO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_unidade_saude_list",
                                label: "EQUIPE",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              staticStyle: { "font-size": "8px" },
                              attrs: {
                                prop: "no_profissional_list",
                                label: "PROFISSIONAL",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "no_cidadao",
                                label: "CIDADAO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cpf",
                                label: "CPF",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "nu_cns",
                                label: "CARTAO SUS",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "idade", label: "IDADE" },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "total_atd", label: "TOTAL" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "dt_atd",
                                label: "DT ATENDIMENTO",
                                width: "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "total_hemoglobina_glicada",
                                label: "TOTAL HEMOGLOBINA GLICADA",
                                width: "200",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "meta",
                                label: "STATUS",
                                width: "80",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 23 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 41 &&
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadoresPaginated[scope.$index]
                                        .meta > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "detalhes",
                                label: "DETALHES",
                                width: "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return _vm._l(
                                      _vm.indicadoresPaginated[scope.$index]
                                        .detalhes,
                                      function (det, key) {
                                        return _c("span", [
                                          _vm._v(_vm._s(det) + " "),
                                          _c("br"),
                                        ])
                                      }
                                    )
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tpFiltro == 2,
                                expression: "tpFiltro == 2",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadorDados },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "estado", label: "ESTADO" },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "nome", label: "UBS" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "pre_natal_consultas",
                                label: "PRÉ-NATAL(6 CONSUL)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_consultas < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_consultas > 23 &&
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_consultas < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_consultas > 41 &&
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_consultas < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_consultas > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .pre_natal_consultas
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "pre_natal_sifilis_hiv",
                                label: "PRÉ-NATAL(SÍF E HIV)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_sifilis_hiv < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_sifilis_hiv > 23 &&
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_sifilis_hiv < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_sifilis_hiv > 41 &&
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_sifilis_hiv < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .pre_natal_sifilis_hiv > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .pre_natal_sifilis_hiv
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "gestantes_saude_bucal",
                                label: "GESTANTES SAÚDE BUCAL",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index]
                                        .gestantes_saude_bucal < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .gestantes_saude_bucal > 23 &&
                                      _vm.indicadorDados[scope.$index]
                                        .gestantes_saude_bucal < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .gestantes_saude_bucal > 41 &&
                                      _vm.indicadorDados[scope.$index]
                                        .gestantes_saude_bucal < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .gestantes_saude_bucal > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .gestantes_saude_bucal
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "cobertura_citopatologica",
                                label: "COBER CITOPATOLÓGICO",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_citopatologica < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_citopatologica > 23 &&
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_citopatologica < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_citopatologica > 41 &&
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_citopatologica < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_citopatologica > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .cobertura_citopatologica
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "cobertura_polio_penta",
                                label: "COBER POLIO E PENTA",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_polio_penta < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_polio_penta > 23 &&
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_polio_penta < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_polio_penta > 41 &&
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_polio_penta < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .cobertura_polio_penta > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .cobertura_polio_penta
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "hipertensao",
                                label: "HIPERT(PA AFERIDA)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index]
                                        .hipertensao < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .hipertensao > 23 &&
                                      _vm.indicadorDados[scope.$index]
                                        .hipertensao < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .hipertensao > 41 &&
                                      _vm.indicadorDados[scope.$index]
                                        .hipertensao < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .hipertensao > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .hipertensao
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "diabetes",
                                label: "DIABETES(HEMOG GLIC)",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index]
                                        .diabetes < 24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .diabetes > 23 &&
                                      _vm.indicadorDados[scope.$index]
                                        .diabetes < 42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .diabetes > 41 &&
                                      _vm.indicadorDados[scope.$index]
                                        .diabetes < 60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index]
                                        .diabetes > 59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .diabetes
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.tpFiltro == 3,
                                expression: "tpFiltro == 3",
                              },
                            ],
                            staticClass: "table-style",
                            attrs: { data: _vm.indicadorDados },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "estado", label: "ESTADO" },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "q1_2020", label: "2020 Q1" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index].q1_2020 <
                                      24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2020 >
                                        23 &&
                                      _vm.indicadorDados[scope.$index].q1_2020 <
                                        42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2020 >
                                        41 &&
                                      _vm.indicadorDados[scope.$index].q1_2020 <
                                        60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2020 >
                                      59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .q1_2020
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "q3_2019", label: "2019 Q3" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index].q3_2019 <
                                      24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q3_2019 >
                                        23 &&
                                      _vm.indicadorDados[scope.$index].q3_2019 <
                                        42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q3_2019 >
                                        41 &&
                                      _vm.indicadorDados[scope.$index].q3_2019 <
                                        60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q3_2019 >
                                      59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .q3_2019
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "q2_2019", label: "2019 Q2" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index].q2_2019 <
                                      24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q2_2019 >
                                        23 &&
                                      _vm.indicadorDados[scope.$index].q2_2019 <
                                        42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q2_2019 >
                                        41 &&
                                      _vm.indicadorDados[scope.$index].q2_2019 <
                                        60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q2_2019 >
                                      59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .q2_2019
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "q1_2019", label: "2019 Q1" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index].q1_2019 <
                                      24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2019 >
                                        23 &&
                                      _vm.indicadorDados[scope.$index].q1_2019 <
                                        42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2019 >
                                        41 &&
                                      _vm.indicadorDados[scope.$index].q1_2019 <
                                        60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2019 >
                                      59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .q1_2019
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "q3_2018", label: "2018 Q3" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index].q3_2018 <
                                      24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q3_2018 >
                                        23 &&
                                      _vm.indicadorDados[scope.$index].q3_2018 <
                                        42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q3_2018 >
                                        41 &&
                                      _vm.indicadorDados[scope.$index].q3_2018 <
                                        60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q3_2018 >
                                      59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .q3_2018
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "q2_2018", label: "2018 Q3" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index].q2_2018 <
                                      24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q2_2018 >
                                        23 &&
                                      _vm.indicadorDados[scope.$index].q2_2018 <
                                        42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q2_2018 >
                                        41 &&
                                      _vm.indicadorDados[scope.$index].q2_2018 <
                                        60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q2_2018 >
                                      59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .q2_2018
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "q1_2018", label: "2018 Q3" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.indicadorDados[scope.$index].q1_2018 <
                                      24
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_redflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2018 >
                                        23 &&
                                      _vm.indicadorDados[scope.$index].q1_2018 <
                                        42
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_yellowflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2018 >
                                        41 &&
                                      _vm.indicadorDados[scope.$index].q1_2018 <
                                        60
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_greenflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.indicadorDados[scope.$index].q1_2018 >
                                      59
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "20px",
                                              "margin-right": "1rem",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/downloaded_blueflag.svg"),
                                            },
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.indicadorDados[scope.$index]
                                              .q1_2018
                                          ) + " %"
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _vm.indicadoresFiltered.length > 0
                          ? _c("el-pagination", {
                              attrs: {
                                background: "",
                                "page-size": _vm.indicadoresPerPage,
                                layout: "prev, pager, next",
                                total: _vm.indicadoresFiltered.length,
                              },
                              on: { "current-change": _vm.changePage },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }