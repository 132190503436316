"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.replace");

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _validate = require("@/utils/validate");

var _vuex = require("vuex");

var _localforage = _interopRequireDefault(require("localforage"));

var _elementUi = require("element-ui");

var _inputmask = _interopRequireDefault(require("inputmask"));

var _vue = _interopRequireDefault(require("vue"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

_vue.default.directive('mask', {
  bind: function bind(el, binding) {
    (0, _inputmask.default)(binding.value).mask(el.getElementsByTagName('INPUT')[0]);
  }
});

var _default = {
  name: 'Forgot',
  components: {},
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('Por favor insira um email válido'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error('A senha deve conter 6 caracteres ou mais'));
      } else {
        callback();
      }
    };

    var validateCPF = function validateCPF(rule, value, callback) {
      if (!value || value == '') {
        callback(new Error('CPF é obrigatório!'));
      } else {
        callback();
      }
    };

    return {
      forgotForm: {
        cpf: '',
        username: '',
        password: '',
        password2: ''
      },
      forgotRules: {
        cpf: [{
          required: true,
          trigger: 'blur',
          validator: validateCPF
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        password2: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      hasToken: false,
      hasMsg: false,
      msg: 'Msg padrão!',
      uid: '',
      token: '',
      loading: false,
      otherQuery: {}
    };
  },
  beforeMount: function beforeMount() {},
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['domain', 'secretaria', 'perfil'])),
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;

        if (query) {
          // console.log(query)
          if (query.uid && query.uid) {
            this.uid = query.uid;
            this.token = query.token;
            this.hasToken = true;
          }
        }
      },
      immediate: true
    }
  },
  created: function created() {// window.addEventListener('storage', this.afterQRScan)
  },
  mounted: function mounted() {// Message({
    //   message: `Usuário com cpf: encontrado.`,
    //   type: 'success',
    //   duration: 50 * 1000
    // })
  },
  destroyed: function destroyed() {// window.removeEventListener('storage', this.afterQRScan)
  },
  methods: _objectSpread(_objectSpread({}, (0, _vuex.mapMutations)('user', ['SET_SECRETARIA'])), {}, {
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this = this;

      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }

      this.$nextTick(function () {
        _this.$refs.password.focus();
      });
    },
    goLogin: function goLogin() {
      this.$router.push('/login');
    },
    handleReset: function handleReset() {
      var _this2 = this;

      var self = this;
      this.$refs.forgotForm.validate(function (valid) {
        if (true) {
          _this2.loading = true;

          _this2.$store.dispatch('user/login', _this2.forgotForm).then(function (res) {
            // console.log(res)
            _this2.loading = false; // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
            // this.loading = false
          }).catch(function (err) {
            console.log("error", err);
            console.log("error", err.response);
            _this2.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleButton: function handleButton() {
      var _this3 = this;

      if (!this.hasToken) {
        var CPF = this.forgotForm.cpf.replace(/\./g, '').replace('-', '').replace(/_/g, '');

        if (CPF != '') {
          this.loading = true;
          this.$store.dispatch('user/passwordReset', {
            cpf: CPF
          }).then(function (response) {
            console.log("sucesso");
            _this3.msg = 'Se o seu CPF tiver cadastro no sistema, você receberá um link via SMS para redefinir a sua senha.';
            _this3.hasMsg = true;
            _this3.loading = false;
          }).catch(function (err) {
            _this3.loading = false;
          });
        }
      } else {
        var pass1 = this.forgotForm.password;
        var pass2 = this.forgotForm.password2;

        if (pass1.length < 6 || pass2.length < 6) {
          (0, _elementUi.Message)({
            message: "Senha deve ter no m\xEDnimo 6 caracteres.",
            type: 'error',
            duration: 5 * 1000
          });
        } else if (pass1 != pass2) {
          (0, _elementUi.Message)({
            message: "Confirma\xE7\xE3o de senha incorreta.",
            type: 'error',
            duration: 5 * 1000
          });
        } else {
          this.loading = true;
          this.$store.dispatch('user/passwordResetConfirmation', {
            uid: this.uid,
            token: this.token,
            new_password: this.forgotForm.password,
            re_new_password: this.forgotForm.password2
          }).then(function (response) {
            console.log("sucesso");
            _this3.msg = 'Senha alterada com sucesso. Você já pode utilizar a sua nova senha.';
            _this3.hasMsg = true;
          }).catch(function (err) {
            console.log(err);
            _this3.loading = false;
          });
        }
      }
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }

        return acc;
      }, {});
    }
  })
};
exports.default = _default;