var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "inline-block",
                "vertical-align": "text-bottom",
                "line-height": "50px",
                padding: "0",
                margin: "0",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.mapRoles[_vm.perfil.role]) +
                  " - " +
                  _vm._s(_vm.perfil.nome_instituicao) +
                  " "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c("el-avatar", { attrs: { icon: "el-icon-user-solid" } }),
                  _vm._v(" "),
                  _c("i", { staticClass: "el-icon-caret-bottom" }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown",
                    { attrs: { trigger: "click" } },
                    [
                      _c("el-button", { attrs: { plain: "" } }, [
                        _c("span", [_vm._v("Selecione Perfil")]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-caret-bottom el-icon--right",
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "no-padding",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.perfis, function (perfil, i) {
                          return _c(
                            "el-dropdown-item",
                            {
                              key: i,
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.selectPerfil(i)
                                },
                              },
                            },
                            [
                              perfil.tipo_instituicao == "SECRETARIA"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.tipos[perfil.role] +
                                          " em " +
                                          perfil.nome_instituicao
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              perfil.tipo_instituicao == "HOSPITAL"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.tipos[perfil.role] +
                                          " em " +
                                          perfil.nome_instituicao
                                      ) + " (HOSPITAL)"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              perfil.tipo_instituicao == "EMPRESA"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.tipos[perfil.role] +
                                          " em " +
                                          perfil.nome_instituicao
                                      ) + " (EMPRESA)"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("Sair"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }