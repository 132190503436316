//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'use strict';

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _typeof2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/typeof.js"));

require("core-js/modules/es6.number.constructor");

var _request = _interopRequireDefault(require("@/utils/request"));

var _language = _interopRequireDefault(require("./utils/language.js"));

var _mimes = _interopRequireDefault(require("./utils/mimes.js"));

var _data2blob = _interopRequireDefault(require("./utils/data2blob.js"));

var _effectRipple = _interopRequireDefault(require("./utils/effectRipple.js"));

var _default = {
  props: {
    // 域，上传文件name，触发事件会带上（如果一个页面多个图片上传控件，可以做区分
    field: {
      type: String,
      default: 'avatar'
    },
    // 原名key，类似于id，触发事件会带上（如果一个页面多个图片上传控件，可以做区分
    ki: {
      type: Number,
      default: 0
    },
    // 显示该控件与否
    value: {
      type: Boolean,
      default: true
    },
    // 上传地址
    url: {
      type: String,
      default: ''
    },
    // 其他要上传文件附带的数据，对象格式
    params: {
      type: Object,
      default: null
    },
    // Add custom headers
    headers: {
      type: Object,
      default: null
    },
    // 剪裁图片的宽
    width: {
      type: Number,
      default: 200
    },
    // 剪裁图片的高
    height: {
      type: Number,
      default: 200
    },
    // 不显示旋转功能
    noRotate: {
      type: Boolean,
      default: true
    },
    // 不预览圆形图片
    noCircle: {
      type: Boolean,
      default: false
    },
    // 不预览方形图片
    noSquare: {
      type: Boolean,
      default: false
    },
    // 单文件大小限制
    maxSize: {
      type: Number,
      default: 10240
    },
    // 语言类型
    langType: {
      type: String,
      default: 'zh'
    },
    // 语言包
    langExt: {
      type: Object,
      default: null
    },
    // 图片上传格式
    imgFormat: {
      type: String,
      default: 'png'
    },
    // 是否支持跨域
    withCredentials: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var imgFormat = this.imgFormat,
        langType = this.langType,
        langExt = this.langExt,
        width = this.width,
        height = this.height;
    var isSupported = true;
    var allowImgFormat = ['jpg', 'png'];
    var tempImgFormat = allowImgFormat.indexOf(imgFormat) === -1 ? 'jpg' : imgFormat;
    var lang = _language.default[langType] ? _language.default[langType] : _language.default['en'];
    var mime = _mimes.default[tempImgFormat]; // 规范图片格式

    this.imgFormat = tempImgFormat;

    if (langExt) {
      Object.assign(lang, langExt);
    }

    if (typeof FormData !== 'function') {
      isSupported = false;
    }

    return {
      // 图片的mime
      mime: mime,
      // 语言包
      lang: lang,
      // 浏览器是否支持该控件
      isSupported: isSupported,
      // 浏览器是否支持触屏事件
      isSupportTouch: document.hasOwnProperty('ontouchstart'),
      // 步骤
      step: 1,
      // 1选择文件 2剪裁 3上传
      // 上传状态及进度
      loading: 0,
      // 0未开始 1正在 2成功 3错误
      progress: 0,
      // 是否有错误及错误信息
      hasError: false,
      errorMsg: '',
      // 需求图宽高比
      ratio: width / height,
      // 原图地址、生成图片地址
      sourceImg: null,
      sourceImgUrl: '',
      createImgUrl: '',
      // 原图片拖动事件初始值
      sourceImgMouseDown: {
        on: false,
        mX: 0,
        // 鼠标按下的坐标
        mY: 0,
        x: 0,
        // scale原图坐标
        y: 0
      },
      // 生成图片预览的容器大小
      previewContainer: {
        width: 100,
        height: 100
      },
      // 原图容器宽高
      sourceImgContainer: {
        // sic
        width: 240,
        height: 184 // 如果生成图比例与此一致会出现bug，先改成特殊的格式吧，哈哈哈

      },
      // 原图展示属性
      scale: {
        zoomAddOn: false,
        // 按钮缩放事件开启
        zoomSubOn: false,
        // 按钮缩放事件开启
        range: 1,
        // 最大100
        rotateLeft: false,
        // 按钮向左旋转事件开启
        rotateRight: false,
        // 按钮向右旋转事件开启
        degree: 0,
        // 旋转度数
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        maxWidth: 0,
        maxHeight: 0,
        minWidth: 0,
        // 最宽
        minHeight: 0,
        naturalWidth: 0,
        // 原宽
        naturalHeight: 0
      }
    };
  },
  computed: {
    // 进度条样式
    progressStyle: function progressStyle() {
      var progress = this.progress;
      return {
        width: progress + '%'
      };
    },
    // 原图样式
    sourceImgStyle: function sourceImgStyle() {
      var scale = this.scale,
          sourceImgMasking = this.sourceImgMasking;
      var top = scale.y + sourceImgMasking.y + 'px';
      var left = scale.x + sourceImgMasking.x + 'px';
      return {
        top: top,
        left: left,
        width: scale.width + 'px',
        height: scale.height + 'px',
        transform: 'rotate(' + scale.degree + 'deg)',
        // 旋转时 左侧原始图旋转样式
        '-ms-transform': 'rotate(' + scale.degree + 'deg)',
        // 兼容IE9
        '-moz-transform': 'rotate(' + scale.degree + 'deg)',
        // 兼容FireFox
        '-webkit-transform': 'rotate(' + scale.degree + 'deg)',
        // 兼容Safari 和 chrome
        '-o-transform': 'rotate(' + scale.degree + 'deg)' // 兼容 Opera

      };
    },
    // 原图蒙版属性
    sourceImgMasking: function sourceImgMasking() {
      var width = this.width,
          height = this.height,
          ratio = this.ratio,
          sourceImgContainer = this.sourceImgContainer;
      var sic = sourceImgContainer;
      var sicRatio = sic.width / sic.height; // 原图容器宽高比

      var x = 0;
      var y = 0;
      var w = sic.width;
      var h = sic.height;
      var scale = 1;

      if (ratio < sicRatio) {
        scale = sic.height / height;
        w = sic.height * ratio;
        x = (sic.width - w) / 2;
      }

      if (ratio > sicRatio) {
        scale = sic.width / width;
        h = sic.width / ratio;
        y = (sic.height - h) / 2;
      }

      return {
        scale: scale,
        // 蒙版相对需求宽高的缩放
        x: x,
        y: y,
        width: w,
        height: h
      };
    },
    // 原图遮罩样式
    sourceImgShadeStyle: function sourceImgShadeStyle() {
      var sourceImgMasking = this.sourceImgMasking,
          sourceImgContainer = this.sourceImgContainer;
      var sic = sourceImgContainer;
      var sim = sourceImgMasking;
      var w = sim.width === sic.width ? sim.width : (sic.width - sim.width) / 2;
      var h = sim.height === sic.height ? sim.height : (sic.height - sim.height) / 2;
      return {
        width: w + 'px',
        height: h + 'px'
      };
    },
    previewStyle: function previewStyle() {
      var ratio = this.ratio,
          previewContainer = this.previewContainer;
      var pc = previewContainer;
      var w = pc.width;
      var h = pc.height;
      var pcRatio = w / h;

      if (ratio < pcRatio) {
        w = pc.height * ratio;
      }

      if (ratio > pcRatio) {
        h = pc.width / ratio;
      }

      return {
        width: w + 'px',
        height: h + 'px'
      };
    }
  },
  watch: {
    value: function value(newValue) {
      if (newValue && this.loading !== 1) {
        this.reset();
      }
    }
  },
  created: function created() {
    // 绑定按键esc隐藏此插件事件
    document.addEventListener('keyup', this.closeHandler);
  },
  destroyed: function destroyed() {
    document.removeEventListener('keyup', this.closeHandler);
  },
  methods: {
    // 点击波纹效果
    ripple: function ripple(e) {
      (0, _effectRipple.default)(e);
    },
    // 关闭控件
    off: function off() {
      var _this = this;

      setTimeout(function () {
        _this.$emit('input', false);

        _this.$emit('close');

        if (_this.step === 3 && _this.loading === 2) {
          _this.setStep(1);
        }
      }, 200);
    },
    // 设置步骤
    setStep: function setStep(no) {
      var _this2 = this;

      // 延时是为了显示动画效果呢，哈哈哈
      setTimeout(function () {
        _this2.step = no;
      }, 200);
    },

    /* 图片选择区域函数绑定
     ---------------------------------------------------------------*/
    preventDefault: function preventDefault(e) {
      e.preventDefault();
      return false;
    },
    handleClick: function handleClick(e) {
      if (this.loading !== 1) {
        if (e.target !== this.$refs.fileinput) {
          e.preventDefault();

          if (document.activeElement !== this.$refs) {
            this.$refs.fileinput.click();
          }
        }
      }
    },
    handleChange: function handleChange(e) {
      e.preventDefault();

      if (this.loading !== 1) {
        var files = e.target.files || e.dataTransfer.files;
        this.reset();

        if (this.checkFile(files[0])) {
          this.setSourceImg(files[0]);
        }
      }
    },

    /* ---------------------------------------------------------------*/
    // 检测选择的文件是否合适
    checkFile: function checkFile(file) {
      var lang = this.lang,
          maxSize = this.maxSize; // 仅限图片

      if (file.type.indexOf('image') === -1) {
        this.hasError = true;
        this.errorMsg = lang.error.onlyImg;
        return false;
      } // 超出大小


      if (file.size / 1024 > maxSize) {
        this.hasError = true;
        this.errorMsg = lang.error.outOfSize + maxSize + 'kb';
        return false;
      }

      return true;
    },
    // 重置控件
    reset: function reset() {
      this.loading = 0;
      this.hasError = false;
      this.errorMsg = '';
      this.progress = 0;
    },
    // 设置图片源
    setSourceImg: function setSourceImg(file) {
      var _this3 = this;

      var fr = new FileReader();

      fr.onload = function (e) {
        _this3.sourceImgUrl = fr.result;

        _this3.startCrop();
      };

      fr.readAsDataURL(file);
    },
    // 剪裁前准备工作
    startCrop: function startCrop() {
      var _this4 = this;

      var width = this.width,
          height = this.height,
          ratio = this.ratio,
          scale = this.scale,
          sourceImgUrl = this.sourceImgUrl,
          sourceImgMasking = this.sourceImgMasking,
          lang = this.lang;
      var sim = sourceImgMasking;
      var img = new Image();
      img.src = sourceImgUrl;

      img.onload = function () {
        var nWidth = img.naturalWidth;
        var nHeight = img.naturalHeight;
        var nRatio = nWidth / nHeight;
        var w = sim.width;
        var h = sim.height;
        var x = 0;
        var y = 0; // 图片像素不达标

        if (nWidth < width || nHeight < height) {
          _this4.hasError = true;
          _this4.errorMsg = lang.error.lowestPx + width + '*' + height;
          return false;
        }

        if (ratio > nRatio) {
          h = w / nRatio;
          y = (sim.height - h) / 2;
        }

        if (ratio < nRatio) {
          w = h * nRatio;
          x = (sim.width - w) / 2;
        }

        scale.range = 0;
        scale.x = x;
        scale.y = y;
        scale.width = w;
        scale.height = h;
        scale.degree = 0;
        scale.minWidth = w;
        scale.minHeight = h;
        scale.maxWidth = nWidth * sim.scale;
        scale.maxHeight = nHeight * sim.scale;
        scale.naturalWidth = nWidth;
        scale.naturalHeight = nHeight;
        _this4.sourceImg = img;

        _this4.createImg();

        _this4.setStep(2);
      };
    },
    // 鼠标按下图片准备移动
    imgStartMove: function imgStartMove(e) {
      e.preventDefault(); // 支持触摸事件，则鼠标事件无效

      if (this.isSupportTouch && !e.targetTouches) {
        return false;
      }

      var et = e.targetTouches ? e.targetTouches[0] : e;
      var sourceImgMouseDown = this.sourceImgMouseDown,
          scale = this.scale;
      var simd = sourceImgMouseDown;
      simd.mX = et.screenX;
      simd.mY = et.screenY;
      simd.x = scale.x;
      simd.y = scale.y;
      simd.on = true;
    },
    // 鼠标按下状态下移动，图片移动
    imgMove: function imgMove(e) {
      e.preventDefault(); // 支持触摸事件，则鼠标事件无效

      if (this.isSupportTouch && !e.targetTouches) {
        return false;
      }

      var et = e.targetTouches ? e.targetTouches[0] : e;
      var _this$sourceImgMouseD = this.sourceImgMouseDown,
          on = _this$sourceImgMouseD.on,
          mX = _this$sourceImgMouseD.mX,
          mY = _this$sourceImgMouseD.mY,
          x = _this$sourceImgMouseD.x,
          y = _this$sourceImgMouseD.y,
          scale = this.scale,
          sourceImgMasking = this.sourceImgMasking;
      var sim = sourceImgMasking;
      var nX = et.screenX;
      var nY = et.screenY;
      var dX = nX - mX;
      var dY = nY - mY;
      var rX = x + dX;
      var rY = y + dY;
      if (!on) return;

      if (rX > 0) {
        rX = 0;
      }

      if (rY > 0) {
        rY = 0;
      }

      if (rX < sim.width - scale.width) {
        rX = sim.width - scale.width;
      }

      if (rY < sim.height - scale.height) {
        rY = sim.height - scale.height;
      }

      scale.x = rX;
      scale.y = rY;
    },
    // 按钮按下开始向右旋转
    startRotateRight: function startRotateRight(e) {
      var _this5 = this;

      var scale = this.scale;
      scale.rotateRight = true;

      var rotate = function rotate() {
        if (scale.rotateRight) {
          var degree = ++scale.degree;

          _this5.createImg(degree);

          setTimeout(function () {
            rotate();
          }, 60);
        }
      };

      rotate();
    },
    // 按钮按下开始向左旋转
    startRotateLeft: function startRotateLeft(e) {
      var _this6 = this;

      var scale = this.scale;
      scale.rotateLeft = true;

      var rotate = function rotate() {
        if (scale.rotateLeft) {
          var degree = --scale.degree;

          _this6.createImg(degree);

          setTimeout(function () {
            rotate();
          }, 60);
        }
      };

      rotate();
    },
    // 停止旋转
    endRotate: function endRotate() {
      var scale = this.scale;
      scale.rotateLeft = false;
      scale.rotateRight = false;
    },
    // 按钮按下开始放大
    startZoomAdd: function startZoomAdd(e) {
      var _this7 = this;

      var scale = this.scale;
      scale.zoomAddOn = true;

      var zoom = function zoom() {
        if (scale.zoomAddOn) {
          var range = scale.range >= 100 ? 100 : ++scale.range;

          _this7.zoomImg(range);

          setTimeout(function () {
            zoom();
          }, 60);
        }
      };

      zoom();
    },
    // 按钮松开或移开取消放大
    endZoomAdd: function endZoomAdd(e) {
      this.scale.zoomAddOn = false;
    },
    // 按钮按下开始缩小
    startZoomSub: function startZoomSub(e) {
      var _this8 = this;

      var scale = this.scale;
      scale.zoomSubOn = true;

      var zoom = function zoom() {
        if (scale.zoomSubOn) {
          var range = scale.range <= 0 ? 0 : --scale.range;

          _this8.zoomImg(range);

          setTimeout(function () {
            zoom();
          }, 60);
        }
      };

      zoom();
    },
    // 按钮松开或移开取消缩小
    endZoomSub: function endZoomSub(e) {
      var scale = this.scale;
      scale.zoomSubOn = false;
    },
    zoomChange: function zoomChange(e) {
      this.zoomImg(e.target.value);
    },
    // 缩放原图
    zoomImg: function zoomImg(newRange) {
      var _this9 = this;

      var sourceImgMasking = this.sourceImgMasking,
          scale = this.scale;
      var maxWidth = scale.maxWidth,
          maxHeight = scale.maxHeight,
          minWidth = scale.minWidth,
          minHeight = scale.minHeight,
          width = scale.width,
          height = scale.height,
          x = scale.x,
          y = scale.y;
      var sim = sourceImgMasking; // 蒙版宽高

      var sWidth = sim.width;
      var sHeight = sim.height; // 新宽高

      var nWidth = minWidth + (maxWidth - minWidth) * newRange / 100;
      var nHeight = minHeight + (maxHeight - minHeight) * newRange / 100; // 新坐标（根据蒙版中心点缩放）

      var nX = sWidth / 2 - nWidth / width * (sWidth / 2 - x);
      var nY = sHeight / 2 - nHeight / height * (sHeight / 2 - y); // 判断新坐标是否超过蒙版限制

      if (nX > 0) {
        nX = 0;
      }

      if (nY > 0) {
        nY = 0;
      }

      if (nX < sWidth - nWidth) {
        nX = sWidth - nWidth;
      }

      if (nY < sHeight - nHeight) {
        nY = sHeight - nHeight;
      } // 赋值处理


      scale.x = nX;
      scale.y = nY;
      scale.width = nWidth;
      scale.height = nHeight;
      scale.range = newRange;
      setTimeout(function () {
        if (scale.range === newRange) {
          _this9.createImg();
        }
      }, 300);
    },
    // 生成需求图片
    createImg: function createImg(e) {
      var mime = this.mime,
          sourceImg = this.sourceImg,
          _this$scale = this.scale,
          x = _this$scale.x,
          y = _this$scale.y,
          width = _this$scale.width,
          height = _this$scale.height,
          degree = _this$scale.degree,
          scale = this.sourceImgMasking.scale;
      var canvas = this.$refs.canvas;
      var ctx = canvas.getContext('2d');

      if (e) {
        // 取消鼠标按下移动状态
        this.sourceImgMouseDown.on = false;
      }

      canvas.width = this.width;
      canvas.height = this.height;
      ctx.clearRect(0, 0, this.width, this.height); // 将透明区域设置为白色底边

      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, this.width, this.height);
      ctx.translate(this.width * 0.5, this.height * 0.5);
      ctx.rotate(Math.PI * degree / 180);
      ctx.translate(-this.width * 0.5, -this.height * 0.5);
      ctx.drawImage(sourceImg, x / scale, y / scale, width / scale, height / scale);
      this.createImgUrl = canvas.toDataURL(mime);
    },
    prepareUpload: function prepareUpload() {
      var url = this.url,
          createImgUrl = this.createImgUrl,
          field = this.field,
          ki = this.ki;
      this.$emit('crop-success', createImgUrl, field, ki);

      if (typeof url === 'string' && url) {
        this.upload();
      } else {
        this.off();
      }
    },
    // 上传图片
    upload: function upload() {
      var _this10 = this;

      var lang = this.lang,
          imgFormat = this.imgFormat,
          mime = this.mime,
          url = this.url,
          params = this.params,
          field = this.field,
          ki = this.ki,
          createImgUrl = this.createImgUrl;
      var fmData = new FormData();
      fmData.append(field, (0, _data2blob.default)(createImgUrl, mime), field + '.' + imgFormat); // 添加其他参数

      if ((0, _typeof2.default)(params) === 'object' && params) {
        Object.keys(params).forEach(function (k) {
          fmData.append(k, params[k]);
        });
      } // 监听进度回调
      // const uploadProgress = (event) => {
      //   if (event.lengthComputable) {
      //     this.progress = 100 * Math.round(event.loaded) / event.total
      //   }
      // }
      // 上传文件


      this.reset();
      this.loading = 1;
      this.setStep(3);
      (0, _request.default)({
        url: url,
        method: 'post',
        data: fmData
      }).then(function (resData) {
        _this10.loading = 2;

        _this10.$emit('crop-upload-success', resData.data);
      }).catch(function (err) {
        if (_this10.value) {
          _this10.loading = 3;
          _this10.hasError = true;
          _this10.errorMsg = lang.fail;

          _this10.$emit('crop-upload-fail', err, field, ki);
        }
      });
    },
    closeHandler: function closeHandler(e) {
      if (this.value && (e.key === 'Escape' || e.keyCode === 27)) {
        this.off();
      }
    }
  }
};
exports.default = _default;