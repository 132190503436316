var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c(
            "a",
            {
              staticClass: "link-type link-title",
              attrs: {
                target: "_blank",
                href: "https://panjiachen.github.io/vue-element-admin-site/guide/advanced/theme.html",
              },
            },
            [_vm._v("\n        Theme documentation\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "box-item" },
          [
            _c("span", { staticClass: "field-label" }, [
              _vm._v("Change Theme : "),
            ]),
            _vm._v(" "),
            _c("el-switch", {
              model: {
                value: _vm.theme,
                callback: function ($$v) {
                  _vm.theme = $$v
                },
                expression: "theme",
              },
            }),
            _vm._v(" "),
            _c("aside", { staticStyle: { "margin-top": "15px" } }, [
              _vm._v(
                "\n        Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.\n      "
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v("\n      Primary\n    "),
          ]),
          _vm._v(" "),
          _c("el-button", { attrs: { type: "success" } }, [
            _vm._v("\n      Success\n    "),
          ]),
          _vm._v(" "),
          _c("el-button", { attrs: { type: "info" } }, [
            _vm._v("\n      Info\n    "),
          ]),
          _vm._v(" "),
          _c("el-button", { attrs: { type: "warning" } }, [
            _vm._v("\n      Warning\n    "),
          ]),
          _vm._v(" "),
          _c("el-button", { attrs: { type: "danger" } }, [
            _vm._v("\n      Danger\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-button", { attrs: { type: "primary", icon: "el-icon-edit" } }),
          _vm._v(" "),
          _c("el-button", {
            attrs: { type: "primary", icon: "el-icon-share" },
          }),
          _vm._v(" "),
          _c("el-button", {
            attrs: { type: "primary", icon: "el-icon-delete" },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary", icon: "el-icon-search" } },
            [_vm._v("\n      Search\n    ")]
          ),
          _vm._v(" "),
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v("\n      Upload\n      "),
            _c("i", { staticClass: "el-icon-upload el-icon-right" }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        _vm._l(_vm.tags, function (tag) {
          return _c(
            "el-tag",
            {
              key: tag.type,
              staticClass: "tag-item",
              attrs: { type: tag.type },
            },
            [_vm._v("\n      " + _vm._s(tag.name) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.radio,
                callback: function ($$v) {
                  _vm.radio = $$v
                },
                expression: "radio",
              },
            },
            [
              _c("el-radio", { attrs: { label: 3 } }, [
                _vm._v("\n        Option A\n      "),
              ]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 6 } }, [
                _vm._v("\n        Option B\n      "),
              ]),
              _vm._v(" "),
              _c("el-radio", { attrs: { label: 9 } }, [
                _vm._v("\n        Option C\n      "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-slider", {
            model: {
              value: _vm.slideValue,
              callback: function ($$v) {
                _vm.slideValue = $$v
              },
              expression: "slideValue",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }