"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

var _typeof = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/typeof.js");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

require("C:\\Users\\Diogo\\Documents\\projetos\\free\\saic-dash\\node_modules\\core-js\\modules\\es6.array.iterator.js");

require("C:\\Users\\Diogo\\Documents\\projetos\\free\\saic-dash\\node_modules\\core-js\\modules\\es6.promise.js");

require("C:\\Users\\Diogo\\Documents\\projetos\\free\\saic-dash\\node_modules\\core-js\\modules\\es6.object.assign.js");

require("C:\\Users\\Diogo\\Documents\\projetos\\free\\saic-dash\\node_modules\\core-js\\modules\\es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _vue2Leaflet = require("vue2-leaflet");

require("leaflet/dist/leaflet.css");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("./icons");

require("./permission");

require("./utils/error-log");

var filters = _interopRequireWildcard(require("./filters"));

var _ptBr = _interopRequireDefault(require("element-ui/lib/locale/lang/pt-br"));

var _moment = _interopRequireDefault(require("moment"));

var _vMask = require("v-mask");

require("./assets/css/main.css");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// a modern alternative to CSS resets
// global css
// icon
// permission control
// error log
// global filters
_vue.default.prototype.moment = _moment.default;

_vue.default.use(_elementUi.default, {
  locale: _ptBr.default
});

_vue.default.directive("mask", _vMask.VueMaskDirective);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */


if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
      mockXHR = _require.mockXHR;

  mockXHR();
}

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size

});

_vue.default.component('l-map', _vue2Leaflet.LMap);

_vue.default.component('l-tile-layer', _vue2Leaflet.LTileLayer);

_vue.default.component('l-marker', _vue2Leaflet.LMarker); // register global utility filters


Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});