"use strict";

var _interopRequireDefault = require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("C:/Users/Diogo/Documents/projetos/free/saic-dash/node_modules/@babel/runtime/helpers/defineProperty.js"));

require("core-js/modules/web.dom.iterable");

var _tab2 = _interopRequireDefault(require("./tab"));

var _vuex = require("vuex");

var _firebaseConfig = require("@/firebaseConfig");

var _localforage = _interopRequireDefault(require("localforage"));

var _moment = _interopRequireDefault(require("moment"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

var canplayalert = true;
var _default = {
  name: 'chat',
  components: {
    tab: _tab2.default
  },
  data: function data() {
    return {
      'chatlistListener': null,
      'imagemodalvisible': false,
      'imagemodalurl': '',
      'imagemodalname': '',
      'chatlistOpen': true,
      'closingtab': false,
      'chatlist': [// {
        //   'nome': 'fulaninho',
        //   'id': '1',
        //   'messages': [
        //     {
        //       'time': new Date(),
        //       'type': 'message',
        //       'content': 'blablablablablbal balablablabla',
        //       'nome': 'fulaninho'
        //     }
        //   ],
        //   'status': 'encerrado'
        // },
        // {
        //   'nome': 'cicraninho',
        //   'id': '2',
        //   'status': 'andamento',
        // },
        // {
        //   'nome': 'beltrano',
        //   'id': '3',
        //   'status': 'pendente',
        // }
      ],
      'openedChats': [// {
        //   'id': 1,
        //   'nome': 'fulaninho',
        //   'status': 'pendente',
        //   'responsavel': 'ailsoncgt+2@gmail.com',
        //   'messagetosend': '',
        //   'listener': null,
        //   'messages': [
        //     {
        //       'type': 'message',
        //       'content': 'blablablablabla',
        //       'fromsaic': true,
        //       'nome': 'Thomas'
        //     },
        //     {
        //       'type': 'message',
        //       'content': 'blablablablabla',
        //       'fromsaic': false,
        //       'nome': 'Fulano'
        //     },
        //     {
        //       'type': 'file',
        //       'content': {
        //         'url': 'http://google.com.br',
        //         'filename': 'teste'
        //       },
        //       'fromsaic': false,
        //       'nome': 'Fulano'
        //     }
        //   ]
        // }
      ]
    };
  },
  mounted: function mounted() {
    this.$refs.audiomessage.addEventListener("ended", function () {
      console.log('finished');
      canplayalert = true;
    }, false);
  },
  created: function created() {
    var _this = this;

    this.$root.$on('openchat', function (cpf) {
      var chat = _this.chatlist.filter(function (c) {
        return c.cpfcidadao === cpf;
      })[0];

      _this.openChat(chat.id);
    }); // load chats list

    var self = this; // db.collection('chat-dash-cidadao').orderBy('horaultimamensagem', 'desc').get().then(chats => {
    //   const chatlist = []
    //   chats.forEach(c => {
    //     const chatData = c.data()
    //     chatlist.push({
    //       'id': c.ref.id,
    //       'nome': chatData.nomecidadao,
    //       'status': chatData.status
    //     })
    //     self.chatlist = chatlist
    //   })
    // }).then(() => {

    _localforage.default.getItem('secretaria_scheme').then(function (secretaria_scheme) {
      _firebaseConfig.db.collection('chat-dash-cidadao').where('secretaria_schema', '==', secretaria_scheme).orderBy('horaultimamensagem', 'desc').onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            var newDocData = change.doc.data();
            self.chatlist.push({
              'id': change.doc.ref.id,
              'nome': newDocData.nomecidadao,
              'status': newDocData.status,
              'cpfcidadao': newDocData.cpfcidadao,
              'responsavel': newDocData.responsavel,
              'horaultimamensagem': newDocData.horaultimamensagem,
              'horaultimamensagemloaded': newDocData.horaultimamensagem,
              'ultimavisualizacaosaic': newDocData.ultimavisualizacaosaic,
              'ultimavisualizacaocidadao': newDocData.ultimavisualizacaocidadao,
              'ultimamensagemfromcidadao': newDocData.ultimamensagemfromcidadao
            }); // console.log("New city: ", change.doc.data());
          }

          if (change.type === "modified") {
            var changedDocData = change.doc.data();
            var changeChat = self.chatlist.filter(function (c) {
              return c.id === change.doc.ref.id;
            })[0];
            changeChat.nome = changedDocData.nomecidadao;
            changeChat.horaultimamensagem = changedDocData.horaultimamensagem;
            changeChat.status = changedDocData.status;
            changeChat.ultimavisualizacaosaic = changedDocData.ultimavisualizacaosaic;
            changeChat.ultimavisualizacaocidadao = changedDocData.ultimavisualizacaocidadao;
            changeChat.ultimamensagemfromcidadao = changedDocData.ultimamensagemfromcidadao;
            var tabToUpdates = self.openedChats.filter(function (t) {
              return t.id === change.doc.ref.id;
            });

            if (tabToUpdates.length > 0) {
              var _tab = tabToUpdates[0];
              _tab.nome = changedDocData.nomecidadao;
              _tab.status = changedDocData.status;
              _tab.responsavel = changedDocData.responsavel;
              _tab.ultimavisualizacaosaic = changedDocData.ultimavisualizacaosaic;
              _tab.ultimavisualizacaocidadao = changedDocData.ultimavisualizacaocidadao;
              _tab.ultimamensagemfromcidadao = changedDocData.ultimamensagemfromcidadao;
            } // console.log("Modified city: ", change.doc.data());

          }

          if (change.type === "removed") {
            var removedChatId = change.doc.ref.id;
            self.chatlist = self.chatlist.filter(function (c) {
              return c.id !== removedChatId;
            });
          }
        });
      });
    }); // }).catch(e => {
    //   console.log(e)
    // })

  },
  computed: _objectSpread({
    somePendente: function somePendente() {
      return this.chatlist.some(function (c) {
        return c.status === 'pendente';
      });
    }
  }, (0, _vuex.mapGetters)(['email', 'name', 'secretaria_scheme', 'id', 'username'])),
  methods: {
    updatevisualizacao: function updatevisualizacao(chatId) {
      _firebaseConfig.db.collection('chat-dash-cidadao').doc(chatId).update({
        'ultimavisualizacaosaic': _firebaseConfig.firebase.firestore.FieldValue.serverTimestamp()
      }).then(function () {}).catch(function (e) {});
    },
    minmax: function minmax(chatId) {
      if (this.closingtab) {
        return;
      }

      var chat = this.openedChats.filter(function (c) {
        return c.id === chatId;
      })[0];

      if (chat) {
        chat.visible = !chat.visible;
      }
    },
    test: function test(event, chatId) {
      var _this2 = this;

      var self = this;
      var chat = this.openedChats.filter(function (c) {
        return c.id === chatId;
      })[0];
      this.$confirm('Enviar arquivo ' + event.srcElement.files[0].name, 'Alerta', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(function () {
        var name = makeid(15);
        var extension = event.srcElement.files[0].name.split('.')[1];
        chat.sendingmessage = true;

        var storageref = _firebaseConfig.storage.ref();

        var newfileRef = storageref.child('chat-files/' + _this2.id + '/' + name + '.' + extension);
        newfileRef.put(event.srcElement.files[0]).then(function (snapshot) {
          // console.log(snapshot)
          snapshot.ref.getDownloadURL().then(function (downloadURL) {
            self.sendMessage(chatId, 'file', {
              'filename': event.srcElement.files[0].name,
              'url': downloadURL
            });
            event.target.value = "";
            console.log(downloadURL);
          });
        });
      }).catch(function (e) {
        event.target.value = "";
        chat.sendingmessage = false;
      });
      var name = makeid(15);
      var extension = event.srcElement.files[0].name.split('.')[1];
      console.log(name + '.' + extension); // let storageref = storage.ref()
      // let newfileRef = storageref.child('chat-files/' + this.id + '/test.jpg');
      // newfileRef.put(event.srcElement.files[0]).then((snapshot) => {
      //   // console.log(snapshot)
      //   snapshot.ref.getDownloadURL().then(function(downloadURL) {
      //     console.log(downloadURL)
      //   })
      // }).then(e => {
      //   console.log(e)
      // })
      // console.log(event.srcElement.files[0])
    },
    closetab: function closetab(tabId) {
      var _this3 = this;

      this.closingtab = true;
      var closedChat = this.openedChats.filter(function (t) {
        return t.id === tabId;
      })[0];
      closedChat.unsubscribe();
      this.openedChats = this.openedChats.filter(function (t) {
        return t.id !== tabId;
      });
      setTimeout(function () {
        _this3.closingtab = false;
      }, 100);
    },
    finishChat: function finishChat(chatId) {
      // console.log(chatId)
      var self = this;

      var chatRef = _firebaseConfig.db.collection('chat-dash-cidadao').doc(chatId);

      _firebaseConfig.db.runTransaction(function (transaction) {
        return transaction.get(chatRef).then(function (cdoc) {
          transaction.update(cdoc.ref, {
            'status': 'encerrado',
            'responsavel': ''
          });
        });
      }).then(function () {}).catch(function (e) {
        console.log(e);
      });
    },
    startChat: function startChat(chatId) {
      var self = this;

      var chatRef = _firebaseConfig.db.collection('chat-dash-cidadao').doc(chatId);

      _firebaseConfig.db.runTransaction(function (transaction) {
        return transaction.get(chatRef).then(function (cdoc) {
          transaction.update(cdoc.ref, {
            'status': 'andamento',
            'responsavel': self.username
          });
        });
      }).then(function () {}).catch(function (e) {
        console.log(e);
      });
    },
    openChat: function openChat(chatId) {
      var chatsWithId = this.openedChats.filter(function (c) {
        return c.id === chatId;
      });

      if (chatsWithId.length > 0) {
        chatsWithId[0].visible = true;
        return;
      } //   'id': 1,
      //   'nome': 'fulaninho',
      //   'status': 'pendente',
      //   'responsavel': 'ailsoncgt+2@gmail.com',
      //   'messagetosend': '',
      //   'listener': null


      var self = this;
      var chatInfo = this.chatlist.filter(function (c) {
        return c.id === chatId;
      })[0];
      var chat = {
        'id': chatInfo.id,
        'nome': chatInfo.nome,
        'responsavel': chatInfo.responsavel,
        'status': chatInfo.status,
        'messagetosend': '',
        'messages': [],
        'visible': true,
        'sendingmessage': false,
        'ultimavisualizacaocidadao': chatInfo.ultimavisualizacaocidadao
      };
      chat.unsubscribe = _firebaseConfig.db.collection('chat-dash-cidadao').doc(chatId).collection('messages').orderBy('hora', 'asc').onSnapshot(function (snapshot) {
        var messages = []; // snapshot.forEach(function(doc) {
        //   const docData = doc.data()
        //   messages.push({
        //     'type': docData.type,
        //     'content': docData.content,
        //     'fromsaic': docData.fromsaic,
        //     'nome': docData.nome,
        //     'hora': docData.hora
        //   });
        // });
        // chat.messages = messages

        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            var changeDocData = change.doc.data();
            var dia = (0, _moment.default)(new Date(changeDocData.hora.seconds * 1000)).format('DD');
            chat.messages.push({
              'type': changeDocData.type,
              'content': changeDocData.content,
              'fromsaic': changeDocData.fromsaic,
              'nome': changeDocData.nome,
              'hora': changeDocData.hora,
              'dia': dia,
              'showDay': chat.messages.length === 0 ? true : dia !== chat.messages[chat.messages.length - 1].dia
            });

            if (changeDocData.fromsaic === false && chatInfo.responsavel === self.username && changeDocData.hora.seconds > chatInfo.horaultimamensagemloaded.seconds) {
              if (canplayalert === true) {
                canplayalert = false;
                self.$refs.audiomessage.play();
              }
            }
          }

          window.setTimeout(function () {
            var element = document.getElementById(chatId + '-chat');
            element.scrollTop = element.scrollHeight - element.clientHeight;
          }, 200);
        });
      });
      self.openedChats.push(chat);
      window.setTimeout(function () {
        var element = document.getElementById(chatId + '-chat');
        element.scrollTop = element.scrollHeight - element.clientHeight;
      }, 1000);
    },
    sendMessage: function sendMessage(chatId, type, content) {
      var self = this;
      var chat = this.openedChats.filter(function (c) {
        return c.id === chatId;
      })[0];

      if (chat.messagetosend === '' && type === 'message') {
        return;
      }

      chat.sendingmessage = true;

      var chatDoc = _firebaseConfig.db.collection('chat-dash-cidadao').doc(chatId);

      _firebaseConfig.db.runTransaction(function (transaction) {
        return transaction.get(chatDoc).then(function (cdoc) {
          var updateChat = {
            'horaultimamensagem': _firebaseConfig.firebase.firestore.FieldValue.serverTimestamp(),
            'ultimamensagemfromcidadao': false
          };

          if (cdoc.data().status === 'encerrado') {
            updateChat.status = 'andamento';
            updateChat.responsavel = self.username;
          }

          transaction.update(chatDoc, updateChat);
          var messageRef = chatDoc.collection('messages').doc();
          transaction.set(messageRef, {
            'type': type,
            'content': type === 'message' ? chat.messagetosend : content,
            'fromsaic': true,
            'nome': self.name,
            'hora': _firebaseConfig.firebase.firestore.FieldValue.serverTimestamp()
          });
        });
      }).then(function () {
        chat.sendingmessage = false;

        if (type === 'message') {
          chat.messagetosend = '';
        } // console.log("Transaction successfully committed!");

      }).catch(function (error) {
        console.log("Transaction failed: ", error);
        chat.sendingmessage = false;
      });
    }
  }
};
exports.default = _default;